import React from 'react';

const MaintenanceMode = () => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#2c2c3e', // Dark background
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        maxWidth: '600px',
        width: '90%'
      }}>
        <h1 style={{
          color: '#333',
          marginBottom: '20px'
        }}>Down for Maintenance</h1>
        <p style={{
          color: '#666',
          fontSize: '16px',
          lineHeight: '1.5'
        }}>
          Sorry, this store hasn't opened yet, or is temporarily down for maintenance. Please check back soon.
        </p>
      </div>
    </div>
  );
}

export default MaintenanceMode;
