import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import DeleteSuccessModal from "../DeleteSuccessModal";
const delObject = "Global Pricing Driver";

class GlobalPricingDriversTable extends Component {
  constructor(props) {
    super(props);
    this.renderDrivers = this.renderDrivers.bind(this);
    this.editModal = this.editModal.bind(this);
	 this.deleteModal = this.deleteModal
}
deleteModal(id){
  this.props.getGlobalPricingDriver(id);

}
editModal(id) {
  this.props.getGlobalPricingDriver(id);
    this.props.setShowCreateGlobalPricingDriverModal(true, "edit");
  }
  
  renderDrivers(item, index) {
    return (
      <tr key={index}>
        <td>{item.name.substring(0,70)}</td>
        <td>{item.type}</td>
        <td className="serivce-actions text-right flex">
          {this.props.renderActionButtons ? this.props.renderActionButtons(item) : <React.Fragment>
            <button
              onClick={() => this.editModal(item._id)}
              title="Edit Driver"
              className="btn btn-sm btn-dark"
            >
              <i className="ion ion-md-create" />
            </button>
            <button
              onClick={() =>
               { this.props.delete(true, item._id, "globalPricingDriver"); this.deleteModal(item._id)}
              }
              data-toggle="tooltip"
              title="Delete Driver"
              className="btn btn-sm btn-dark ml-2"
            >
              <i className="ion ion-md-trash" />
            </button>
          </React.Fragment>}
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
        {this.props.linkedServices && (
            <>
              {this.props.showConfirmDeleteModal && (
                <ConfirmDeleteModal
                  // setShowDeleteModal={setShowDeleteModal}
                  linkedServices={this.props.linkedServices}
                />
              )}
              <DeleteSuccessModal delObject={delObject} />
            </>
          )}
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Driver Name</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.props.drivers.map(this.renderDrivers)}
            </tbody>           
          </table>
        </div>
      </div>
    );
  }
}

export default connect(null)(GlobalPricingDriversTable);
