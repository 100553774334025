import React, { Component } from "react";
import "./style.css";
import "./style-m.css";
import logoImg from "./assets/outbooks-logo.webp";
import iconFacebook from "./assets/Icon-Facebook-White.png";
import iconX from "./assets/Icon-X-White.png";
import iconLinkedin from "./assets/Icon-Linkedin-White.png";
import iconYoutube from "./assets/Icon-YouTube-White.png";

import { onInputChange } from "../../store/auth/login/actions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/Loader";

class Booking extends Component {
  constructor(props) {
    super(props);
  }
  
  
  
  render() {
    return (
      <React.Fragment>
        <div>
        <header className="lnheader">
            <div className="lnnav-bar">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks" />
                </a>
              </div>
              <div className="lnmenu">
                <ul className="lnul">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                    <ul class="sb-nav">
                      <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                    </ul>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <div className="lnbtn">
                <a href="https://teststaging.outbooks.com/signup" target="_blank" class="top3btn t3btn3">SIGNUP</a>
                <a href="https://teststaging.outbooks.com/signIn" target="_blank" class="top3btn t3btn2">LOGIN</a>   
                <a href="book-demo" class="top3btn t3btn1">BOOK A FREE DEMO</a>
              </div>
            </div>
          </header>
          {this.props.loading ? <Loader /> : null}
          <div className="lnmain-content">
            <section className="lnsection-one">
              {/* <div className="lnhead-panel">
                <div className="lntext lnleft">
                  <h1 className="lnh1 martno">Schedule Appointment Now!</h1>
                  <p className="lnp">Book Your Spot for Winning Proposals and a Streamlined Sales Journey.</p>
                </div>
              </div> */}
              <div className="lnbooking-panel">
                <a className="lna" name="booking"></a>
                <iframe src="https://calendly.com/amit-outbooks/outbooksproposal" scrolling="yes" width="100%" height="1000" frameborder="0"></iframe>
              </div>
            </section>
          </div>

          <footer className="lnfooter">
            <div className="lncol-1">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks Proposal" />
                </a>
              </div>
              <div className="lnmenu footer-m1">
                <ul className="lnul lnnav-list">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="signup">SIGN UP</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT</a>
                  </li>
                </ul>
              </div>
              <p className="lnp lncopy-right">
                Copyright © Outbooks 2023 | All Rights Reserved.
              </p>
            </div>
            <div className="lncol-2">
              <div className="lnmenu footer-m1">
                <ul className="lnul lnnav-list">
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/contact-us/">Help Center</a>
                    </li>
                    <li>
                      <a className="lna" href="#">Blogs</a>
                    </li>
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/terms-and-conditions/">Terms and Conditions</a>
                    </li>
                  </ul>
              </div>
            </div>
            <div className="lncol-3">
              <p className="lnp lnadr">
                Suite 18, Winsor & Newton Building,
                <br />
                Whitefriars Avenue, Harrow HA3 5RN
              </p>
              <p className="lnp lnregno">Registration No: 10746177</p>
              <ul className="lnul lncontact-links">
                <li>
                  <a className="lna" href="mailto:info@outbooks.com">
                    &#9993; &nbsp; info@outbooks.com
                  </a>
                </li>
                <li>
                  <a className="lna" href="tel:+443300578597">
                    &#9743; &nbsp; +44 330 057 8597
                  </a>
                </li>
              </ul>
              <ul className="lnul lnsocial-links">
                <li>
                  <a className="lna" href="https://www.facebook.com/profile.php?id=61556567720993">
                    <img src={iconFacebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.linkedin.com/company/outbooksproposal/">
                    <img src={iconLinkedin} alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://twitter.com/outbookproposal">
                    <img src={iconX} alt="Twitter / X" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.youtube.com/channel/UCCucVxt5QuYrJI6SDCDW7sQ">
                    <img src={iconYoutube} alt="Youtube" />
                  </a>
                </li>
              </ul>
              <a
                href="https://outbooks.com/proposal/contact-us/"
                className="lna lnpage-btn"
              >
                CONTACT US
              </a>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { email, password, error, loading } = state.Login;
  return { email, password, error, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    onInputChange,
  })(Booking)
);
