import React, { Component ,useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
import PackagesTable from "../components/PackagesTable";
import { getPackages,setShowCreateNewPackageSuccessModal } from "../store/package/actions";
import { setShowConfirmDeleteModal } from "../store/delete/actions";

class Packages extends Component {
  componentDidMount() {
    this.props.getPackages();
  }
  render() {
    return (
      <React.Fragment>
              {/* {this.props.loading && <Loader />} */}

              <div className="page-info-header">
              <div className="container-fluid">
                <div className="page-display-title">Packages</div>
                <div className="text-right flex1">
                  <button onClick={() => this.props.history.push("/packages/new")} className="btn btn-success create-item"><i className="ion ion-md-add-circle"></i> <span>Add New Package</span></button>
                </div>
              </div>
            </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <PackagesTable
                  
                    delete={this.props.setShowConfirmDeleteModal}
                    packages={this.props.packages}
                    pagination={this.props.pagination}
                    linkedServices={this.props.linkedServices} {...this.props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
       {/* <CreateCategoryModal />
        <ConfirmDeleteModal />
        <DeleteSuccessModal /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    packages, pagination, loading,linkedServices
  } = state.Package;
  const { showConfirmDeleteModal } = state.Delete;

  return {
    packages, pagination,linkedServices, loading,showConfirmDeleteModal
  };
}

export default connect(mapStateToProps, { getPackages, setShowConfirmDeleteModal })(Packages);
