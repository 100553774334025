import React, { Component } from "react";
import ServiceListTable from "../components/ServiceListTable";
import Loader from "../components/Loader";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
import { getServicesByCategory, updateServiceActiveKey ,setServiceLoading} from "../store/service/actions";
import { setShowConfirmActiveDeActiveModal} from "../store/activeDeActive/actions";
import { setShowConfirmDeleteModal } from "../store/delete/actions";
import { setShowConfirmDeActiveModal } from "../store/delete/actions";
import { connect } from "react-redux";

class Services extends Component {
  componentDidMount() {
    this.props.setServiceLoading(true);
    this.props.getServicesByCategory();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Services</div>
            <div className="text-right flex1">
              <button onClick={() => this.props.history.push("/services/new")} className="btn btn-success create-item"><i className="ion ion-md-add-circle"></i> <span>Add New Service</span></button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <ServiceListTable delete={this.props.setShowConfirmDeleteModal} deActive={this.props.setShowConfirmDeActiveModal}servicesByCategory={this.props.servicesByCategory} {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ConfirmDeleteModal />
        <DeleteSuccessModal /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { servicesByCategory, loading } = state.Service;
  const { showConfirmDeleteModal, showDeleteObjectSuccessModal } = state.Delete;
  const { showConfirmActiveDeActiveModal} = state.ActiveDeActive;
  return { servicesByCategory, loading, showConfirmDeleteModal , showConfirmActiveDeActiveModal, showDeleteObjectSuccessModal};
}

export default connect(mapStateToProps, { getServicesByCategory, setShowConfirmDeleteModal ,setShowConfirmActiveDeActiveModal, updateServiceActiveKey, setServiceLoading})(Services);
