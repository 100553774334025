import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Paginator from "./Paginator";
import { formatCurrency } from "../Utils";
import { apiBaseUrl } from "../config";
import { ucFirst } from "../Utils";
import { getContracts, downloadContract } from "../store/contract/actions";

class ContractListTable extends Component {
  constructor(props) {
    super(props);
    this.renderContracts = this.renderContracts.bind(this);
  }

  renderContracts(item, index) {
    let statusBadge = "";
    if (item.status === "pending") {
      statusBadge = "badge-primary";
    } else if (item.status === "completed") {
      statusBadge = "badge-success";
    } else if (item.status === "draft") {
      statusBadge = "badge-warning";
    } else if (item.status === "declined") {
      statusBadge = "badge-danger";
    }

    return (
      <tr key={index}>
        <td>
          {item.prefix}-{item.code}
        </td>
        <td>{item.data.client.displayName}</td>
        <td className="quote-status">
          <div className={`badge ${statusBadge}`}>
            {ucFirst(
              item.status === "pending"
                ? "sent"
                : item.status === "completed"
                ? "Signed"
                : item.status
            )}
          </div>
        </td>
        <td>
          {["completed", "pending", "declined"].includes(item.status) && (
            <div>
              <div>
                Recurring:{" "}
                {item.recurring_total
                  ? formatCurrency(item.recurring_total)
                  : "NA"}
              </div>
              <div>
                One-Off:{" "}
                {item.one_off_total ? formatCurrency(item.one_off_total) : "NA"}
              </div>
            </div>
          )}
        </td>
        <td>
          {["completed", "pending", "declined"].includes(item.status) && (
            <div>
              {item.status === "completed" ? (
                <p
                  style={{ cursor: "pointer", color: "#626ED4" }}
                  onClick={() => this.props.downloadContract(item._id)}
                >
                  Engagement Letter PDF
                </p>
              ) : (
                <Link
                  to={{
                    pathname: `/contracts/view-pdf/${btoa(item._id)}`,
                  }}
                  target="_blank"
                >
                  Engagement Letter PDF
                </Link>
              )}
            </div>
          )}
        </td>
        <td className="serivce-actions text-right">
          {item.status === "draft" && (
            <button
              onClick={() =>
                this.props.history.push(`/contracts/edit/${item._id}`)
              }
              data-toggle="tooltip"
              title="Edit Engagement Letter"
              className="btn btn-sm btn-dark ml-2"
            >
              Edit
              <i className="ion ion-md-create ml-1" />
            </button>
          )}
          {["completed", "pending", "declined"].includes(item.status) && (
            <button
              onClick={() => this.props.history.push(`/contracts/${item._id}`)}
              data-toggle="tooltip"
              title="View Engagement Letter"
              className="btn btn-sm btn-dark ml-2"
            >
              View
              <i className="ion ion-md-eye ml-1" />
            </button>
          )}
          {/*<button data-toggle="tooltip" title="Delete Service" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>*/}
        </td>
      </tr>
    );
  }

  render() {
    return this.props.contracts.length > 0 ? (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Ref ID</th>
                <th>Prospects/Clients</th>
                <th>Status</th>
                <th>Value</th>
                <th>Documents</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.props.contracts.map(this.renderContracts)}</tbody>
          </table>
          <Paginator
            totalPages={this.props.pagination.total_pages}
            pageNum={this.props.pagination.page_num}
            getObjects={this.props.getContracts}
            status={this.props.status}
          />
        </div>
      </div>
    ) : (
      <div className="text-center">No Engagement Letter Found!</div>
    );
  }
}

export default withRouter(
  connect(null, { getContracts, downloadContract })(ContractListTable)
);
