import React, { Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../components/Loader";
import { getFirebaseBackend } from "../helpers/authUtils";
import axios from "axios";
import {
  onInputChange,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  getMyOrganisation,
  updateEmailConfigs,
  setShowImageUploadModal,
  setShowUpdateOrganisationSuccessModal,
  uploadLogo,
  uploadSignature,
  resetOrganisationForm,
  companySearch,
  selectCompanySearchItem,
  getEmailConfigs,
} from "../store/organisation/actions";

class EmailConfigs extends Component {
  constructor(props) {
    super(props);
    this.updateOrganisation = this.updateOrganisation.bind(this);
    this.validateEmails = this.validateEmails.bind(this);
    this.resetToDefault = this.resetToDefault.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.state = {
      quote_cc_valid: true,
      quote_bcc_valid: true,
      contract_cc_valid: true,
      contract_bcc_valid: true,
      quote_cc: '',
      quote_bcc: '',
      contract_cc: '',
      contract_bcc: '',
      updatedOnce: false,
      provider: "",
      username: "",
      password: "",
      displayName: "",
      successAlertVisible: false,
      successDeleteAlertVisible: false,
      loading: false,
      errorAlertVisible: false,
      errorMessage: "",
      providerError: false,
      usernameError: false,
      passwordError: false,
      displayNameError: false,
      confirmReset: false,
      resetButtonVisible: false,
    }
    this.showHintMessage = this.showHintMessage.bind(this);
  }
  componentDidMount() {
    // this.props.resetOrganisationForm();
    this.fetchEmailConfiguration();
    this.props.getEmailConfigs();
  }

  componentDidUpdate() {
    if (!this.state.updatedOnce && (this.props.quote_cc || this.props.quote_bcc || this.props.contract_cc || this.props.contract_bcc)) {
      this.setState({
        quote_cc: this.props.quote_cc,
        quote_bcc: this.props.quote_bcc,
        contract_cc: this.props.contract_cc,
        contract_bcc: this.props.contract_bcc,
        updatedOnce: true
      });
    }
  }
  //   componentDidUpdate() {}
  //   componentWillUnmount() {
  //     this.props.resetOrganisationForm();
  //   }
  validateEmails() {
    let submitForm = true;
    let quote_cc_valid = true;
    let quote_bcc_valid = true;
    let contract_cc_valid = true;
    let contract_bcc_valid = true;
    let tester =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (this.state.quote_cc && this.state.quote_cc != '') {
      const arrEmails = this.state.quote_cc.split(',');
      arrEmails.map((email) => {
        email = email.trim();
        if (!email) {
          quote_cc_valid = false;
        }

        if (email.length > 256) {
          quote_cc_valid = false;
        }

        if (!tester.test(email)) {
          quote_cc_valid = false;
        }
      });
    }
    if (this.state.quote_bcc && this.state.quote_bcc != '') {
      const arrEmails = this.state.quote_bcc.split(',');
      arrEmails.map((email) => {
        email = email.trim();
        if (!email) {
          quote_bcc_valid = false;
        }

        if (email.length > 256) {
          quote_bcc_valid = false;
        }

        if (!tester.test(email)) {
          quote_bcc_valid = false;
        }
      });
    }
    if (this.state.contract_cc && this.state.contract_cc != '') {
      const arrEmails = this.state.contract_cc.split(',');
      arrEmails.map((email) => {
        email = email.trim();
        if (!email) {
          contract_cc_valid = false;
        }

        if (email.length > 256) {
          contract_cc_valid = false;
        }

        if (!tester.test(email)) {
          contract_cc_valid = false;
        }
      });
    }
    if (this.state.contract_bcc && this.state.contract_bcc != '') {
      const arrEmails = this.state.contract_bcc.split(',');
      arrEmails.map((email) => {
        email = email.trim();
        if (!email) {
          contract_bcc_valid = false;
        }

        if (email.length > 256) {
          contract_bcc_valid = false;
        }

        if (!tester.test(email)) {
          contract_bcc_valid = false;
        }
      });
    }
    if (!quote_cc_valid || !quote_bcc_valid || !contract_cc_valid || !contract_bcc_valid) {
      submitForm = false;
    }
    this.setState({
      quote_cc_valid,
      quote_bcc_valid,
      contract_cc_valid,
      contract_bcc_valid,
    })
    return submitForm;
  }
  updateOrganisation() {
    if (!this.validateEmails()) return;
    this.props.updateEmailConfigs({
      quote_cc: this.state.quote_cc,
      quote_bcc: this.state.quote_bcc,
      contract_cc: this.state.contract_cc,
      contract_bcc: this.state.contract_bcc,
    });
  }

  fetchEmailConfiguration() {
    this.setState({ loading: true });
    const token = JSON.parse(localStorage.getItem('authUser'));
    const fireBaseBackend = getFirebaseBackend();
    const orgId = fireBaseBackend.getOrganistionId();

    axios.get(`https://teststaging.outbooks.com/api/get-email-configuration/${orgId}`, {
      headers: {
        'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
      }
    })
      .then(response => {
        const emailConfigData = response.data.result[0];
        this.setState({
          provider: emailConfigData.provider,
          username: emailConfigData.username,
          password: emailConfigData.password,
          displayName: emailConfigData.displayName,
          loading: false,
          resetButtonVisible:true
        });
      })
      .catch(error => {
        console.error("Error fetching email configuration:", error);
        this.setState({ loading: false });
      });
  }

  validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  clearFields = () => {
    this.setState({
      provider: "",
      username: "",
      password: "",
      displayName: "",
    });
  }

  confirmResetToDefault() {
    this.setState({ loading: true, confirmReset: false });
    const token = JSON.parse(localStorage.getItem('authUser'));
    const fireBaseBackend = getFirebaseBackend();
    const orgId = fireBaseBackend.getOrganistionId();

    axios.delete(`https://teststaging.outbooks.com/api/delete-email-configuration/${orgId}`, {
      headers: {
        'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
      }
    })
      .then(response => {
        const emailConfigData = response.data.message;
        this.setState({
          successDeleteAlertVisible: true,
          loading: false
        });
        console.log("Email configuration deleted successfully:", emailConfigData);
      })
      .catch(error => {
        console.error("Failed to delete email configuration data:", error);
        this.setState({
          loading: false,
          errorAlertVisible: true,
          errorMessage: error.message
        });
      });
  }

  resetToDefault() {
    this.setState({ confirmReset: true });
  }

  clearCcFields = () => {
    this.setState({
      quote_cc: "",
      quote_bcc: "",
      contract_cc: "",
      contract_bcc: "",
    });
  }

  handleSave = () => {
    this.setState({
      providerError: false,
      usernameError: false,
      passwordError: false,
      displayNameError: false,
      errorMessage: ""
    });

    let isValid = true;
    if (this.state.provider === "") {
      this.setState({ providerError: true });
      isValid = false;
    }
    if (this.state.username === "") {
      this.setState({ usernameError: true });
      isValid = false;
    }
    if (this.state.password === "") {
      this.setState({ passwordError: true });
      isValid = false;
    }
    if (this.state.displayName === "") {
      this.setState({ displayNameError: true });
      isValid = false;
    }

    if (!isValid) return;

    this.setState({ loading: true });
    const token = JSON.parse(localStorage.getItem('authUser'));
    const fireBaseBackend = getFirebaseBackend();
    const orgId = fireBaseBackend.getOrganistionId();
    const payload = {
      provider: this.state.provider,
      username: this.state.username,
      organisation: orgId,
      password: this.state.password,
      displayName: this.state.displayName,
    };

    axios.post("https://teststaging.outbooks.com/api/add-update-email-configuration", payload, {
      headers: {
        'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
      }
    })
      .then(response => {
        console.log("Success:", response.data);
        this.setState({ successAlertVisible: true, loading: false });
        this.fetchEmailConfiguration();
      })
      .catch(error => {
        console.error("Error:", error);
        let errorMessage = 'An error occurred';
        if (error.response && error.response.status === 400) {
          errorMessage = 'Invalid credentials';
        } else if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.setState({
          loading: false,
          errorAlertVisible: true,
          errorMessage: errorMessage
        });
        this.fetchEmailConfiguration();
      });
  }

  showHintMessage(event) {
    event.preventDefault();
    const { provider } = this.state;
    const providerInstructions = {
      "bt-internet": "To create an app password for BT Internet:\n\n1. Go to your BT Internet account security settings.\n2. Look for 'App passwords' or a similar option.\n3. Generate a new app password.\n4. Copy the generated password and enter it here.",
      "gmail": "To create an app password for Gmail:\n\n1. Go to your Google Account.\n2. Select 'Manage your Google Account'.\n3. Select 'Security' from the left-hand menu.\n4. Ensure '2-Step Verification' is turned on. If it is off, turn it on.\n5. Once '2-Step Verification' is on, look for the 'App passwords' option in search box.\n6. Generate a new app password.\n7. Copy the generated password and enter it here.",
      "hot-mail": "To create an app password for Hotmail:\n\n1. Go to your browser and log in to your Hotmail account.\n2. Go to 'My Profile' and then navigate to the 'Security' section.\n3. Look for the option 'Manage how you sign in' and click on it.\n4. If you have not turned on two-step verification, please enable it now.\n5. Scroll down to find the 'App passwords' section.\n6. Create a new app password.\n7. Copy the generated password and enter it here.",
      "microsoft": "To create an app password for Microsoft:\n\n1. Go to your browser and log in to your Microsoft account.\n2. Go to 'My Profile' and then navigate to the 'Security' section.\n3. Look for the option 'Manage how you sign in' and click on it.\n4. If you have not turned on two-step verification, please enable it now.\n5. Scroll down to find the 'App passwords' section.\n6. Create a new app password.\n7. Copy the generated password and enter it here.",
      "office365": "To create an app password for Office 365:\n\nFor Personal Accounts:\n\n1. Go to your browser and log in to your Office 365 account.\n2. Go to 'My Profile' and then navigate to the 'Security' section.\n3. Look for the option 'Manage how you sign in' and click on it.\n4. If you have not turned on two-step verification, please enable it now.\n5. Scroll down to find the 'App passwords' section.\n6. Create a new app password.\n7. Copy the generated password and enter it here.\n\nFor Work Accounts:\n\n1. Go to your browser and log in to your Office 365 account.\n2. Go to 'View account' and then navigate to the 'Security info' section.\n3. If you have not turned on two-step verification, please enable it now.\n4. Find and click on 'Add sign-in method'.\n5. Choose 'App password' method and click on 'Next'.\n6. Enter an app name and click on 'Next', you will get an app password.\n7. Copy the generated password and enter it here.",
      "yahoo": "To create an app password for Yahoo:\n\n1. Go to your browser and log in to your Yahoo account.\n2. Go to 'My Profile' and click on 'Manage your account'.\n3. Then, go to the 'Security' section.\n4. Look for the option 'Generate app password' and click on it.\n5. Generate your app password.\n6. Copy the generated password and enter it here."
    };

    if (provider && providerInstructions[provider]) {
      alert(providerInstructions[provider]);
    } else {
      alert("Please select a provider to see the instructions.");
    }
  }


  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Email Configuration</div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card cus-card" >
                <div className="card-body">
                  <h3 style={{ marginTop: "-10px", fontSize: "18px", textDecoration: "underline" }} >Mail box configuration</h3>
                  <div className="row fieldset">
                    <div className="col-6">
                      <label className="fieldset-label required">Select provider</label>
                      <select
                        name="provider"
                        className="form-control input-text"
                        value={this.state.provider}
                        onChange={this.handleInputChange}
                        placeholder="Select..."
                      >
                        <option value="">Select...</option>
                        {/* <option value="bt-internet">BT Internet</option> */}
                        <option value="gmail">Gmail</option>
                        <option value="hot-mail">Hotmail</option>
                        <option value="microsoft">Microsoft</option>
                        <option value="office365">Office 365</option>
                        <option value="yahoo">Yahoo</option>
                      </select>
                      {this.state.providerError && this.state.provider === "" && <span className="text-danger">This field is required.</span>}
                    </div>
                    <div className="col-6">
                      <label className="fieldset-label required">Email address</label>
                      <input
                        type="username"
                        name="username"
                        className="form-control input-text"
                        value={this.state.username}
                        onChange={this.handleInputChange}
                        placeholder="Enter your email address"
                      />
                      {this.state.usernameError && this.state.username === "" && <span className="text-danger">This field is required.</span>}
                      {!this.validateEmail(this.state.username) && this.state.username !== "" && <span className="text-danger">Please enter valid email address.</span>}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-6">
                      <label className="fieldset-label required">
                        App password
                        <a href="#" onClick={this.showHintMessage} style={{ marginLeft: "5px", fontSize: "14px" }}>
                          (How to create?)
                        </a>
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control input-text"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        placeholder="Enter your app password"
                      />
                      {this.state.passwordError && this.state.password === "" && <span className="text-danger">This field is required.</span>}
                    </div>
                    <div className="col-6">
                      <label className="fieldset-label required">Display name</label>
                      <input
                        type="text"
                        name="displayName"
                        className="form-control input-text"
                        value={this.state.displayName}
                        onChange={this.handleInputChange}
                        placeholder="Enter your display name"
                      />
                      {this.state.displayNameError && this.state.displayName === "" && <span className="text-danger">This field is required.</span>}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        className="btn"
                        style={{ marginRight: "5px", backgroundColor: "aliceblue" }}
                        onClick={this.clearFields}
                      >
                        Clear
                      </button>
                      { this.state.resetButtonVisible && <button
                        className="btn"
                        style={{ marginRight: "5px", backgroundColor: "aliceblue" }}
                        onClick={this.resetToDefault}
                      >
                        Reset to default
                      </button>}
                      <button
                        className="btn create-item col-1"
                        onClick={this.handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.successAlertVisible && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.setState({ successAlertVisible: false });
              this.props.history.replace("/email-config");
            }}
          >
            <div className="mb-2 success-alert-message">
              You will now receive a test email on <strong>{this.state.username}</strong> to verify the Mailbox configuration.
            </div>
          </SweetAlert>
        )}
        {this.state.successDeleteAlertVisible && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.setState({ successDeleteAlertVisible: false });
              this.props.history.replace("/email-config");
              window.location.reload();
            }}
          >
            <div className="mb-2 success-alert-message">
              Mailbox configuration data delete successfully.
            </div>
          </SweetAlert>
        )}
        {this.state.errorAlertVisible && (
          <SweetAlert
            title="Error"
            error
            confirmBtnBsStyle="danger"
            onConfirm={() => this.setState({ errorAlertVisible: false })}
          >
            <div className="mb-2 error-alert-message">
              {this.state.errorMessage}
            </div>
          </SweetAlert>
        )}
        {this.state.loading && <Loader />}
        {this.state.confirmReset && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, reset it!"
            cancelBtnText="No, cancel"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            // title="Are you sure?"
            onConfirm={() => this.confirmResetToDefault()}
            onCancel={() => this.setState({ confirmReset: false })}
          >
            Are you sure you want to reset to default?
          </SweetAlert>
        )}

        {/* <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Email Config</div>
          </div>
        </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card cus-card" style={{ marginTop: '-76px' }}>
                <div className="card-body">
                  <h3 style={{ marginTop: "-10px", fontSize: "18px", textDecoration: "underline" }} >CC/BCC Mail configuration for Proposal and Engagement Letter</h3>
                  <div className="row fieldset">
                    <div className="col-6">
                      <label className="fieldset-label">Proposal CC</label>
                      <input
                        type="text"
                        className="input-text"
                        min={0}
                        onChange={(e) => {
                          // this.props.onInputChange("quote_cc", e.target.value);
                          this.setState({
                            quote_cc: e.target.value
                          });
                        }}
                        value={this.state.quote_cc}
                      />
                      {!this.state.quote_cc_valid ? <p style={{ color: 'red' }}>Please enter correct email address</p> : null}
                    </div>
                    <div className="col-6">
                      <label className="fieldset-label">Proposal BCC</label>
                      <input
                        type="text"
                        className="input-text"
                        min={0}
                        onChange={(e) => {
                          // this.props.onInputChange("quote_bcc", e.target.value);
                          this.setState({
                            quote_bcc: e.target.value
                          });
                        }}
                        value={this.state.quote_bcc}
                      />
                      {!this.state.quote_bcc_valid ? <p style={{ color: 'red' }}>Please enter correct email address</p> : null}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-6">
                      <label className="fieldset-label">Engagement Letter CC</label>
                      <input
                        type="text"
                        className="input-text"
                        min={0}
                        onChange={(e) => {
                          // this.props.onInputChange("contract_cc", e.target.value);
                          this.setState({
                            contract_cc: e.target.value
                          });
                        }}
                        value={this.state.contract_cc}
                      />
                      {!this.state.contract_cc_valid ? <p style={{ color: 'red' }}>Please enter correct email address</p> : null}
                    </div>

                    <div className="col-6">
                      <label className="fieldset-label">Engagement Letter BCC</label>
                      <input
                        type="text"
                        className="input-text"
                        min={0}
                        onChange={(e) => {
                          // this.props.onInputChange("contract_bcc", e.target.value);
                          this.setState({
                            contract_bcc: e.target.value
                          });
                        }}
                        value={this.state.contract_bcc}
                      />
                      {!this.state.contract_bcc_valid ? <p style={{ color: 'red' }}>Please enter correct email address</p> : null}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        className="btn"
                        style={{ marginRight: "5px", backgroundColor: "aliceblue" }}
                        onClick={this.clearCcFields}
                      >
                        Clear
                      </button>
                      <button
                        className="btn create-item col-1"
                        onClick={this.updateOrganisation}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.showUpdateOrganisationSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.props.setShowUpdateOrganisationSuccessModal(false)
            }
          >
            <div className="mb-2 success-alert-message">
              Email Configs has been updated successfully!
            </div>
          </SweetAlert>
        )}
        {this.props.emailConfigsLoading && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => state.Organisation;

export default connect(mapStateToProps, {
  onInputChange,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  getMyOrganisation,
  updateEmailConfigs,
  setShowImageUploadModal,
  setShowUpdateOrganisationSuccessModal,
  uploadLogo,
  uploadSignature,
  resetOrganisationForm,
  companySearch,
  selectCompanySearchItem,
  getEmailConfigs,
})(EmailConfigs);
