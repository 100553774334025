import React, { Component } from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/Loader";
import { getFirebaseBackend } from "../../helpers/authUtils";

class EmailConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provider: "",
            username: "",
            password: "",
            displayName: "",
            successAlertVisible: false,
            loading: false,
            errorAlertVisible: false,
            errorMessage: "",
            providerError: false,
            usernameError: false,
            passwordError: false,
            displayNameError: false
        };

        this.showHintMessage = this.showHintMessage.bind(this);
    }

    componentDidMount() {
        this.fetchEmailConfiguration();
    }

    fetchEmailConfiguration() {
        this.setState({ loading: true });
        const token = JSON.parse(localStorage.getItem('authUser'));
        const fireBaseBackend = getFirebaseBackend();
        const orgId = fireBaseBackend.getOrganistionId();

        axios.get(`https://teststaging.outbooks.com/api/get-email-configuration/${orgId}`, {
            headers: {
                'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
            }
        })
            .then(response => {
                const emailConfigData = response.data.result[0];
                this.setState({
                    provider: emailConfigData.provider,
                    username: emailConfigData.username,
                    password: emailConfigData.password,
                    displayName: emailConfigData.displayName,
                    loading: false
                });
            })
            .catch(error => {
                console.error("Error fetching email configuration:", error);
                this.setState({ loading: false });
            });
    }

    validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    clearFields = () => {
        this.setState({
            provider: "",
            username: "",
            password: "",
            displayName: "",
        });
    }

    handleSave = () => {
        this.setState({
            providerError: false,
            usernameError: false,
            passwordError: false,
            displayNameError: false,
            errorMessage: ""
        });

        let isValid = true;
        if (this.state.provider === "") {
            this.setState({ providerError: true });
            isValid = false;
        }
        if (this.state.username === "") {
            this.setState({ usernameError: true });
            isValid = false;
        }
        if (this.state.password === "") {
            this.setState({ passwordError: true });
            isValid = false;
        }
        if (this.state.displayName === "") {
            this.setState({ displayNameError: true });
            isValid = false;
        }

        if (!isValid) return;

        this.setState({ loading: true });
        const token = JSON.parse(localStorage.getItem('authUser'));
        const fireBaseBackend = getFirebaseBackend();
        const orgId = fireBaseBackend.getOrganistionId();
        const payload = {
            provider: this.state.provider,
            username: this.state.username,
            organisation: orgId,
            password: this.state.password,
            displayName: this.state.displayName,
        };

        axios.post("https://teststaging.outbooks.com/api/add-update-email-configuration", payload, {
            headers: {
                'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
            }
        })
            .then(response => {
                console.log("Success:", response.data);
                this.setState({ successAlertVisible: true, loading: false });
            })
            .catch(error => {
                console.error("Error:", error);
                let errorMessage = 'An error occurred';
                if (error.response && error.response.status === 400) {
                    errorMessage = 'Invalid credentials';
                } else if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                this.setState({
                    loading: false,
                    errorAlertVisible: true,
                    errorMessage: errorMessage
                });
            });
    }

    showHintMessage(event) {
        event.preventDefault();
        const { provider } = this.state;
        const providerInstructions = {
            "bt-internet": "To create an app password for BT Internet:\n\n1. Go to your BT Internet account security settings.\n2. Look for 'App passwords' or a similar option.\n3. Generate a new app password.\n4. Copy the generated password and enter it here.",
            "gmail": "To create an app password for Gmail:\n\n1. Go to your Google Account.\n2. Select 'Manage your Google Account'.\n3. Select 'Security' from the left-hand menu.\n4. Ensure '2-Step Verification' is turned on. If it is off, turn it on.\n5. Once '2-Step Verification' is on, look for the 'App passwords' option in search box.\n6. Generate a new app password.\n7. Copy the generated password and enter it here.",
            "hot-mail": "To create an app password for Hotmail:\n\n1. Go to your Microsoft account security settings.\n2. Look for 'App passwords' or a similar option.\n3. Generate a new app password.\n4. Copy the generated password and enter it here.",
            "microsoft": "To create an app password for Microsoft:\n\n1. Go to your Microsoft account security settings.\n2. Look for 'App passwords' or a similar option.\n3. Generate a new app password.\n4. Copy the generated password and enter it here.",
            "office365": "To create an app password for Office 365:\n\n1. Go to your Office 365 account security settings.\n2. Look for 'App passwords' or a similar option.\n3. Generate a new app password.\n4. Copy the generated password and enter it here.",
            "yahoo": "To create an app password for Yahoo:\n\n1. Go to your Yahoo account security settings.\n2. Look for 'Generate app password' or a similar option.\n3. Generate a new app password.\n4. Copy the generated password and enter it here."
        };

        if (provider && providerInstructions[provider]) {
            alert(providerInstructions[provider]);
        } else {
            alert("Please select a provider to see the instructions.");
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-info-header">
                    <div className="container-fluid">
                        <div className="page-display-title">Email Configuration</div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card cus-card">
                                <div className="card-body">
                                    <div className="row fieldset">
                                        <div className="col-6">
                                            <label className="fieldset-label required">Select provider</label>
                                            <select
                                                name="provider"
                                                className="form-control input-text"
                                                value={this.state.provider}
                                                onChange={this.handleInputChange}
                                                placeholder="Select..."
                                            >
                                                <option value="">Select...</option>
                                                {/* <option value="bt-internet">BT Internet</option> */}
                                                <option value="gmail">Gmail</option>
                                                <option value="hot-mail">Hotmail</option>
                                                <option value="microsoft">Microsoft</option>
                                                <option value="office365">Office 365</option>
                                                <option value="yahoo">Yahoo</option>
                                            </select>
                                            {this.state.providerError && this.state.provider === "" && <span className="text-danger">This field is required.</span>}
                                        </div>
                                        <div className="col-6">
                                            <label className="fieldset-label required">Email address</label>
                                            <input
                                                type="username"
                                                name="username"
                                                className="form-control input-text"
                                                value={this.state.username}
                                                onChange={this.handleInputChange}
                                                placeholder="Enter your email address"
                                            />
                                            {this.state.usernameError && this.state.username === "" && <span className="text-danger">This field is required.</span>}
                                            {!this.validateEmail(this.state.username) && this.state.username !== "" && <span className="text-danger">Please enter valid email address.</span>}
                                        </div>
                                    </div>
                                    <div className="row fieldset">
                                        <div className="col-6">
                                            <label className="fieldset-label required">
                                                App password
                                                <a href="#" onClick={this.showHintMessage} style={{ marginLeft: "5px", fontSize: "14px" }}>
                                                    (How to create?)
                                                </a>
                                            </label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control input-text"
                                                value={this.state.password}
                                                onChange={this.handleInputChange}
                                                placeholder="Enter your app password"
                                            />
                                            {this.state.passwordError && this.state.password === "" && <span className="text-danger">This field is required.</span>}
                                        </div>
                                        <div className="col-6">
                                            <label className="fieldset-label required">Display name</label>
                                            <input
                                                type="text"
                                                name="displayName"
                                                className="form-control input-text"
                                                value={this.state.displayName}
                                                onChange={this.handleInputChange}
                                                placeholder="Enter your display name"
                                            />
                                            {this.state.displayNameError && this.state.displayName === "" && <span className="text-danger">This field is required.</span>}
                                        </div>
                                    </div>
                                    <div className="row fieldset">
                                        <div className="col-12 d-flex justify-content-end">
                                            <button
                                                className="btn"
                                                style={{ marginRight: "5px", backgroundColor: "aliceblue" }}
                                                onClick={this.clearFields}
                                            >
                                                Clear
                                            </button>
                                            <button
                                                className="btn create-item col-1"
                                                onClick={this.handleSave}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.successAlertVisible && (
                    <SweetAlert
                        title=""
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            this.setState({ successAlertVisible: false });
                            this.props.history.replace("/settings/email-configuration");
                        }}
                    >
                        <div className="mb-2 success-alert-message">
                            Record saved successfully.
                        </div>
                    </SweetAlert>
                )}
                {this.state.errorAlertVisible && (
                    <SweetAlert
                        title="Error"
                        error
                        confirmBtnBsStyle="danger"
                        onConfirm={() => this.setState({ errorAlertVisible: false })}
                    >
                        <div className="mb-2 error-alert-message">
                            {this.state.errorMessage}
                        </div>
                    </SweetAlert>
                )}
                {this.state.loading && <Loader />}
            </React.Fragment>
        );
    }
}

export default EmailConfig;

