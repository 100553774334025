import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { connect } from "react-redux";
import Paginator from "./Paginator";
import { formatCurrency } from "../Utils";
import { getPackages } from '../store/package/actions';
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
const delObject = "Package";

class PackagesTable extends Component {
	
	constructor(props) {
		super(props)
		this.renderPackages = this.renderPackages.bind(this)
		this.deleteModal = this.deleteModal

	}
	deleteModal(id){
		this.props.getPackages(id);
	}
	renderPackages(item, index) {
		return (
			<tr key={index}>
				<td>{!item.is_valid && <i className="ion ion-md-warning mr-1 text-danger"></i>}{item.name ? item.name.substring(0,50) : ''}</td>
				{/*<td>Sole Trader, Company, LLP</td>
			<td>Large Business, Small Business</td>
			<td>53%</td>*/}
				<td>
					<div>
						<div>Recurring: <b>{formatCurrency(item.recurring_original_price ? item.recurring_original_price : 0)}</b></div>
						<div>One Off: <b>{formatCurrency(item.one_off_original_price ? item.one_off_original_price : 0)}</b></div>
					</div>
				</td>
				<td>
					<div>
						<div>Recurring: <b>{formatCurrency(item.recurring_default_price ? item.recurring_default_price : 0)}</b></div>
						<div>One Off: <b>{formatCurrency(item.one_off_default_price ? item.one_off_default_price : 0)}</b></div>
					</div>
				</td>
				<td>
					<div>
						<div>Recurring: <b>{formatCurrency(item.recurring_min_price ? item.recurring_min_price : 0)}</b></div>
						<div>One Off: <b>{formatCurrency(item.one_off_min_price ? item.one_off_min_price : 0)}</b></div>
					</div>
				</td>
				<td><Badge color={item.is_active ? "success" : "danger"}>{item.is_active ? "Active" : "Inactive"}</Badge></td>
				<td className="text-right">
					<button onClick={() => this.props.history.push(`/packages/edit/${item._id}`)} data-toggle="tooltip" title="Edit Package" className="btn btn-sm btn-dark"><i className="ion ion-md-create" /></button>
					{/*<button data-toggle="tooltip" title="Clone Service" className="btn btn-sm btn-dark ml-2"><i className="ion ion-ios-copy" /></button>*/}
					
					 <button
					 onClick={() =>
					  {this.props.delete(true, item._id, "package"); this.deleteModal(item._id)}
					 }
					 data-toggle="tooltip"
					 title="Delete Package"
					 className="btn btn-sm btn-dark ml-2"
				   >
					 <i className="ion ion-md-trash" />
				   </button>

				</td>
			</tr>
		);
	}

	render() {
		return (
			this.props.packages.length > 0 ? (<div className="table-rep-plugin">
				<div className="table-responsive mb-0" data-pattern="priority-columns">
				{this.props.linkedServices && (
					<>
					  {this.props.showConfirmDeleteModal && (
						<ConfirmDeleteModal
						  // setShowDeleteModal={setShowDeleteModal}
						  linkedServices={this.props.linkedServices}
						/>
					  )}
					  <DeleteSuccessModal delObject={delObject} />
					</>
				  )}
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Packages</th>
								{/*<th>Enquiry Types</th>
			  			<th>Business Types</th>
			  			<th>Default Discount (%)</th>*/}
								<th>Original Price</th>
								<th>Default Price</th>
								<th>Minimum Price</th>
								<th>Status</th>
								<th className="action-button-column"></th>
							</tr>
						</thead>
						<tbody>
							{this.props.packages.map(this.renderPackages)}
						</tbody>
					</table>
					<Paginator
						totalPages={this.props.pagination.total_pages}
						pageNum={this.props.pagination.page_num}
						getObjects={this.props.getPackages}
					/>
				</div>
			</div>) : (<div className="text-center">No Packages Found!</div>)
		);
	}
}

export default withRouter(connect(null, { getPackages })(PackagesTable));
