import React, { Component } from "react";
// import SettingMenu from "./Shared/SettingMenu";
import { Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
// Custom Scrollbar
// import SimpleBar from "simplebar-react";

// import images
import servicesIcon1 from "../assets/images/services-icon/01.png";
import servicesIcon2 from "../assets/images/services-icon/02.png";
import servicesIcon3 from "../assets/images/services-icon/03.png";
import servicesIcon4 from "../assets/images/services-icon/04.png";
// import user2 from "../assets/images/users/user-2.jpg";
// import user3 from "../assets/images/users/user-3.jpg";
// import user4 from "../assets/images/users/user-4.jpg";
// import user5 from "../assets/images/users/user-5.jpg";
// import user6 from "../assets/images/users/user-6.jpg";
// import smimg1 from "../assets/images/small/img-1.jpg";
// import smimg2 from "../assets/images/small/img-2.jpg";

// Charts
// import LineAreaChart from "./AllCharts/apex/lineareachart";
// import RadialChart from "./AllCharts/apex/apexdonut";
// import Apexdonut from "./AllCharts/apex/apexdonut1";
// import SparkLine from "./AllCharts/sparkline/sparkline";
// import SparkLine1 from "./AllCharts/sparkline/sparkline1";
// import Salesdonut from "./AllCharts/apex/salesdonut";

// import "chartist/dist/scss/chartist.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import ActivityLogsTable from "../components/ActivityLogsTable";
import { disableCursor } from "fullcalendar";
import { Refresh, VapingRoomsRounded } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "../assets/scss/custom/components/_dashboard.scss";
import {getDashboard,
  getFilteredDashboardDataAction,
  getFilteredDashboard,
} from "../store/dashboard/actions";
import * as XLSX from "xlsx";
import { getFirebaseBackend } from "../helpers/authUtils";
import excel from "../assets/images/excel.png";
import axios from 'axios';


const names = [
  "All",
  "Current Week",
  "Last Week",
  "Current Month",
  "Last Month",
  "Last 3 Months",
  // "LAST QUARTER",// need to remove
  "Last 6 Months",
  // "LAST HALF YEAR", // need to remove
  "Current Financial Year", // Current Financial Year - 1st apr to next year march 31
  "Last Financial Year", // Last Financial Year - 1st apr 2022 to 31 march 2023
  // "CUSTOM DATE RANGE", // Select Date Range
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "",
    organisationData: null,
    error: null,  };
    const fireBaseBackend = getFirebaseBackend();
    this.organizationName = fireBaseBackend.getOrganistionName();
  }

  componentDidMount() {
    const storedFilter = localStorage.getItem("dashboardFilter");
    if (storedFilter) {
      this.setState({ name: storedFilter });
      if (storedFilter === "All") {
        this.props.getDashboard();
      } else {
        this.props.getFilteredDashboard(storedFilter.replace(/\s+/g, "-"));
        this.props.getDashboard();
      }
    } else {
      // If there is no stored filter, fetch the default dashboard data
      this.props.getDashboard();
    }

    const token = JSON.parse(localStorage.getItem('authUser'));
  
    if (token && token.stsTokenManager && token.stsTokenManager.accessToken) {
      axios.get('https://teststaging.outbooks.com/api/organisation/me', {
        headers: {
          'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
        }
      })
      .then(response => {
        this.setState({ organisationData: response.data });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
    } else {
      this.setState({ error: 'Token not found or invalid.' });
    }
  }

  render() {
    const { getCurrency } = this.props;
    // const ITEM_HEIGHT = 40;
    // const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: "auto",
          width: 200,
        },
      },
    };

    function getStyles(name, personName) {
      return {
        fontWeight: 100,
      };
    }
    const modifiedNames = this.state.name.replace(/\s+/g, "-");

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      var modifiedValue = value.replace(/\s+/g, "-");
      localStorage.setItem("dashboardFilter", modifiedValue);
      if (modifiedValue === "All") {
        this.props.getDashboard();
        this.setState({ name: value });
      } else {
        this.setState({ name: value });
        this.props.getFilteredDashboard(modifiedValue);
      }

      // if (modifiedValue === "CUSTOM-DATE-RANGE") {
      //   this.setState({ showCalendarPopup: true });
      // } else {
      //   this.setState({ showCalendarPopup: false });
      // }
    };

    const handleExport = () => {
      // // Get the current date
      // const currentDate = new Date();

      // // Extract year, month, and day
      // const year = currentDate.getFullYear();
      // const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
      // const day = String(currentDate.getDate()).padStart(2, "0");

      // // Format the date as "YYYY-MM-DD"
      // const formattedDate = `${year}-${month}-${day}`;

      // Replace this with your actual dashboard data
      const data = [
        [`Practice Name : ${this.organizationName}`],
        [`Reporting Period : ${modifiedNames ? modifiedNames : "All"}`],
        [],
        ["", "Count"],
        [
          "Proposals Draft",
          this.state.name && this.state.name != "All"
            ? this.props.filterDraftQuotes?.length
            : this.props.draftQuotes,
        ],
        [
          "Proposals Sent",
          this.state.name && this.state.name != "All"
            // ? this.props.filterSentQuotes?.length
            // : this.props.sentQuotes,
            ? this.props.filterSentQuotes?.length +
                this.props.filterAcceptedQuotes?.length +
                this.props.filterDeclinedQuotes?.length
              : this.props.sentQuotes +
                this.props.acceptedQuotes +
                this.props.declinedQuotes,
        ],
      ];
      if (!this.state?.organisationData?.result?.isVisible) {
        data.push(
          [
            "Proposals Awaiting for Response",
            this.state.name && this.state.name !== "All"
              ? this.props.filterSentQuotes?.length
              : this.props.sentQuotes,
          ],
          [
            "Proposals Accepted",
            this.state.name && this.state.name !== "All"
              ? this.props.filterAcceptedQuotes?.length
              : this.props.acceptedQuotes,
          ],
          [
            "Proposals Declined",
            this.state.name && this.state.name !== "All"
              ? this.props.filterDeclinedQuotes?.length
              : this.props.declinedQuotes,
          ]
        );
      }
      if (this.state?.organisationData?.result?.isVisible) {
        data.push(
          [
            "Engagement Letters Draft",
            this.state.name && this.state.name !== "All"
              ? this.props.filterDraftContracts?.length
              : this.props.draftContracts,
          ],
          [
            "Engagement Letters Sent",
            this.state.name && this.state.name !== "All"
              ? this.props.filterPendingContracts?.length +
                this.props.filterCompletedContracts?.length +
                this.props.filterDeclinedContracts?.length
              : this.props.completedContracts +
                this.props.pendingContracts +
                this.props.declinedContracts,
          ],
          [
            "Engagement Letters Signed",
            this.state.name && this.state.name !== "All"
              ? this.props.filterCompletedContracts?.length
              : this.props.completedContracts,
          ],
          [
            "Engagement Letters Awaiting Signature",
            this.state.name && this.state.name !== "All"
              ? this.props.filterPendingContracts?.length
              : this.props.pendingContracts,
          ],
          [
            "Engagement Letters Declined",
            this.state.name && this.state.name !== "All"
              ? this.props.filterDeclinedContracts?.length
              : this.props.declinedContracts,
          ]
        );
      }


      const proposalDraftData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterDraftQuotes ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])
          : (this.props.draftQuotesData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])),
      ];
      const proposalSentData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
          "Send Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? [
              ...(this.props.filterAcceptedQuotes ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.filterSentQuotes ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.filterDeclinedQuotes ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              ]),
            ]
          : [
              ...(this.props.acceptedQuotesData ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.sentQuotesData ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.declinedQuotesData ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              ]),
            ]),
      ];


      const proposalPendingData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
          "Send Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterSentQuotes ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])
          : (this.props.sentQuotesData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])),
      ];
      
      const proposalAcceptedData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
          "Accepted Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterAcceptedQuotes ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])
          : (this.props.acceptedQuotesData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])),
      ];

      const proposalDeclinedData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
          "Declined Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterDeclinedQuotes ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])
          : (this.props.declinedQuotesData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])),
      ];


      const engLetterDraftData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterDraftContracts ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])
          : (this.props.draftContractsData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
            ])),
      ];

      const engLetterSendData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          " Created Date",
          "Last Updated",
          "Sent Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? [
              ...(this.props.filterCompletedContracts ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.sent_at).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.filterPendingContracts ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.sent_at).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.filterDeclinedContracts ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.sent_at).toLocaleDateString("en-GB"),
              ]),
            ]
          : [
              ...(this.props.completedContractsData ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.sent_at).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.pendingContractsData ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.sent_at).toLocaleDateString("en-GB"),
              ]),
              ...(this.props.declinedContractsData ?? []).map((item) => [
                `${item?.prefix}-${item?.code}`,
                item?.data?.client?.displayName,
                item?.data?.client?.business_type,
                item?.status,
                item?.recurring_total === "0" ||
                item?.recurring_total === null ||
                item?.recurring_total === undefined ||
                !item?.recurring_total
                  ? ""
                  : Number(item?.recurring_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(
                //     item?.recurring_total
                //   )}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(
                //     item?.recurring_total
                //   )}`
                // : "",
                item?.payment_frequency,
                item?.payment_frequency === "yearly"
                  ? item?.recurring_total != null
                    ? Number(item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "halfYearly"
                  ? item?.recurring_total != null
                    ? Number(2 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "quarterly"
                  ? item?.recurring_total != null
                    ? Number(4 * item?.recurring_total)
                    : ""
                  : item?.payment_frequency === "monthly"
                  ? item?.recurring_total != null
                    ? Number(12 * item?.recurring_total)
                    : ""
                  : "",
                item?.one_off_total === "0" ||
                item?.one_off_total === null ||
                item?.one_off_total === undefined ||
                !item?.one_off_total
                  ? ""
                  : Number(item?.one_off_total),
                // : item?.data?.organisation?.currency === "GBP"
                // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "EUR"
                // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "AUD"
                // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
                // : item?.data?.organisation?.currency === "USD"
                // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
                // : "",
                new Date(item?.createdAt).toLocaleDateString("en-GB"),
                new Date(item?.updatedAt).toLocaleDateString("en-GB"),
                new Date(item?.sent_at).toLocaleDateString("en-GB"),
              ]),
            ]),
      ];

      const engLetterSignedData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
          "Sent Date",
          "Signed Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterCompletedContracts ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.sent_at).toLocaleDateString("en-GB"),
              new Date(item?.completed_at).toLocaleDateString("en-GB"),
            ])
          : (this.props.completedContractsData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.sent_at).toLocaleDateString("en-GB"),
              new Date(item?.completed_at).toLocaleDateString("en-GB"),
            ])),
      ];

      const engLetterAwaitingSignData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
          "Sent Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterPendingContracts ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.sent_at).toLocaleDateString("en-GB"),
            ])
          : (this.props.pendingContractsData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.sent_at).toLocaleDateString("en-GB"),
            ])),
      ];

      const engLetterDeclinedData = [
        [
          "Ref Id",
          "Prospect",
          "Type of Prospect",
          "Status",
          `Recurring Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Payment Frequency",
          `Recurring Fee Yearly(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          `One-Off Fee(${
            getCurrency === "GBP"
              ? "£"
              : getCurrency === "EUR"
              ? "€"
              : getCurrency === "AUD"
              ? "AU$"
              : getCurrency === "USD"
              ? "USD$"
              : getCurrency
          })`,
          "Created Date",
          "Last Updated",
          "Sent Date",
          "Declined Date",
        ],
        ...(this.state.name && this.state.name !== "All"
          ? (this.props.filterDeclinedContracts ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.sent_at).toLocaleDateString("en-GB"),
              new Date(item?.declined_at).toLocaleDateString("en-GB"),
            ])
          : (this.props.declinedContractsData ?? []).map((item) => [
              `${item?.prefix}-${item?.code}`,
              item?.data?.client?.displayName,
              item?.data?.client?.business_type,
              item?.status,
              item?.recurring_total === "0" ||
              item?.recurring_total === null ||
              item?.recurring_total === undefined ||
              !item?.recurring_total
                ? ""
                : Number(item?.recurring_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.recurring_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.recurring_total)}`
              // : "",
              item?.payment_frequency,
              item?.payment_frequency === "yearly"
                ? item?.recurring_total != null
                  ? Number(item?.recurring_total)
                  : ""
                : item?.payment_frequency === "halfYearly"
                ? item?.recurring_total != null
                  ? Number(2 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "quarterly"
                ? item?.recurring_total != null
                  ? Number(4 * item?.recurring_total)
                  : ""
                : item?.payment_frequency === "monthly"
                ? item?.recurring_total != null
                  ? Number(12 * item?.recurring_total)
                  : ""
                : "",
              item?.one_off_total === "0" ||
              item?.one_off_total === null ||
              item?.one_off_total === undefined ||
              !item?.one_off_total
                ? ""
                : Number(item?.one_off_total),
              // : item?.data?.organisation?.currency === "GBP"
              // ? `${formatCurrencyOfYourChoice("GBP")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "EUR"
              // ? `${formatCurrencyOfYourChoice("EUR")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "AUD"
              // ? `${formatCurrencyOfYourChoice("AUD")(item?.one_off_total)}`
              // : item?.data?.organisation?.currency === "USD"
              // ? `${formatCurrencyOfYourChoice("USD")(item?.one_off_total)}`
              // : "",
              new Date(item?.createdAt).toLocaleDateString("en-GB"),
              new Date(item?.updatedAt).toLocaleDateString("en-GB"),
              new Date(item?.sent_at).toLocaleDateString("en-GB"),
              new Date(item?.declined_at).toLocaleDateString("en-GB"),
            ])),
      ];

      // Create a workbook and a worksheet
      const ws = XLSX.utils.aoa_to_sheet(data);
      const proposalDraftEx = XLSX.utils.aoa_to_sheet(proposalDraftData);
      const proposalSentEx = XLSX.utils.aoa_to_sheet(proposalSentData);
      if(!this.state?.organisationData?.result?.isVisible){
        var proposalPendingEx = XLSX.utils.aoa_to_sheet(proposalPendingData);
        var proposalAcceptedEx = XLSX.utils.aoa_to_sheet(proposalAcceptedData);
        var proposalDeclinedEx = XLSX.utils.aoa_to_sheet(proposalDeclinedData);
        proposalPendingEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
        proposalAcceptedEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
        proposalDeclinedEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
      }
      if(this.state?.organisationData?.result?.isVisible){

        var engLetterDraftEx = XLSX.utils.aoa_to_sheet(engLetterDraftData);
        var engLetterSendEx = XLSX.utils.aoa_to_sheet(engLetterSendData);
        var engLetterSignedEx = XLSX.utils.aoa_to_sheet(engLetterSignedData);
        var engLetterAwaitingSignEx = XLSX.utils.aoa_to_sheet(
          engLetterAwaitingSignData
        );
        var engLetterDeclinedEx = XLSX.utils.aoa_to_sheet(
          engLetterDeclinedData
        );

        engLetterDraftEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
        engLetterSendEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
        engLetterSignedEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
        engLetterAwaitingSignEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
        engLetterDeclinedEx["!cols"] = [
          { wch: 15 }, // Width for column A
          { wch: 15 }, // Width for column B
          { wch: 20 }, // Width for column C
          { wch: 15 }, // Width for column D
          { wch: 20 }, // Width for column E
          { wch: 20 }, // Width for column F
          { wch: 25 }, // Width for column G
          { wch: 20 }, // Width for column H
          { wch: 20 }, // Width for column I
          { wch: 20 }, // Width for column J
          { wch: 20 }, // Width for column K
          { wch: 20 }, // Width for column L
          // Add more columns as needed
        ];
      }
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, "Summary");
      XLSX.utils.book_append_sheet(wb, proposalDraftEx, "Proposals Draft");
      XLSX.utils.book_append_sheet(wb, proposalSentEx, "Proposals Sent");
      if(!this.state?.organisationData?.result?.isVisible){
        XLSX.utils.book_append_sheet(wb, proposalPendingEx, "Proposals Awaiting Response");
        XLSX.utils.book_append_sheet(wb, proposalAcceptedEx, "Proposals Accepted");
        XLSX.utils.book_append_sheet(wb, proposalDeclinedEx, "Proposals Declined");
      }
      if(this.state?.organisationData?.result?.isVisible){

        XLSX.utils.book_append_sheet(wb, engLetterDraftEx, "Eng. Letters Draft");
        XLSX.utils.book_append_sheet(wb, engLetterSendEx, "Eng. Letters Sent");
        XLSX.utils.book_append_sheet(
          wb,
          engLetterSignedEx,
          "Eng. Letters Signed"
        );
        XLSX.utils.book_append_sheet(
          wb,
          engLetterAwaitingSignEx,
          "Eng. Let Awaiting Signature"
        );
        XLSX.utils.book_append_sheet(
          wb,
          engLetterDeclinedEx,
          "Eng. Letters Declined"
        );
      }
      // Set the column widths for specific sheets
      ws["!cols"] = [
        { wch: 40 }, // Width for column A
        { wch: 15 }, // Width for column B
        // Add more columns as needed
      ];
      proposalDraftEx["!cols"] = [
        { wch: 15 }, // Width for column A
        { wch: 15 }, // Width for column B
        { wch: 20 }, // Width for column C
        { wch: 15 }, // Width for column D
        { wch: 20 }, // Width for column E
        { wch: 20 }, // Width for column F
        { wch: 25 }, // Width for column G
        { wch: 20 }, // Width for column H
        { wch: 20 }, // Width for column I
        { wch: 20 }, // Width for column J
        { wch: 20 }, // Width for column K
        { wch: 20 }, // Width for column L
        // Add more columns as needed
      ];

      proposalSentEx["!cols"] = [
        { wch: 15 }, // Width for column A
        { wch: 15 }, // Width for column B
        { wch: 20 }, // Width for column C
        { wch: 15 }, // Width for column D
        { wch: 20 }, // Width for column E
        { wch: 20 }, // Width for column F
        { wch: 25 }, // Width for column G
        { wch: 20 }, // Width for column H
        { wch: 20 }, // Width for column I
        { wch: 20 }, // Width for column J
        { wch: 20 }, // Width for column K
        { wch: 20 }, // Width for column L
        // Add more columns as needed
      ];
     
      // Convert the workbook to an Excel file
      XLSX.writeFile(wb, "Your_Dashboard_data.xlsx");
    };

    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        {this.state.organisationData===null && <Loader/>}
        <div className="container-fluid">
          {/* <Row className="align-items-center">
            <Col sm={12}>
              <div className="page-title-box flex-row justify-content-between align-items-center" style={{display: 'flex'}}>
                <h4 className="font-size-18">Dashboard</h4>
                <button className="btn btn-primary" onClick={() => {
                  caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
                  window.location.reload();
                }}>
                  <Refresh />
                  <span>Refresh</span>
                </button>
              </div>
            </Col> */}

          {/* <Col sm="6">
              <div className="float-right d-none d-md-block">
                <SettingMenu />
              </div>
            </Col> */}
          {/* </Row> */}

          <div>
            <FormControl
              sx={{
                m: 2,
                width: 200,
                mt: 2,
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 0,
              }}
            >
              <div className="select-custom">
                <h4
                  style={{
                    fontSize: 14,
                  }}
                >
                  Dashboard:
                </h4>
              </div>
              <Select
                displayEmpty
                value={this.state.name}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span
                        style={{
                          fontStyle: "normal",
                          color: "gray",
                          fontSize: "14px",
                        }}
                      >
                        SELECT FILTER
                      </span>
                    );
                  }
                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  height: 48,
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                {/* <MenuItem value="ALL">
                  <em>ALL</em>
                </MenuItem> */}

                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, this.state.name)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>

              {/* <div style={{ marginLeft: 1150 }} >
            <button className="btn btn-primary" onClick={() => {
            caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
            window.location.reload();
          }}>
            <Refresh />
            <span>Refresh</span>
          </button>
            </div> */}
            <div
                style={{
                  display: "inline-block",
                  marginLeft: 10,
                  cursor: "pointer",
                }}
              >
                <span
                  // className="btn btn-primary"
                  onClick={() => {
                    handleExport();
                  }}
                >
                  {/* <span>Export</span> */}
                  <img
                    src={excel}
                    alt="Excel Icon"
                    style={{ width: "30px", height: "30px" }}
                    title="Export in Excel"
                  />
                </span>
              </div>
            </FormControl>
          </div>

          <Row>
            <Col xl={9}>
              <Row>
                <Col xl={4} md={6}>
                  {this.props.role == "superadmin" && (
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon2} alt="" />
                          </div>
                          <h5 className="font-size-15 mt-0 text-white-24">
                            Proposals - Draft
                          </h5>
                          
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? this.props.filterDraftQuotes?.length
                              : this.props.draftQuotes}
                            {/* {this.props.draftQuotes}{" "} */}
                            {/*<i className="mdi mdi-arrow-down text-danger ml-2"></i>*/}
                          </h4>
                          {/*<div className="mini-stat-label bg-danger">
                          <p className="mb-0">- 00%</p>
                        </div>*/}
                        </div>
                        {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                      </CardBody>
                    </Card>
                  )}
                  {this.props.role !== "superadmin" && (
                    <Link
                      to={
                        `quotes/?status=draft&filter=${modifiedNames}`
                        // this.props?.organisation.trading_business_name ===
                        // "Super Admin"
                        //   ? "#"
                        //   : "quotes/?status=draft"
                      }
                    >
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h5 className="font-size-15 mt-0 text-white-24">
                              Proposals - Draft
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? this.props.filterDraftQuotes?.length
                                : this.props.draftQuotes}
                              {/* {this.props.draftQuotes}{" "} */}
                              {/*<i className="mdi mdi-arrow-down text-danger ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-danger">
                          <p className="mb-0">- 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                </Col>
                <Col xl={4} md={6}>
                  {this.props.role == "superadmin" && (
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon1} alt="" />
                          </div>
                          <h5 className="font-size-15 mt-0 text-white-24">
                            Proposals - Sent
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? (parseInt(
                                this.props.filterSendQuotes?.length
                              ) || 0) +
                              (parseInt(
                                this.props.filterAcceptedQuotes?.length
                              ) || 0) +
                              (parseInt(
                                this.props.filterDeclinedQuotes?.length
                              ) || 0)
                            : (parseInt(this.props.acceptedQuotes) || 0) +
                              (parseInt(this.props.pendingQuotes) || 0) +
                              (parseInt(this.props.declinedQuotes) || 0) +
                              (parseInt(this.props.sentQuotes) || 0)
                              }
                            {/* {this.props.sentQuotes}{" "} */}
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {this.props.role !== "superadmin" && (
                    <Link to={`quotes/?status=sent&status=accepted&status=declined&filter=${modifiedNames}`}>
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon1} alt="" />
                            </div>
                            <h5 className="font-size-15 mt-0 text-white-24">
                              Proposals - Sent
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                // ? this.props.filterSentQuotes?.length
                                // : this.props.sentQuotes}
                                ? (parseInt(
                                  this.props.filterSentQuotes?.length
                                ) || 0) +
                                (parseInt(
                                  this.props.filterAcceptedQuotes?.length
                                ) || 0) +
                                (parseInt(
                                  this.props.filterDeclinedQuotes?.length
                                ) || 0)
                              : (parseInt(this.props.acceptedQuotes) || 0) +
                                (parseInt(this.props.pendingQuotes) || 0) +
                                (parseInt(this.props.declinedQuotes) || 0) +
                                (parseInt(this.props.sentQuotes) || 0)
                                }
                              {/* {this.props.sentQuotes}{" "} */}
                              {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                </Col>


                  {
                  // this.props.role == "superadmin" && (
                  //   <Col xl={4} md={6}>
                  //   <Card className="mini-stat bg-primary text-white">
                  //     <CardBody
                  //       className="dash-card"
                  //       style={{ cursor: "default" }}
                  //     >
                  //       <div className="mb-4">
                  //         <div className="float-left mini-stat-img mr-3">
                  //           <img src={servicesIcon1} alt="" />
                  //         </div>
                  //         <h5 className="font-size-15 mt-0 text-white-24">
                  //           Proposals - Awaiting Response
                  //         </h5>
                  //         <h4 className="font-weight-medium font-size-24">
                  //           {this.state.name && this.state.name != "All"
                  //             ? this.props.filterSentQuotes?.length
                  //             : this.props.sentQuotes}
                  //           {/* {this.props.sentQuotes}{" "} */}
                  //         </h4>
                  //       </div>
                  //     </CardBody>
                  //   </Card>
                  //   </Col>
                  // )
                  }
                  {
                  // this.props.role !== "superadmin" && !this.state?.organisationData?.result?.isVisible && (
                  //   <Col xl={4} md={6}>
                  //   <Link to={`quotes/?status=sent&filter=${modifiedNames}`}>
                  //     <Card className="mini-stat bg-primary text-white">
                  //       <CardBody className="dash-card">
                  //         <div className="mb-4">
                  //           <div className="float-left mini-stat-img mr-3">
                  //             <img src={servicesIcon1} alt="" />
                  //           </div>
                  //           <h5 className="font-size-15 mt-0 text-white-24">
                  //             Proposals - Awaiting Response
                  //           </h5>
                  //           <h4 className="font-weight-medium font-size-24">
                  //             {this.state.name && this.state.name != "All"
                  //               ? this.props.filterSentQuotes?.length
                  //               : this.props.sentQuotes}
                  //             {/* {this.props.sentQuotes}{" "} */}
                  //             {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                  //           </h4>
                  //           {/*<div className="mini-stat-label bg-success">
                  //         <p className="mb-0">+ 00%</p>
                  //       </div>*/}
                  //         </div>
                  //         {/*<div className="pt-2">
                  //       <div className="float-right">
                  //         <Link to="#" className="text-white-50">
                  //           <i className="mdi mdi-arrow-right h5"></i>
                  //         </Link>
                  //       </div>
                  //       <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  //     </div>*/}
                  //       </CardBody>
                  //     </Card>
                  //   </Link>
                  // </Col>
                  // )
                  }
                  
                  {this.props.role == "superadmin" && (
                    <Col xl={4} md={6}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon1} alt="" />
                          </div>
                          <h5 className="font-size-15 mt-0 text-white-24">
                            Proposals - Accepted
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? this.props.filterAcceptedQuotes?.length
                              : this.props.acceptedQuotes}
                            {/* {this.props.sentQuotes}{" "} */}
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    </Col>
                  )}
                  {this.props.role !== "superadmin" && !this.state?.organisationData?.result?.isVisible && (
                  <Col xl={4} md={6}>
                    <Link to={`quotes/?status=accepted&filter=${modifiedNames}`}>
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon1} alt="" />
                            </div>
                            <h5 className="font-size-15 mt-0 text-white-24">
                              Proposals - Accepted
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? this.props.filterAcceptedQuotes?.length
                                : this.props.acceptedQuotes}
                              {/* {this.props.sentQuotes}{" "} */}
                              {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  )}
                  {this.props.role == "superadmin" && (
                    <Col xl={4} md={6}>
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon1} alt="" />
                          </div>
                          <h5 className="font-size-15 mt-0 text-white-24">
                            Proposals - Declined
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? this.props.filterDeclinedQuotes?.length
                              : this.props.declinedQuotes}
                            {/* {this.props.sentQuotes}{" "} */}
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                    </Col>
                  )}
                  {this.props.role !== "superadmin" && !this.state?.organisationData?.result?.isVisible && (
                    <Col xl={4} md={6}>
                    <Link to={`quotes/?status=declined&filter=${modifiedNames}`}>
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon1} alt="" />
                            </div>
                            <h5 className="font-size-15 mt-0 text-white-24">
                              Proposals - Declined
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? this.props.filterDeclinedQuotes?.length
                                : this.props.declinedQuotes}
                              {/* {this.props.sentQuotes}{" "} */}
                              {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                </Col>
                  )}



                <Col xl={4} md={6}>
                  {this.props.role == "superadmin" && (
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon4} alt="" />
                          </div>
                          <h5 className="font-size-15  mt-0 text-white-24">
                            Engagement Letters <br />- Draft
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? this.props.filterDraftContracts?.length
                              : this.props.draftContracts}
                            {/* {this.props.draftContracts}{" "} */}
                            {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                          </h4>
                          {/*<div className="mini-stat-label bg-warning">
                     <p className="mb-0">+ 00%</p>
                   </div>*/}
                        </div>
                        {/*<div className="pt-2">
                   <div className="float-right">
                     <Link to="#" className="text-white-50">
                       <i className="mdi mdi-arrow-right h5"></i>
                     </Link>
                   </div>
                   <p className="text-white-50 mb-0 mt-1">Since last month</p>
                 </div>*/}
                      </CardBody>
                    </Card>
                  )}
                  {this.props.role !== "superadmin" && this.state?.organisationData?.result?.isVisible && (
                    <Link
                      to={
                        `contracts/?status=draft&filter=${modifiedNames}`
                        // this.props?.organisation.trading_business_name ===
                        // "Super Admin"
                        //   ? "#"
                        //   : "contracts/?status=draft"
                      }
                    >
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon4} alt="" />
                            </div>
                            <h5 className="font-size-15  mt-0 text-white-24">
                              Engagement Letters <br />- Draft
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? this.props.filterDraftContracts?.length
                                : this.props.draftContracts}
                              {/* {this.props.draftContracts}{" "} */}
                              {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-warning">
                          <p className="mb-0">+ 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                </Col>
                <Col xl={4} md={6}>
                  {this.props.role == "superadmin" && (
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon1} alt="" />
                          </div>
                          <h5 className="font-size-15  mt-0 text-white-24">
                            Engagement Letters <br />- Sent
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? (parseInt(
                                  this.props.filterPendingContracts?.length
                                ) || 0) +
                                (parseInt(
                                  this.props.filterCompletedContracts?.length
                                ) || 0) +
                                (parseInt(
                                  this.props.filterDeclinedContracts?.length
                                ) || 0)
                              : (parseInt(this.props.completedContracts) || 0) +
                                (parseInt(this.props.pendingContracts) || 0) +
                                (parseInt(this.props.declinedContracts) || 0)}
                            {/* {this.props.completedContracts + */}
                            {/* // this.props.pendingContracts + this.props.declinedContracts}{" "} */}
                            {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                          </h4>
                          {/*<div className="mini-stat-label bg-success">
                    <p className="mb-0">+ 00%</p>
                  </div>*/}
                        </div>
                        {/*<div className="pt-2">
                  <div className="float-right">
                    <Link to="#" className="text-white-50">
                      <i className="mdi mdi-arrow-right h5"></i>
                    </Link>
                  </div>
                  <p className="text-white-50 mb-0 mt-1">Since last month</p>
                </div>*/}
                      </CardBody>
                    </Card>
                  )}
                  {this.props.role !== "superadmin" && this.state?.organisationData?.result?.isVisible && (
                    <Link
                      to={
                        `contracts/?status=completed&status=pending&status=declined&filter=${modifiedNames}`
                        // this.props?.organisation.trading_business_name ===
                        // "Super Admin"
                        //   ? "#"
                        //   : "contracts"
                      }
                    >
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon1} alt="" />
                            </div>
                            <h5 className="font-size-15  mt-0 text-white-24">
                              Engagement Letters <br />- Sent
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? (parseInt(
                                    this.props.filterPendingContracts?.length
                                  ) || 0) +
                                  (parseInt(
                                    this.props.filterCompletedContracts?.length
                                  ) || 0) +
                                  (parseInt(
                                    this.props.filterDeclinedContracts?.length
                                  ) || 0)
                                : (parseInt(this.props.completedContracts) ||
                                    0) +
                                  (parseInt(this.props.pendingContracts) || 0) +
                                  (parseInt(this.props.declinedContracts) || 0)}
                              {/* {this.props.completedContracts + */}
                              {/* this.props.pendingContracts + this.props.declinedContracts}{" "} */}
                              {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                </Col>
                <Col xl={4} md={6}>
                  {this.props.role == "superadmin" && (
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon2} alt="" />
                          </div>
                          <h5 className="font-size-15  mt-0 text-white-24">
                            Engagement Letters <br />- Signed
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? this.props.filterCompletedContracts?.length
                              : this.props.completedContracts}
                            {/* {this.props.completedContracts}{" "} */}
                            {/*<i className="mdi mdi-arrow-down text-danger ml-2"></i>*/}
                          </h4>
                          {/*<div className="mini-stat-label bg-danger">
                    <p className="mb-0">- 00%</p>
                  </div>*/}
                        </div>
                        {/*<div className="pt-2">
                  <div className="float-right">
                    <Link to="#" className="text-white-50">
                      <i className="mdi mdi-arrow-right h5"></i>
                    </Link>
                  </div>

                  <p className="text-white-50 mb-0 mt-1">Since last month</p>
                </div>*/}
                      </CardBody>
                    </Card>
                  )}
                  {this.props.role !== "superadmin" && this.state?.organisationData?.result?.isVisible && (
                    <Link
                      to={
                        `contracts/?status=completed&filter=${modifiedNames}`
                        // this.props?.organisation.trading_business_name ===
                        // "Super Admin"
                        //   ? "#"
                        //   : "contracts/?status=completed"
                      }
                    >
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h5 className="font-size-15  mt-0 text-white-24">
                              Engagement Letters <br />- Signed
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? this.props.filterCompletedContracts?.length
                                : this.props.completedContracts}
                              {/* {this.props.completedContracts}{" "} */}
                              {/*<i className="mdi mdi-arrow-down text-danger ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-danger">
                          <p className="mb-0">- 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                </Col>
                <Col xl={4} md={6}>
                  {this.props.role == "superadmin" && (
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon3} alt="" />
                          </div>
                          <h5 className="font-size-15  mt-0 text-white-24">
                            Engagement Letters Awaiting Signature
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? this.props.filterPendingContracts?.length
                              : this.props.pendingContracts}
                            {/* {this.props.pendingContracts}{" "} */}
                            {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                          </h4>
                          {/*<div className="mini-stat-label bg-info">
                    <p className="mb-0"> 00%</p>
                  </div>*/}
                        </div>
                        {/*<div className="pt-2">
                  <div className="float-right">
                    <Link to="#" className="text-white-50">
                      <i className="mdi mdi-arrow-right h5"></i>
                    </Link>
                  </div>

                  <p className="text-white-50 mb-0 mt-1">Since last month</p>
                </div>*/}
                      </CardBody>
                    </Card>
                  )}
                  {this.props.role !== "superadmin" && this.state?.organisationData?.result?.isVisible && (
                    <Link
                      to={
                        `contracts/?status=pending&filter=${modifiedNames}`
                        // this.props?.organisation.trading_business_name ===
                        // "Super Admin"
                        //   ? "#"
                        //   : "contracts/?status=pending"
                      }
                    >
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon3} alt="" />
                            </div>
                            <h5 className="font-size-15  mt-0 text-white-24">
                              Engagement Letters Awaiting Signature
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? this.props.filterPendingContracts?.length
                                : this.props.pendingContracts}
                              {/* {this.props.pendingContracts}{" "} */}
                              {/*<i className="mdi mdi-arrow-up text-success ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-info">
                          <p className="mb-0"> 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                </Col>
                <Col xl={4} md={6}>
                  {this.props.role == "superadmin" && (
                    <Card className="mini-stat bg-primary text-white">
                      <CardBody
                        className="dash-card"
                        style={{ cursor: "default" }}
                      >
                        <div className="mb-4">
                          <div className="float-left mini-stat-img mr-3">
                            <img src={servicesIcon2} alt="" />
                          </div>
                          <h5 className="font-size-15  mt-0 text-white-24">
                            Engagement Letters <br />- Declined
                          </h5>
                          <h4 className="font-weight-medium font-size-24">
                            {this.state.name && this.state.name != "All"
                              ? this.props.filterDeclinedContracts?.length
                              : this.props.declinedContracts}
                            {/* {this.props.declinedContracts}{" "} */}
                            {/*<i className="mdi mdi-arrow-down text-danger ml-2"></i>*/}
                          </h4>
                          {/*<div className="mini-stat-label bg-danger">
                    <p className="mb-0">- 00%</p>
                  </div>*/}
                        </div>
                        {/*<div className="pt-2">
                  <div className="float-right">
                    <Link to="#" className="text-white-50">
                      <i className="mdi mdi-arrow-right h5"></i>
                    </Link>
                  </div>

                  <p className="text-white-50 mb-0 mt-1">Since last month</p>
                </div>*/}
                      </CardBody>
                    </Card>
                  )}
                  {this.props.role !== "superadmin" && this.state?.organisationData?.result?.isVisible && (
                    <Link
                      to={
                        `contracts/?status=declined&filter=${modifiedNames}`
                        // this.props?.organisation.trading_business_name ===
                        // "Super Admin"
                        //   ? "#"
                        //   : "contracts/?status=completed"
                      }
                    >
                      <Card className="mini-stat bg-primary text-white">
                        <CardBody className="dash-card">
                          <div className="mb-4">
                            <div className="float-left mini-stat-img mr-3">
                              <img src={servicesIcon2} alt="" />
                            </div>
                            <h5 className="font-size-15  mt-0 text-white-24">
                              Engagement Letters <br />- Declined
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                              {this.state.name && this.state.name != "All"
                                ? this.props.filterDeclinedContracts?.length
                                : this.props.declinedContracts}
                              {/* {this.props.declinedContracts}{" "} */}
                              {/*<i className="mdi mdi-arrow-down text-danger ml-2"></i>*/}
                            </h4>
                            {/*<div className="mini-stat-label bg-danger">
                          <p className="mb-0">- 00%</p>
                        </div>*/}
                          </div>
                          {/*<div className="pt-2">
                        <div className="float-right">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">Since last month</p>
                      </div>*/}
                        </CardBody>
                      </Card>
                    </Link>
                  )}
                </Col>
              </Row>
            </Col>
            {this.props.role !== "superadmin" && (
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Activity</h4>
                    {/* {Role !== 'superadmin' && */}

                    <ActivityLogsTable auditTrails={this.props.auditTrails} />

                    {this.props.auditTrails.length > 0 ? (
                      <div className="text-center">
                        <Link to="/activity-logs" className="btn btn-primary">
                          View All
                        </Link>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Dashboard,
    auditTrails: state.AuditTrail.auditTrails,
    organisation: state.Organisation?.currentOrganisation,
    getCurrency: state.Organisation?.currentOrganisation?.currency,
  };
};

export default withRouter(
  connect(mapStateToProps, { getDashboard, getFilteredDashboard })(Dashboard)
);
