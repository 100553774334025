import React from 'react';
import { withRouter } from "react-router-dom";
import { getFirebaseBackend } from "../helpers/authUtils";

let userRole;

function renderTermsAndConditionTemplates(item, index) {
	return (
		<tr key={index}>
			<td>{item.name.substring(0,70)}</td>
			{userRole==="superadmin" && <td>{item.organisation_business_type}</td>}
			{/*<td>{item.description}</td>*/} 
			<td className="serivce-actions text-right">
		      <button onClick={() => this.history.push(`/terms-and-conditions/edit/${item._id}`)} data-id={item._id} data-index={index} data-toggle="tooltip" title="Edit Terms & Conditions" className="btn btn-sm btn-dark"><i className="ion ion-md-create" /></button>
		      <button onClick={() => this.delete(true, item._id, "tncTemplate")} data-toggle="tooltip" title="Delete Terms & Conditions" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>
		    </td>
		</tr>
	);
}

const TermsAndConditionListTable = (props) => {
	userRole = getFirebaseBackend().getUserRole();
	return (
		props.termsAndConditionTemplates.length>0 ? (<div className="table-rep-plugin">
			<div className="table-responsive mb-0" data-pattern="priority-columns">
			  <table className="table table-striped">
			  	<thead>
			  		<tr>
			  			<th>Name</th>
			  			{userRole==="superadmin" && <th>Business Type</th>}
			  		{/* <th>Description</th> */} 
			  			<th></th>
			  		</tr>
			  	</thead>
			    <tbody>
			    	{props.termsAndConditionTemplates.map(renderTermsAndConditionTemplates.bind(props))}
			    </tbody>
			  </table>
			</div>
		</div>) : (<div className="text-center">No Templates Found!</div>)
	);
}

export default withRouter(TermsAndConditionListTable);
