import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Switch from "react-switch";
import DatePicker from "react-date-picker";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/Loader";
import InputPhone from "../../components/InputPhone";
import OrganisationAddress from "../../components/OrganisationAddress";
import OrganisationPartner from "../../components/OrganisationPartner";
import OrganisationOfficer from "../../components/OrganisationOfficer";
import AutoCompleteInput from "../../components/AutoCompleteInput";
import ImageUploadModal from "../../components/Settings/ImageUploadModal";
import {
  onInputChange,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  getMyOrganisation,
  updateMyOrganisation,
  setShowImageUploadModal,
  setShowUpdateOrganisationSuccessModal,
  uploadLogo,
  uploadSignature,
  resetOrganisationForm,
  companySearch,
  selectCompanySearchItem,
  addSignatory,
  deleteSignatory,
  setSignatories,
} from "../../store/organisation/actions";
import {
  businessTypes,
  vatValues,
  incorporatedInCountryOptions,
  templateTypes,
} from "../../config";
import {
  loadScript,
  utcDateToCurrentDate,
  formatAddress,
  formatDate,
  getValidator,
  getPhoneObject,
} from "../../Utils";
import ContractSignatory from "../../components/ContractSignatory";
import {
  onInputChange as onContractInputChange,
  getTncTemplates,
} from "../../store/contract/actions";
import { getTemplates } from "../../store/template/actions";
import { getAvailablePaymentMethods } from "../../store/common/actions";
import { getClients } from "../../store/client/actions";
import MyEditor from "../../components/MyEditor";
import axios from 'axios';

class UpdateOrganisationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationData: null,
      error: null,
      oldCompanyNumber: null,
      newCompany: null,
      signatoriesCalled: false,
      templateOptions: {
        individual: [],
        soleTrader: [],
        llp: [],
        partnership: [],
        ltd: []
      },
      templateUpdatedOnce: false
    };
    this.validator = getValidator();
    this.updateOrganisation = this.updateOrganisation.bind(this);
    this.validatePartners = this.validatePartners.bind(this);
    this.validateOfficers = this.validateOfficers.bind(this);
    this.renderTradingDetails = this.renderTradingDetails.bind(this);
    this.renderESignnature = this.renderESignnature.bind(this);
    this.getUploadImageFunction = this.getUploadImageFunction.bind(this);
    this.getSignatoriesData = this.getSignatoriesData.bind(this);
  }
  componentDidMount() {
    this.props.resetOrganisationForm();
    this.props.getMyOrganisation();
    this.props.getAvailablePaymentMethods();
    this.props.getClients();
    this.props.getTemplates({ type: "contract" });

    const token = JSON.parse(localStorage.getItem('authUser'));
  
    if (token && token.stsTokenManager && token.stsTokenManager.accessToken) {
      axios.get('https://teststaging.outbooks.com/api/organisation/me', {
        headers: {
          'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
        }
      })
      .then(response => {
        this.setState({ organisationData: response.data });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
    } else {
      this.setState({ error: 'Token not found or invalid.' });
    }
  }
  componentDidUpdate() {
    if (!this.state.signatoriesCalled && this.props.clients.length > 0) {
      // console.log("spec_check_signatories", this.props.clients);
      this.setState({ signatoriesCalled: true });
      this.props.getTncTemplates();
    }
    if (this.props.companyNumber && !this.state.oldCompanyNumber) {
      this.setState({
        oldCompanyNumber: this.props.companyNumber,
      });
    }
    if (this.props.Template?.templates?.length > 0 && !this.state.templateUpdatedOnce) {
      const tempTemplates = {
        individual: [],
        soleTrader: [],
        llp: [],
        partnership: [],
        ltd: []
      };
      this.props.Template.templates.map((x) => {
        if (x.type == 'contract') {
          tempTemplates[x.client_business_type].push({
            value: x._id,
            label: x.name
          })
        }
      });
      if (tempTemplates.length > 0) {
        this.props.onInputChange(
          "individual_template_id",
          this.state.templateOptions['individual'].length > 0 ? this.state.templateOptions['individual'][0] : ''
        );
        this.props.onInputChange(
          "soleTrader_template_id",
          this.state.templateOptions['soleTrader'].length > 0 ? this.state.templateOptions['soleTrader'][0] : ''
        );
        this.props.onInputChange(
          "ltd_template_id",
          this.state.templateOptions['ltd'].length > 0 ? this.state.templateOptions['ltd'][0] : ''
        );
        this.props.onInputChange(
          "llp_template_id",
          this.state.templateOptions['llp'].length > 0 ? this.state.templateOptions['llp'][0] : ''
        );
        this.props.onInputChange(
          "partnership_template_id",
          this.state.templateOptions['partnership'].length > 0 ? this.state.templateOptions['partnership'][0] : ''
        );
      }
      this.setState({
        templateUpdatedOnce: true,
        templateOptions: tempTemplates,
      });
    }
  }
  componentWillUnmount() {
    this.props.resetOrganisationForm();
  }
  validatePartners() {
    let valid = true;
    for (let code of this.props.partnerCodes) {
      if (
        !(
          this.validator.fields[`partner${code}FirstName`] &&
          this.validator.fields[`partner${code}LastName`] &&
          this.validator.fields[`partner${code}Email`] &&
          this.validator.fields[`partner${code}Phone`] &&
          this.validator.fields[`partner${code}Address`]
        )
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  validateOfficers() {
    let valid = true;
    for (let code of this.props.officerCodes) {
      if (
        !(
          this.validator.fields[`officer${code}FirstName`] &&
          this.validator.fields[`officer${code}LastName`] &&
          this.validator.fields[`officer${code}Email`] &&
          this.validator.fields[`officer${code}Phone`] &&
          this.validator.fields[`officer${code}Address`] &&
          this.validator.fields[`officer${code}Role`] &&
          this.validator.fields[`officer${code}AppointedOn`] &&
          this.validator.fields["Website"]
        )
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  getSignatoriesData() {
    let signatoriesData = [];
    for (let signatory of this.props.signatories) {
      signatoriesData.push({
        firstName: this.props[`signatory${signatory}FirstName`],
        lastName: this.props[`signatory${signatory}LastName`],
        email: this.props[`signatory${signatory}Email`],
        signaturePosition: this.props[`signatory${signatory}SignaturePosition`],
      });
    }
    return signatoriesData;
  }
  updateOrganisation() {
    if (
      this.validator.fields["Trading Name"] &&
      this.validator.fields["Trading Start Date"] &&
      this.validator.fields["Trading Address"] &&
      this.validator.fields["Email"] &&
      this.validator.fields["phone"] &&
      this.validator.fields["vat Registered"] &&
      (this.props.businessType === "soleTrader"
        ? this.validator.fields["First Name"] &&
        this.validator.fields["Last Name"] &&
        this.validator.fields["Phone"] &&
        this.validator.fields["soleTraderPhone"] &&
        this.validator.fields["Email"] &&
        this.validator.fields["Residential Address"]
        : true) &&
      (this.props.businessType === "partnership"
        ? this.validatePartners() && this.validator.fields["partnersArray"]
        : true) &&
      (["ltd", "llp"].includes(this.props.businessType)
        ? this.validateOfficers() && this.validator.fields["officersArray"]
        : true)
    ) {
      const data = {
        // businessType: this.props.businessType,
        tradingDetails: {
          name: this.props.tradingName,
          startDate: utcDateToCurrentDate(this.props.tradingStartDate),
          address: {
            premises: this.props.tradingPremises,
            addressLine1: this.props.tradingAddressLine1,
            addressLine2: this.props.tradingAddressLine2,
            locality: this.props.tradingLocality,
            region: this.props.tradingRegion,
            country: this.props.tradingCountry,
            postcode: this.props.tradingPostcode,
          },
        },
        logoUrl: this.props.logoUrl,
        isVisible:this.props.isVisible,
        signatureUrl: this.props.signatureUrl,
        signatoryName: this.props.signatoryName,
        brandColor: this.props.brandColor,
        website: this.props.website,
        phone: getPhoneObject(this.props.dialCode, this.props.phone),
        // phone: (this.props.phone && this.props.phone!=="") ? ({code: this.props.dialCode, number: this.props.phone}) : null,
        email: this.props.email,
        vatRegistered: this.props.vatRegistered,
        vatNumber: this.props.vatNumber,
        businessTagline: this.props.businessTagline,
        affiliatedAccountingBodyName: this.props.affiliatedAccountingBodyName,
        websiteOfAffiliatedAccountingBody:
          this.props.websiteOfAffiliatedAccountingBody,
        contract_email_method: this.props.contract_email_method,
        signatories: this.getSignatoriesData(),
        payment_method: this.props.payment_method,
        tncTemplateOrg: this.props.tncTemplateOrg,
        individual_template_id: this.props.individual_template_id,
        soleTrader_template_id: this.props.soleTrader_template_id,
        partnership_template_id: this.props.partnership_template_id,
        llp_template_id: this.props.llp_template_id,
        ltd_template_id: this.props.ltd_template_id,
      };
      switch (this.props.businessType) {
        case "soleTrader":
          if (
            this.validator.fields["Website"] &&
            (this.validator.fields["vat Registered"] ||
              this.props.organisation.vatRegistered === true ||
              !this.props.organisation.vatRegistered)
          ) {
            let soleTrader = {
              firstName: this.props.soleTraderFirstName,
              lastName: this.props.soleTraderLastName,
              phone: getPhoneObject(
                this.props.soleTraderDialCode,
                this.props.soleTraderPhone
              ),
              // phone:this.props.soleTraderPhone,
              // phone: {code: this.props.soleTraderDialCode, number: this.props.soleTraderPhone},
              email: this.props.soleTraderEmail,
              residentialAddress: {
                premises: this.props.soleTraderPremises,
                addressLine1: this.props.soleTraderAddressLine1,
                addressLine2: this.props.soleTraderAddressLine2,
                locality: this.props.soleTraderLocality,
                region: this.props.soleTraderRegion,
                country: this.props.soleTraderCountry,
                postcode: this.props.soleTraderPostcode,
              },
            };
            data.soleTrader = soleTrader;
          }
          break;
        case "partnership":
          if (
            this.validator.fields["Website"] &&
            (this.validator.fields["vat Registered"] ||
              this.props.organisation.vatRegistered === true ||
              !this.props.organisation.vatRegistered)
          ) {
            data.partners = [];
            this.props.partnerCodes.forEach((partnerCode) => {
              let partner = {
                id: partnerCode,
                isAuthorisedSignatory:
                  this.props[`partner${partnerCode}IsAuthorisedSignatory`] ||
                  false,
                isNew: this.props[`partner${partnerCode}IsNew`],
                firstName: this.props[`partner${partnerCode}FirstName`],
                lastName: this.props[`partner${partnerCode}LastName`],
                phone: getPhoneObject(
                  this.props[`partner${partnerCode}DialCode`],
                  this.props[`partner${partnerCode}Phone`]
                ),
                // phone: {code: this.props[`partner${partnerCode}DialCode`], number: this.props[`partner${partnerCode}Phone`]},
                email: this.props[`partner${partnerCode}Email`],
                residentialAddress: {
                  premises: this.props[`partner${partnerCode}Premises`],
                  addressLine1: this.props[`partner${partnerCode}AddressLine1`],
                  addressLine2: this.props[`partner${partnerCode}AddressLine2`],
                  locality: this.props[`partner${partnerCode}Locality`],
                  region: this.props[`partner${partnerCode}Region`],
                  country: this.props[`partner${partnerCode}Country`],
                  postcode: this.props[`partner${partnerCode}Postcode`],
                },
              };
              data.partners.push(partner);
            });
          }
          break;
        case "llp":
        case "ltd":
          if (
            this.validator.fields["Website"] &&
            (this.validator.fields["vat Registered"] ||
              this.props.organisation.vatRegistered === true ||
              !this.props.organisation.vatRegistered)
          ) {
            data.officers = [];
            this.props.officerCodes.forEach((officerCode) => {
              let officer = {
                id: officerCode,
                isAuthorisedSignatory:
                  this.props[`officer${officerCode}IsAuthorisedSignatory`] ||
                  false,
                isNew:
                  this.state.oldCompanyNumber === this.props.companyNumber
                    ? this.props[`officer${officerCode}IsNew`]
                    : true, // this.props[`officer${officerCode}IsNew`],            // handle this field incase of update company
                firstName: this.props[`officer${officerCode}FirstName`],
                lastName: this.props[`officer${officerCode}LastName`],
                role: this.props[`officer${officerCode}Role`],
                appointedOn: this.props[`officer${officerCode}AppointedOn`],
                nationality: this.props[`officer${officerCode}Nationality`],
                countryOfResidence:
                  this.props[`officer${officerCode}CountryOfResidence`],
                phone: getPhoneObject(
                  this.props[`officer${officerCode}DialCode`],
                  this.props[`officer${officerCode}Phone`]
                ),
                // phone: {code: this.props[`officer${officerCode}DialCode`], number: this.props[`officer${officerCode}Phone`]},
                email: this.props[`officer${officerCode}Email`],
                correspondenceAddress: {
                  premises: this.props[`officer${officerCode}Premises`],
                  addressLine1: this.props[`officer${officerCode}AddressLine1`],
                  addressLine2: this.props[`officer${officerCode}AddressLine2`],
                  locality: this.props[`officer${officerCode}Locality`],
                  region: this.props[`officer${officerCode}Region`],
                  country: this.props[`officer${officerCode}Country`],
                  postcode: this.props[`officer${officerCode}Postcode`],
                },
              };
              data.officers.push(officer);
            });
            data.company = {
              name: this.props.companyName,
              number: this.props.companyNumber,
              incorporatedIn: this.props.companyIncorporatedIn,
            };
          } else {
            this.validator.showMessages();
            this.forceUpdate();
            break;
          }
          break;
        default:
          break;
      }
      data["newCompany"] =
        this.state.oldCompanyNumber === this.props.companyNumber ? false : true;
      // console.log("update data -- ", data)
      this.props.updateMyOrganisation(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  getUploadImageFunction() {
    switch (this.props.imageUploadModalType) {
      case "Logo":
        return this.props.uploadLogo;
      case "Signature":
        return this.props.uploadSignature;
      default:
        return null;
    }
  }
  renderPartners() {
    let jsx = [];
    let index = 0;
    for (let partnerCode of this.props.partnerCodes) {
      jsx.push(
        <OrganisationPartner
          key={partnerCode}
          index={index++}
          onInputChange={this.props.onInputChange}
          partnerCode={partnerCode}
          isAuthorisedSignatory={
            this.props[`partner${partnerCode}IsAuthorisedSignatory`]
          }
          firstName={this.props[`partner${partnerCode}FirstName`]}
          lastName={this.props[`partner${partnerCode}LastName`]}
          phone={this.props[`partner${partnerCode}Phone`]}
          dialCode={this.props[`partner${partnerCode}DialCode`]}
          email={this.props[`partner${partnerCode}Email`]}
          addressSearch={this.props[`partner${partnerCode}AddressSearch`]}
          premises={this.props[`partner${partnerCode}Premises`]}
          addressLine1={this.props[`partner${partnerCode}AddressLine1`]}
          addressLine2={this.props[`partner${partnerCode}AddressLine2`]}
          locality={this.props[`partner${partnerCode}Locality`]}
          region={this.props[`partner${partnerCode}Region`]}
          postcode={this.props[`partner${partnerCode}Postcode`]}
          country={this.props[`partner${partnerCode}Country`]}
          deletePartner={this.props.deletePartner}
          validator={this.validator}
        />
      );
    }
    return jsx;
  }
  renderOfficers() {
    let jsx = [];
    let index = 0;
    for (let officerCode of this.props.officerCodes) {
      jsx.push(
        <OrganisationOfficer
          key={officerCode}
          index={index++}
          onInputChange={this.props.onInputChange}
          officerCode={officerCode}
          isAuthorisedSignatory={
            this.props[`officer${officerCode}IsAuthorisedSignatory`]
          }
          firstName={this.props[`officer${officerCode}FirstName`]}
          lastName={this.props[`officer${officerCode}LastName`]}
          role={this.props[`officer${officerCode}Role`]}
          appointedOn={this.props[`officer${officerCode}AppointedOn`]}
          nationality={this.props[`officer${officerCode}Nationality`]}
          countryOfResidence={
            this.props[`officer${officerCode}CountryOfResidence`]
          }
          phone={this.props[`officer${officerCode}Phone`]}
          dialCode={this.props[`officer${officerCode}DialCode`]}
          email={this.props[`officer${officerCode}Email`]}
          addressSearch={this.props[`officer${officerCode}AddressSearch`]}
          premises={this.props[`officer${officerCode}Premises`]}
          addressLine1={this.props[`officer${officerCode}AddressLine1`]}
          addressLine2={this.props[`officer${officerCode}AddressLine2`]}
          locality={this.props[`officer${officerCode}Locality`]}
          region={this.props[`officer${officerCode}Region`]}
          postcode={this.props[`officer${officerCode}Postcode`]}
          country={this.props[`officer${officerCode}Country`]}
          deleteOfficer={this.props.deleteOfficer}
          validator={this.validator}
        />
      );
    }
    return jsx;
  }
  generateNewPartnerCode() {
    return new Date().getTime();
  }
  generateNewOfficerCode() {
    return new Date().getTime();
  }
  handleToggleEngagementLetter = () => {
    // Assuming that `isVisible` is a boolean property in your component's state
    this.props.onInputChange("isVisible", !this.props.isVisible);
}
  renderTradingDetails() {
    let maxTradingDate = new Date();
    maxTradingDate.setMonth(maxTradingDate.getMonth() + 3);
    return (
      <React.Fragment>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label required">Trading Name</label>
          </div>
          <div className="col-4">
            <input
              value={this.props.tradingName || ""}
              className="input-text"
              onChange={(e) =>
                this.props.onInputChange("tradingName", e.target.value)
              }
              type="text"
              placeholder="Trading Name"
            />
            {this.validator.message(
              "Trading Name",
              this.props.tradingName,
              "required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label required">
              Trading Start Date
            </label>
          </div>
          <div className="col-4">
            <DatePicker
              yearPlaceholder="yyyy"
              monthPlaceholder="MM"
              dayPlaceholder="dd"
              format="dd-MM-yyyy"
              onChange={(date) =>
                this.props.onInputChange("tradingStartDate", date)
              }
              value={
                this.props.tradingStartDate
                  ? new Date(this.props.tradingStartDate)
                  : null
              }
            />
            {this.validator.message(
              "Trading Start Date",
              this.props.tradingStartDate,
              "required|_date",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label required">Trading Address</label>
          </div>
          <div className="col-4">
            <OrganisationAddress
              addressHeader="Trading Address"
              addressPrefix="trading"
              addressSearch={this.props.tradingAddressSearch}
              premises={this.props.tradingPremises}
              addressLine1={this.props.tradingAddressLine1}
              addressLine2={this.props.tradingAddressLine2}
              locality={this.props.tradingLocality}
              region={this.props.tradingRegion}
              country={this.props.tradingCountry}
              postcode={this.props.tradingPostcode}
              onInputChange={this.props.onInputChange}
            />
            {this.validator.message(
              "Trading Address",
              formatAddress(
                this.props.tradingPremises,
                this.props.tradingAddressLine1,
                this.props.tradingAddressLine2,
                this.props.tradingLocality,
                this.props.tradingRegion,
                this.props.tradingCountry,
                this.props.tradingPostcode
              ),
              "required",
              { className: "text-danger" }
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderESignnature() {
    return (
      <div className="fieldset-group">
        <label className="fieldset-group-label">E Signature</label>
        <div className="row fieldset">
                <div className="col-2">
                    <label className="fieldset-label required">Engagement Letter</label>
                </div>
                <div className="col-4">
                    {/* Use the Switch component */}
                    <Switch
                        checked={this.props.isVisible}
                        onChange={this.handleToggleEngagementLetter}
                    />
                </div>
            </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Signatory Name</label>
          </div>
          <div className="col-4">
            <input
              value={this.props.signatoryName || ""}
              className="input-text"
              onChange={(e) =>
                this.props.onInputChange("signatoryName", e.target.value)
              }
              type="text"
              placeholder="Signatory Name"
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Signature Image</label>
          </div>
          <div className="col-4">
            {this.props.signatureUrl && (
              <div className="organisation-details-image-div">
                <img
                  src={this.props.signatureUrl}
                  className="organisation-details-image"
                />
                <span
                  onClick={() => this.props.onInputChange("signatureUrl", null)}
                  className="remove-organisation-details-image-preview"
                >
                  <i className="ion ion-ios-close-circle"></i>
                </span>
              </div>
            )}
            {!this.props.signatureUrl && (
              <>
                <button
                  onClick={() =>
                    this.props.setShowImageUploadModal(true, "Signature")
                  }
                  className="btn btn-primary"
                >
                  Upload Signature
                </button>
                <br />
                <i>
                  <small>
                    Supported filetypes are .jpg, .jpeg, .png, .svg up to a file
                    size of 2MB.
                  </small>
                </i>
              </>
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Proposal Format</label>
          </div>
          <div className="col-4">
            <Select
              options={[
                { value: "PDF", label: "PDF" },
                { value: "HTML", label: "HTML" },
              ]}
              onChange={(e) => {
                this.props.onInputChange(
                  "contract_email_method",
                  e.value
                );
              }}
              value={[
                { value: "PDF", label: "PDF" },
                { value: "HTML", label: "HTML" },
              ].find(
                (c) =>
                  c.value === this.props.contract_email_method
              )}
            />
          </div>
        </div>


        {  this.state?.organisationData?.result?.isVisible && (
      <React.Fragment>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Engagement Letter - Individual Client Default Template</label>
          </div>
          <div className="col-4">
            <Select
              options={this.state.templateOptions['individual']}
              onChange={(e) => {
                this.props.onInputChange(
                  "individual_template_id",
                  e.value
                );
              }}
              value={this.state.templateOptions['individual'].find(
                (c) =>
                  c.value === this.props.individual_template_id
              )}
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Engagement Letter - Sole Trader Client Default Template</label>
          </div>
          <div className="col-4">
            <Select
              options={this.state.templateOptions['soleTrader']}
              onChange={(e) => {
                this.props.onInputChange(
                  "soleTrader_template_id",
                  e.value
                );
              }}
              value={this.state.templateOptions['soleTrader'].find(
                (c) =>
                  c.value === this.props.soleTrader_template_id
              )}
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Engagement Letter - Company Client Default Template</label>
          </div>
          <div className="col-4">
            <Select
              options={this.state.templateOptions['ltd']}
              onChange={(e) => {
                this.props.onInputChange(
                  "ltd_template_id",
                  e.value
                );
              }}
              value={this.state.templateOptions['ltd'].find(
                (c) =>
                  c.value === this.props.ltd_template_id
              )}
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Engagement Letter - LLP Client Default Template</label>
          </div>
          <div className="col-4">
            <Select
              options={this.state.templateOptions['llp']}
              onChange={(e) => {
                this.props.onInputChange(
                  "llp_template_id",
                  e.value
                );
              }}
              value={this.state.templateOptions['llp'].find(
                (c) =>
                  c.value === this.props.llp_template_id
              )}
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-2">
            <label className="fieldset-label">Engagement Letter - Partnership Client Default Template</label>
          </div>
          <div className="col-4">
            <Select
              options={this.state.templateOptions['partnership']}
              onChange={(e) => {
                this.props.onInputChange(
                  "partnership_template_id",
                  e.value
                );
              }}
              value={this.state.templateOptions['partnership'].find(
                (c) =>
                  c.value === this.props.partnership_template_id
              )}
            />
          </div>
        </div>
        </React.Fragment>
        )}


      </div>
    );
  }
  // setSignatories() {
  //   let signatories = [];
  //   for (let item of this.props.signatories) {
  //     signatories.push({
  //       id: signatories._id,
  //       firstName: signatories.first_name,
  //       lastName: signatories.last_name,
  //       email: signatories.email,
  //       signaturePosition: "right",
  //     });
  //   }
  //   this.props.setSignatories(signatories);
  // }
  /* Contracts By Clients */
  renderSignatories() {
    console.log("spec_check_signatories", this.props.signatories);
    return this.props?.signatories?.map((item, index) => (
      <ContractSignatory
        onInputChange={this.props.onInputChange}
        delete={this.props.deleteSignatory}
        code={item}
        key={index}
        index={index}
        firstName={this.props[`signatory${item}FirstName`]}
        lastName={this.props[`signatory${item}LastName`]}
        email={this.props[`signatory${item}Email`]}
        signaturePosition={this.props[`signatory${item}SignaturePosition`]}
        validator={this.validator}
      />
    ));
  }
  generateNewSignatoryCode() {
    return new Date().getTime();
  }
  /* Contracts By Clients END*/
  render() {
    const tncOptions = this.props.tncTemplates.map((item) => {
      return { value: item._id, label: item.name };
    });
    const availablePaymentOptions = this.props.availablePaymentMethods.map(
      (item) => ({ value: item, label: item })
    );
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Update Practice Details</div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <h4>Basic Information</h4>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">Business Type</label>
                    </div>
                    <div className="col-4">
                      <input
                        type="text"
                        className="input-text"
                        readOnly
                        value={this.props.businessType}
                      />
                    </div>
                  </div>
                  {["soleTrader", "partnership"].includes(
                    this.props.businessType
                  ) && (
                      <React.Fragment>
                        {this.renderTradingDetails()}
                        {this.renderESignnature()}
                      </React.Fragment>
                    )}
                  {["llp", "ltd"].includes(this.props.businessType) && (
                    <React.Fragment>
                      {/*                N E W     F I E L D    U P D A T E    C O M P A N Y                */}
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label">
                            Update Company
                          </label>
                        </div>
                        <div className="col-4">
                          <AutoCompleteInput
                            value={this.props.companySearchInput || ""}
                            onInputChange={this.props.onInputChange}
                            onSelect={this.props.selectCompanySearchItem}
                            search={this.props.companySearch}
                            name="companySearchInput"
                            placeholder="Search by Company Name or Company Number"
                            items={this.props.companySearchItems.filter(
                              (item) => item.companyType
                            )}
                            valueProperty="title"
                          />
                          {/* <input value={this.props.companyName || ""} readOnly className="input-text" type="text" placeholder="Company Name" />
                        {this.validator.message('Company Name', this.props.companyName, 'required', { className: 'text-danger' })} */}
                        </div>
                      </div>
                      {/*                                E N D                           */}
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label">Company Name</label>
                        </div>
                        <div className="col-4">
                          <input
                            value={this.props.companyName || ""}
                            readOnly
                            className="input-text"
                            type="text"
                            placeholder="Company Name"
                          />
                          {this.validator.message(
                            "Company Name",
                            this.props.companyName,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label">Entity Type</label>
                        </div>
                        <div className="col-4">
                          <input
                            value={this.props.companyType}
                            readOnly
                            className="input-text"
                            type="text"
                            placeholder="Entity Type"
                          />
                          {this.validator.message(
                            "Entity Type",
                            this.props.companyType,
                            "",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label">
                            Company Number
                          </label>
                        </div>
                        <div className="col-4">
                          <input
                            value={this.props.companyNumber || ""}
                            readOnly
                            className="input-text"
                            type="text"
                            placeholder="Company Number"
                          />
                          {this.validator.message(
                            "Company Number",
                            this.props.companyNumber,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label">
                            Registered Office Address
                          </label>
                        </div>
                        <div className="col-4">
                          <OrganisationAddress
                            addressHeader="Registered Office Address"
                            addressPrefix="company"
                            addressSearch={this.props.companyAddressSearch}
                            premises={this.props.companyPremises}
                            addressLine1={this.props.companyAddressLine1}
                            addressLine2={this.props.companyAddressLine2}
                            locality={this.props.companyLocality}
                            region={this.props.companyRegion}
                            country={this.props.companyCountry}
                            postcode={this.props.companyPostcode}
                            onInputChange={this.props.onInputChange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label">
                            Incorporation Date
                          </label>
                        </div>
                        <div className="col-4">
                          <input
                            value={
                              this.props.companyIncorporationDate
                                ? formatDate(
                                  this.props.companyIncorporationDate
                                )
                                : ""
                            }
                            readOnly
                            className="input-text"
                            type="text"
                            placeholder="Incorporation Date"
                          />
                          {this.validator.message(
                            "Company Incorporation Date",
                            this.props.companyIncorporationDate,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label">
                            Incorporated In
                          </label>
                        </div>
                        <div className="col-4">
                          <input
                            type="text"
                            className="input-text"
                            value={this.props.companyIncorporatedIn}
                            readOnly
                          />
                          {this.validator.message(
                            "Company Incorporated In",
                            this.props.companyIncorporatedIn,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="fieldset-group">
                        <label className="fieldset-group-label">
                          Trading Details
                        </label>
                        {this.renderTradingDetails()}
                      </div>
                      {this.renderESignnature()}
                    </React.Fragment>
                  )}

                  <div className="separator mt-3 mb-3" />

                  <h4>Other Information</h4>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">Website</label>
                    </div>
                    <div className="col-4">
                      <input
                        value={this.props.website || ""}
                        onChange={(e) =>
                          this.props.onInputChange("website", e.target.value)
                        }
                        className="input-text"
                        type="text"
                        placeholder="Website"
                      />
                      {this.validator.message(
                        "Website",
                        this.props.website,
                        "_url",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">Contact Email</label>
                    </div>
                    <div className="col-4">
                      <input
                        value={this.props.email || ""}
                        onChange={(e) =>
                          this.props.onInputChange("email", e.target.value)
                        }
                        className="input-text"
                        type="text"
                        placeholder="Contact Email"
                      />
                      {this.validator.message(
                        "Email",
                        this.props.email,
                        "email",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">Contact Phone</label>
                    </div>
                    <div className="col-4">
                      <InputPhone
                        placeholder="Phone"
                        phoneFieldName="phone"
                        dialCodeFieldName="dialCode"
                        dialCode={this.props.dialCode}
                        phone={this.props.phone}
                        validator={this.validator}
                        onChange={this.props.onInputChange}
                      />
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label ">VAT Registered</label>
                    </div>
                    <div className="col-4">
                      <Select
                        options={vatValues}
                        value={
                          typeof this.props.vatRegistered === "boolean"
                            ? vatValues.filter(
                              (option) =>
                                option.value === this.props.vatRegistered
                            )
                            : vatValues.filter(
                              (option) => option.value === false
                            )
                        }
                        onChange={(selectedOption) =>
                          this.props.onInputChange(
                            "vatRegistered",
                            selectedOption.value
                          )
                        }
                      />
                      {this.validator.message(
                        "vat Registered",
                        this.props.vatRegistered,
                        "",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                  {this.props.vatRegistered && (
                    <div className="row fieldset">
                      <div className="col-2">
                        <label className="fieldset-label ">VAT Number</label>
                      </div>
                      <div className="col-4">
                        <input
                          value={this.props.vatNumber || ""}
                          onChange={(e) =>
                            this.props.onInputChange(
                              "vatNumber",
                              e.target.value.toUpperCase()
                            )
                          }
                          className="input-text"
                          type="text"
                          placeholder="VAT Number"
                        />
                        {/* {this.validator.message('vat Number', this.props.vatNumber, 'required|alpha_num', { className: 'text-danger' })} */}
                      </div>
                    </div>
                  )}
                  {this.props.currency && (
                    <div className="row fieldset">
                      <div className="col-2">
                        <label className="fieldset-label required">
                          Currency
                        </label>
                      </div>
                      <div className="col-4">
                        <input
                          readOnly
                          value={this.props.currency || ""}
                          onChange={(e) =>
                            this.props.onInputChange(
                              "currency",
                              e.target.value.toUpperCase()
                            )
                          }
                          className="input-text"
                          type="text"
                          placeholder="Preferred Currency"
                        />
                      </div>
                    </div>
                  )}
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">Logo</label>
                    </div>
                    <div className="col-4">
                      {this.props.logoUrl && (
                        <div className="organisation-details-image-div">
                          <img
                            src={this.props.logoUrl}
                            className="organisation-details-image"
                          />
                          <span
                            onClick={() =>
                              this.props.onInputChange("logoUrl", null)
                            }
                            className="remove-organisation-details-image-preview"
                          >
                            <i className="ion ion-ios-close-circle"></i>
                          </span>
                        </div>
                      )}
                      {!this.props.logoUrl && (
                        <>
                          <button
                            onClick={() =>
                              this.props.setShowImageUploadModal(true, "Logo")
                            }
                            className="btn btn-primary"
                          >
                            Upload Logo
                          </button>
                          <br />
                          <i>
                            <small>
                              Supported filetypes are .jpg, .jpeg, .png, .svg up
                              to a file size of 2MB.
                            </small>
                          </i>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">Brand Colour</label>
                    </div>
                    <div className="col-4">
                      {this.props.brandColor && (
                        <input
                          value={this.props.brandColor || ""}
                          onChange={(e) =>
                            this.props.onInputChange(
                              "brandColor",
                              e.target.value
                            )
                          }
                          className="input-text"
                          type="color"
                          placeholder="Brand Colour"
                        />
                      )}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">Business Tagline</label>
                    </div>
                    <div className="col-4">
                      <input
                        value={this.props.businessTagline || ""}
                        onChange={(e) =>
                          this.props.onInputChange(
                            "businessTagline",
                            e.target.value
                          )
                        }
                        className="input-text"
                        type="text"
                        placeholder="Business Tagline"
                      />
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">
                        Affiliated Accounting Body Name
                      </label>
                    </div>
                    <div className="col-4">
                      <input
                        value={this.props.affiliatedAccountingBodyName || ""}
                        onChange={(e) =>
                          this.props.onInputChange(
                            "affiliatedAccountingBodyName",
                            e.target.value
                          )
                        }
                        className="input-text"
                        type="text"
                        placeholder="Affiliated Accounting Body Name"
                      />
                      {/* {this.validator.message('affiliatedAccountingBodyName', this.props.affiliatedAccountingBodyName, '_required', { className: 'text-danger' })} */}
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-2">
                      <label className="fieldset-label">
                        Website of Affiliated Accounting Body
                      </label>
                    </div>
                    <div className="col-4">
                      <input
                        value={
                          this.props.websiteOfAffiliatedAccountingBody || ""
                        }
                        onChange={(e) =>
                          this.props.onInputChange(
                            "websiteOfAffiliatedAccountingBody",
                            e.target.value
                          )
                        }
                        className="input-text"
                        type="text"
                        placeholder="Website of Affiliated Accounting Body"
                      />
                    </div>
                  </div>

                  <div className="separator mt-3 mb-3" />

                  {this.props.businessType === "soleTrader" && (
                    <h4>Sole Trader Details</h4>
                  )}
                  {this.props.businessType === "partnership" && (
                    <h4>Partner Details</h4>
                  )}
                  {["llp", "ltd"].includes(this.props.businessType) && (
                    <h4>Officer Details</h4>
                  )}
                  {this.props.businessType === "soleTrader" && (
                    <React.Fragment>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label required">
                            First Name
                          </label>
                        </div>
                        <div className="col-4">
                          <input
                            value={this.props.soleTraderFirstName || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "soleTraderFirstName",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="text"
                            placeholder="First Name"
                          />
                          {this.validator.message(
                            "First Name",
                            this.props.soleTraderFirstName,
                            "required|_name",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label required">
                            Last Name
                          </label>
                        </div>
                        <div className="col-4">
                          <input
                            value={this.props.soleTraderLastName || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "soleTraderLastName",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="text"
                            placeholder="Last Name"
                          />
                          {this.validator.message(
                            "Last Name",
                            this.props.soleTraderLastName,
                            "required|_name",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label required">
                            Phone
                          </label>
                        </div>
                        <div className="col-4">
                          <InputPhone
                            placeholder="Phone"
                            phoneFieldName="soleTraderPhone"
                            dialCodeFieldName="soleTraderDialCode"
                            dialCode={this.props.soleTraderDialCode}
                            phone={this.props.soleTraderPhone}
                            // value={this.props.soleTraderPhone}
                            validator={this.validator}
                            onChange={this.props.onInputChange}
                          />
                          {this.validator.message(
                            "Phone",
                            this.props.soleTraderPhone,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label required">
                            Email
                          </label>
                        </div>
                        <div className="col-4">
                          <input
                            value={this.props.soleTraderEmail || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "soleTraderEmail",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="text"
                            placeholder="Email"
                          />
                          {this.validator.message(
                            "Email",
                            this.props.soleTraderEmail,
                            "required|email",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2">
                          <label className="fieldset-label required">
                            Residential Address
                          </label>
                        </div>
                        <div className="col-4">
                          <OrganisationAddress
                            addressHeader="Residential Address"
                            addressPrefix="soleTrader"
                            addressSearch={this.props.soleTraderAddressSearch}
                            premises={this.props.soleTraderPremises}
                            addressLine1={this.props.soleTraderAddressLine1}
                            addressLine2={this.props.soleTraderAddressLine2}
                            locality={this.props.soleTraderLocality}
                            region={this.props.soleTraderRegion}
                            country={this.props.soleTraderCountry}
                            postcode={this.props.soleTraderPostcode}
                            onInputChange={this.props.onInputChange}
                          />
                          {this.validator.message(
                            "Residential Address",
                            formatAddress(
                              this.props.soleTraderPremises,
                              this.props.soleTraderAddressLine1,
                              this.props.soleTraderAddressLine2,
                              this.props.soleTraderLocality,
                              this.props.soleTraderRegion,
                              this.props.soleTraderCountry,
                              this.props.soleTraderPostcode
                            ),
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.props.businessType === "partnership" && (
                    <React.Fragment>
                      {this.renderPartners()}
                      {this.validator.message(
                        "partnersArray",
                        this.props.partnerCodes,
                        "_requiredArray:partner",
                        { className: "text-center minimum-array-1" }
                      )}
                      <div className="row fieldset">
                        <div className="col-12 text-right">
                          <button
                            onClick={() =>
                              this.props.addPartner(
                                this.generateNewPartnerCode()
                              )
                            }
                            className="btn btn-sm create-item"
                          >
                            <i className="ion ion-md-add-circle"></i>Add Partner
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {["llp", "ltd"].includes(this.props.businessType) && (
                    <React.Fragment>
                      {this.renderOfficers()}
                      {this.validator.message(
                        "officersArray",
                        this.props.officerCodes,
                        "_requiredArray:officer",
                        { className: "text-center minimum-array-1" }
                      )}
                      <div className="row fieldset">
                        <div className="col-12 text-right">
                          <button
                            onClick={() =>
                              this.props.addOfficer(
                                this.generateNewOfficerCode()
                              )
                            }
                            className="btn btn-sm create-item"
                          >
                            <i className="ion ion-md-add-circle"></i>Add Officer
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {/* Contracts by Client*/}
                  {/* <div className="row fieldset">
                    <div className="col-12">
                      <h4 className="mt-2">Send Contract In Format</h4>
                      <div className="separator mb-3" />
                      <div className="row fieldset">
                        <div className="col-12">
                          <Select
                            options={[
                              { value: "PDF", label: "PDF" },
                              { value: "HTML", label: "HTML" },
                            ]}
                            onChange={(e) => {
                              this.props.onInputChange(
                                "contract_email_method",
                                e.value
                              );
                            }}
                            value={[
                              { value: "PDF", label: "PDF" },
                              { value: "HTML", label: "HTML" },
                            ].find(
                              (c) =>
                                c.value === this.props.contract_email_method
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* {this.props.contract_email_method == "HTML" ? (
                    <>
                      <div className="row fieldset">
                        <div className="col-12">
                          <h4 className="mt-2">Signatories</h4>
                          <div className="separator mb-3" />
                          {this.renderSignatories()}
                          <div className="row fieldset">
                            <div className="col-12 text-right">
                              <button
                                onClick={() =>
                                  this.props.addSignatory(
                                    this.generateNewSignatoryCode()
                                  )
                                }
                                className="btn btn-sm create-item"
                              >
                                <i className="ion ion-md-add-circle"></i>Add
                                Signatory
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-12">
                          <h4 className="mt-2">Terms & Conditions</h4>
                          <div className="separator mb-3" />
                          <div className="row fieldset">
                            <div className="col-2">
                              <label className="fieldset-label">
                                TnC Template
                              </label>
                            </div>
                            <div className="col-10">
                              <Select
                                options={tncOptions}
                                onChange={(selectedOption) => {
                                  console.log(
                                    "selectedOption - ",
                                    selectedOption,
                                    this.props.tncTemplates,
                                    this.props
                                  );
                                  let tnc = this.props.tncTemplates.filter(
                                    (item) => item._id === selectedOption.value
                                  )[0];

                                  this.props.onInputChange(
                                    "tncTemplateOrg",
                                    tnc._id
                                  );

                                  let tncType = tnc.tnc_template_type;
                                  this.props.onInputChange("tncType", tncType);
                                  if (tncType === "customTemplate") {
                                    let tncHtml =
                                      this.props.tncTemplates.filter(
                                        (item) =>
                                          item._id === selectedOption.value
                                      )[0].template[0].tncBlock;
                                    // this.tncEditor.current.updateValue(tncHtml);
                                    this.props.onInputChange("tncOrg", tncHtml);
                                  } else {
                                    let template_url =
                                      this.props.tncTemplates.filter(
                                        (item) =>
                                          item._id === selectedOption.value
                                      )[0].template_url;
                                    this.props.onInputChange(
                                      "template_url",
                                      template_url
                                    );
                                  }
                                }}
                                value={tncOptions.find(
                                  (c) => c.value === this.props.tncTemplateOrg
                                )}
                              />
                            </div>
                          </div>
                          {this.props.tncType === "customTemplate" && (
                            <MyEditor
                              // ref={this.tncEditor}
                              value={this.props.tncOrg}
                              variable={"tncOrg"}
                              onChange={this.props.onInputChange}
                            />
                          )}
                          {this.props.tncType === "pdf" && (
                            <iframe
                              src={this.props.template_url}
                              className="pdfIframe"
                            />
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-2 text-right">
                          <label className="fieldset-label">
                            Payment Gateway
                          </label>
                        </div>
                        <div className="col-10">
                          <Select
                            options={availablePaymentOptions}
                            onChange={(selectedOption) =>
                              this.props.onInputChange(
                                "payment_method",
                                selectedOption.value
                              )
                            }
                            value={
                              this.props.payment_method
                                ? availablePaymentOptions.filter(
                                    (option) =>
                                      option.value === this.props.payment_method
                                  )
                                : ""
                            }
                          />
                          {this.validator.message(
                            "payment_method",
                            this.props.payment_method,
                            "_required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                    </>
                  ) : null} */}
                  {/* Contracts by Client END*/}
                  <div className="row fieldset">
                    <div className="col-12">
                      <button
                        onClick={this.updateOrganisation}
                        className="btn btn-lg btn-block mt-3 create-item"
                      >
                        Update Practice Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImageUploadModal
          uploadImage={this.getUploadImageFunction()}
          type={this.props.imageUploadModalType}
          showImageUploadModal={this.props.showImageUploadModal}
          setShowImageUploadModal={this.props.setShowImageUploadModal}
          allowedExtentions={["jpg", "png", "jpeg", "svg"]}
        />
        {this.props.showUpdateOrganisationSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.props.setShowUpdateOrganisationSuccessModal(false)
            }
          >
            <div className="mb-2 success-alert-message">
              Practice Details has been updated successfully!
            </div>
          </SweetAlert>
        )}
        {this.props.loading && <Loader />}
        {this.state.organisationData===null && <Loader/>}
        {!this.props.tradingName && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { clients } = state.Client;
  const { availablePaymentMethods } = state.Common;
  return {
    ...state.Contract,
    ...state.Organisation,
    clients,
    availablePaymentMethods,
    Template: state.Template
  };
};

export default connect(mapStateToProps, {
  onInputChange,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  getMyOrganisation,
  updateMyOrganisation,
  setShowImageUploadModal,
  setShowUpdateOrganisationSuccessModal,
  uploadLogo,
  uploadSignature,
  resetOrganisationForm,
  companySearch,
  selectCompanySearchItem,
  addSignatory,
  deleteSignatory,
  setSignatories,
  getTncTemplates,
  getAvailablePaymentMethods,
  getClients,
  onContractInputChange,
  getTemplates,
})(UpdateOrganisationDetails);
