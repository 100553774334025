import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_USER_WITH_PROVIDER,
  SWITCH_ORGANISATION,
} from "./actionTypes";
import { loginSuccess, logoutUserSuccess, loginError } from "./actions";
// import { setShowApiErrorModal } from '../../common/actions';

//AUTH related methods
import { getFirebaseBackend } from "../../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { email, password, history } }) {
  try {
    const loginResponse = yield axios({
      method: "POST",
      url: "login",
      data: {
        email: email.toLocaleLowerCase(),
        password: password,
      },
    });
    if (loginResponse.data.success) {
      const firebaseToken = yield call(
        fireBaseBackend.signInWithCustomToken,
        loginResponse.data.result.accessToken
      );
      yield put(loginSuccess(firebaseToken));
      history.push("/dashboard");
    } else {
      yield put(loginError(loginResponse.data.error));
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      yield put(loginError(error.response.data.error));
    } else {
      yield put(loginError("Something went Wrong!"));
    }
  }
}

function* loginUserWithProvider({ payload: { provider, history } }) {
  try {
    switch (provider) {
      case "google":
        provider = yield call(fireBaseBackend.getAuthProviders);
        break;
      case "facebook":
        provider = yield call(fireBaseBackend.getFacebookAuthProviders);
        break;
      case "microsoft":
        provider = yield call(fireBaseBackend.getMicrosoftAuthProviders);
        break;
      case "apple":
        provider = yield call(fireBaseBackend.getAppletAuthProviders);
        break;
      default:
        yield put(loginError("Unknown Provider"));
        return;
    }
    // debugger;
    // const authObject = yield call(fireBaseBackend.getAuthObject, 'signInWithPopup', provider);

    const response = yield fireBaseBackend
      .getAuthObject()
      .signInWithPopup(provider);

    const loginResponse = yield axios({
      method: "POST",
      url: "sign-in-with-google",
      data: {
        idToken: response?.credential?.idToken,
        first_name:
          provider?.providerId === "google.com"
            ? response?.additionalUserInfo?.profile?.given_name
            : provider?.providerId === "microsoft.com"
            ? response?.additionalUserInfo?.profile?.givenName
            : null,
        last_name:
          provider?.providerId === "google.com"
            ? response?.additionalUserInfo?.profile?.family_name || response?.additionalUserInfo?.profile?.name
            : provider?.providerId === "microsoft.com"
            ? response?.additionalUserInfo?.profile?.surname
            : null,
      },
    });
    if (loginResponse.data.success) {
      const firebaseToken = yield call(
        fireBaseBackend.signInWithCustomToken,
        loginResponse.data.result.accessToken
      );

      yield put(loginSuccess(firebaseToken));
      history.push("/dashboard");
    } else {
      yield put(loginError(loginResponse.data.error));
    }

    // try {
    // yield axios({
    //     method: "GET",
    //     url: "test"
    // });
    // } catch (error) {
    //     console.log(error);
    //     return;
    // }
    // const response = yield call([fireBaseBackend, 'getAuthObject', 'signInWithPopup'], provider);
    // const response = yield call(authObject.signInWithPopup, provider)
    // yield put(loginSuccess(response.user));
    // history.push("/dashboard");
  } catch (error) {
    if (error.code === "auth/popup-closed-by-user") {
      yield put(
        loginError(
          "Looks like you closed the popup too soon. Login incomplete. Please try again."
        )
      );
    } else {
      yield put(loginError(error.message));
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(fireBaseBackend.logout);
    yield put(logoutUserSuccess(response));
    history.push("/");
  } catch (error) {
    yield put(loginError(error));
  }
}

function* switchOrganisation({ payload: organisationId }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: "switch-organisation",
      data: {
        organisationId,
      },
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      // yield call(fireBaseBackend.logout);
      const firebaseToken = yield call(
        fireBaseBackend.signInWithCustomToken,
        response.data.result.accessToken
      );
      yield put(loginSuccess(firebaseToken));
      localStorage.removeItem("dashboardFilter");
      window.location.href = "/dashboard";
    } else {
      // yield put(loginError(loginResponse.data.error));
    }
  } catch (error) {
    //
    console.log(error);
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLoginWithProvider() {
  yield takeEvery(LOGIN_USER_WITH_PROVIDER, loginUserWithProvider);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchSwitchOrganisation() {
  yield takeEvery(SWITCH_ORGANISATION, switchOrganisation);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLoginWithProvider),
    fork(watchUserLogout),
    fork(watchSwitchOrganisation),
  ]);
}

export default authSaga;
