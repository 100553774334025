import React, { Component } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFirebaseBackend } from "../../helpers/authUtils";
import { getMyOrganisations, switchOrganisation } from "../../store/actions";

class OrganisationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {createMenu: false};
    const fireBaseBackend = getFirebaseBackend();
    this.organisationName = fireBaseBackend.getOrganistionName();
    this.organisationId = fireBaseBackend.getOrganistionId();
    this.renderMyOrganisations = this.renderMyOrganisations.bind(this);
    this.switchOrganisation = this.switchOrganisation.bind(this);
  }
  componentDidMount() {
    this.props.getMyOrganisations();
  }
  switchOrganisation(event) {
    this.props.switchOrganisation(event.target.dataset.orgid);
  }
  renderMyOrganisations(item, index) {
    return (
      <DropdownItem onClick={this.switchOrganisation} data-orgid={item._id} key={index} tag="a" href="#">
        {item.trading_business_name}
      </DropdownItem>
    );
  }
  render() {
    return (
      this.organisationName ? <Dropdown
        isOpen={this.state.createMenu}
        toggle={() =>
          this.setState({ createMenu: !this.state.createMenu })
        }
        className="d-inline-block"
      >
        <DropdownToggle className="organisation-selector" tag="div">
          <span>{this.organisationName}</span><i className="ion ion-md-arrow-dropdown"></i>
        </DropdownToggle>
        <DropdownMenu className="organisation-dropdown">
          {this.props.myOrganisations.filter(item => item._id!==this.organisationId).map(this.renderMyOrganisations)}
          <DropdownItem tag="a" onClick={() => this.props.history.push("/organisations/new")}>
            Create New Practice
          </DropdownItem>
        </DropdownMenu>
      </Dropdown> : <div onClick={() => this.props.history.push("/organisations/new")} className="organisation-selector">+ Setup New Practice</div>
    );
  }
}

const mapStatetoProps = state => {
  const { myOrganisations } = state.RoleManagement;
  return { myOrganisations };
};

export default withRouter(connect(mapStatetoProps, { getMyOrganisations, switchOrganisation })(OrganisationDropdown));
