import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { apiBaseUrl } from "../config";
import { ucFirst } from "../Utils";
import { getQuotes } from "../store/quote/actions";
import Paginator from "./Paginator";
import { formatCurrency } from "../Utils";
import { connect } from "react-redux";
import axios from 'axios';

class QuoteListTable extends Component {
  constructor(props) {
    super(props);
    this.renderQuotes = this.renderQuotes.bind(this);
    this.state = {
      organisationData: null,
      error: null,
    };
  }

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem('authUser'));
  
    if (token && token.stsTokenManager && token.stsTokenManager.accessToken) {
      axios.get('https://teststaging.outbooks.com/api/organisation/me', {
        headers: {
          'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
        }
      })
      .then(response => {
        this.setState({ organisationData: response.data });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
    } else {
      this.setState({ error: 'Token not found or invalid.' });
    }
  }

  renderQuotes(item, index) {
    let statusBadge = "";
    if (item.status === "sent") {
      statusBadge = "badge-primary";
    } else if (item.status === "draft") {
      statusBadge = "badge-warning";
    } else if (item.status === "skipped") {
      statusBadge = "badge-info";
    }if (item.status === "accepted") {
      statusBadge = "badge-success";
    }if (item.status === "declined") {
      statusBadge = "badge-danger";
    }if (item.status === "pending") {
      statusBadge = "badge-warning";
    }
    const greenStyle = { backgroundColor: "#008000" };
    const darkRed = { backgroundColor: "#FF0000" };
    return (
      <tr key={index}>
        <td>
          {item.prefix}-{item.code}
        </td>
        <td>{item.data.client.displayName}</td>
        <td className="quote-status">
        <div className={`badge ${statusBadge}`} style={item.status === "accepted" ? greenStyle : item.status === "declined" ? darkRed :   {}}>
                    {ucFirst(item.status)}
                </div>
        </td>
        <td>
          {(item.status === "sent" || item.status === "accepted" || item.status === "declined") && (
            <div>
              <div>
                Recurring:{" "}
                {item.recurring_total
                  ? formatCurrency(item.recurring_total)
                  : "NA"}
              </div>
              <div>
                One-Off:{" "}
                {item.one_off_total ? formatCurrency(item.one_off_total) : "NA"}
              </div>
            </div>
          )}
          {item.status === "skipped" && (
            <div>
              <div>
                Recurring:{" "}
                {item.recurring_total
                  ? formatCurrency(item.recurring_total)
                  : "NA"}
              </div>
              <div>
                One-Off:{" "}
                {item.one_off_total ? formatCurrency(item.one_off_total) : "NA"}
              </div>
            </div>
          )}
        </td>
        <td>
          {(item.status === "sent" || item.status === "accepted" || item.status === "declined") && (
            <div>
              <Link
                to={{
                  pathname: `/quotes/view-pdf/${btoa(item._id)}`,
                }}
                target="_blank"
              >
                Proposal PDF
              </Link>
            </div>
          )}
          {item.status === "skipped" && (
            <div>
              <Link
                to={{
                  pathname: `/quotes/view-pdf/${btoa(item._id)}`,
                }}
                target="_blank"
              >
                Proposal PDF
              </Link>
            </div>
          )}
        </td>
        <td className="serivce-actions text-right">
          {(item.status === "sent" && (item.is_el_was_on_or_off !== false) && this.state?.organisationData?.result?.isVisible) && (
            <button
              onClick={() =>
                this.props.history.push({
                  pathname: "/contracts/new",
                  state: {
                    quoteId: item._id,
                    quoteType: item.type,
                    quoteClient: item.client,
                    quoteClientBusinessType: item.client_business_type,
                  },
                })
              }
              className="btn btn-sm btn-dark"
            >
              Generate Engagement Letter
              <i className="ion ion-md-settings ml-1 send-proposal-ion-icon"></i>
            </button>
          )}
          {item.status === "skipped" && (item.is_el_was_on_or_off !== false) && this.state?.organisationData?.result?.isVisible && (
            <button
              onClick={() =>
                this.props.history.push({
                  pathname: "/contracts/new",
                  state: {
                    quoteId: item._id,
                    quoteType: item.type,
                    quoteClient: item.client,
                    quoteClientBusinessType: item.client_business_type,
                    skip: true,
                  },
                })
              }
              className="btn btn-sm btn-dark"
            >
              Generate Engagement Letter
              <i className="ion ion-md-settings ml-1 send-proposal-ion-icon"></i>
            </button>
          )}
          {/*item.status==="sent" && <button className="btn btn-sm btn-dark ml-2">Re-send Quote<i className="ion ion-ios-send ml-1 send-proposal-ion-icon"></i></button>*/}
          {(item.status === "sent" || item.status === "accepted" || item.status === "declined") && (
            <button
              onClick={() => this.props.history.push(`/quotes/${item._id}`)}
              data-toggle="tooltip"
              title="View Proposal"
              className="btn btn-sm btn-dark ml-2"
            >
              View
              <i className="ion ion-md-eye ml-1" />
            </button>
          )}
          {item.status === "skipped" && (
            <button
              onClick={() => this.props.history.push(`/quotes/${item._id}`)}
              data-toggle="tooltip"
              title="View Proposal"
              className="btn btn-sm btn-dark ml-2"
            >
              View
              <i className="ion ion-md-eye ml-1" />
            </button>
          )}
          {item.status === "draft" && (
            <button
              onClick={() =>
                this.props.history.push(`/quotes/edit/${item._id}`)
              }
              data-toggle="tooltip"
              title="Edit Proposal"
              className="btn btn-sm btn-dark ml-2"
            >
              Edit
              <i className="ion ion-md-create ml-1" />
            </button>
          )}
          {/*<button data-toggle="tooltip" title="Delete Service" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>*/}
        </td>
      </tr>
    );
  }
  render() {
    return this.props.quotes.length > 0 ? (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Ref ID</th>
                <th>Prospects</th>
                <th>Status</th>
                <td>Value</td>
                <td>Documents</td>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.props.quotes.map(this.renderQuotes)}</tbody>
          </table>
          <Paginator
            totalPages={this.props.pagination.total_pages}
            pageNum={this.props.pagination.page_num}
            getObjects={this.props.getQuotes}
            status={this.props.status}
          />
        </div>
      </div>
    ) : (
      <div className="text-center">No Proposals Found!</div>
    );
  }
}

export default withRouter(connect(null, { getQuotes })(QuoteListTable));
