import React, { Component, useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { onInputChange } from "../store/quote/actions";
import Loader from "../components/Loader";
import SelectServices from "../components/SelectServices";
import ReviewServices from "../components/ReviewServices";
import StepperWidget from "../components/StepperWidget";
import RequiredGlobalPricingDrivers from "../components/RequiredGlobalPricingDrivers";
import SelectPackagesStep from "../components/Quote/SelectPackagesStep";
import ReviewPackagesStep from "../components/Quote/PackagedPricing/ReviewPackagesStep";
import CustomReviewPackagesStep from "../components/Quote/CustomPackagedPricing/ReviewPackagesStep";
import AddCustomPackageModal from "../components/Quote/CustomPackagedPricing/AddCustomPackageModal";
import CustomPackagesList from "../components/Quote/CustomPackagedPricing/CustomPackagesList";
import RequiredDrivers from "../components/Quote/CustomPackagedPricing/RequiredDrivers";
import {
  Modal, ModalBody
} from 'reactstrap';
import {
  addServiceToALaCartePackage,
  removeServiceFromALaCartePackage,
  setActiveStep,
  previewQuote,
  calculatePackagePrice,
  sendQuotation,
  skipQuotation,
  saveQuotationAsDraft,
  setShowCreateNewQuoteSuccessModal,
  resetCreateNewQuoteForm,
  getQuote,
  addPackagedPricingPackage,
  removePackagedPricingPackage,
  reviewPackagedPricingPackages,
  reviewCustomPackagedPricingPackages,
  getPricingDriversByPackageIds,
  getPricingDriversForCustomPackagedPricing,
  setShowAddCustomPackageModal,
  editAddCustomPackageModal,
} from "../store/quote/actions";
import {
  getServicesByCategory,
  getGlobalDriversByServices,
  getGlobalPricingDriver,
} from "../store/service/actions";
import {
  getMyOrganisation,
  getPricingSettings,
  updatePricingSettings,
  onInputChange as onOrgInputChange,
} from "../store/organisation/actions";
import { getClients } from "../store/client/actions";
import { getTemplates } from "../store/template/actions";
import { getPackages } from "../store/package/actions";
import {
  buildCalculatePricingJson,
  buildGlobalPricingDriversJson,
  getValidator,
  checkPricingDriversValid,
  checkGlobalPricingDriversValid,
  formatCurrency,
} from "../Utils";
import {
  quotePricingOptions,
  paymentFrequencyOptions,
  showFullBreakdownOptions,
  contractSourceOptions,
} from "../config";
import { setShowUpgradeModal } from "../store/common/actions";

class CreateNewQuote extends Component {
  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.reviewPricing = this.reviewPricing.bind(this);
    this.validator = getValidator();
    this.getPDFQuotePreview = this.getPDFQuotePreview.bind(this);
    this.formatDataAndSubmitQuote = this.formatDataAndSubmitQuote.bind(this);
    this.validateCustomPackagedPricingDrivers =
      this.validateCustomPackagedPricingDrivers.bind(this);
    this.validateMaxPackagedPricingPackages =
      this.validateMaxPackagedPricingPackages.bind(this);
    this.getCustomPackagedPricingDrivers =
      this.getCustomPackagedPricingDrivers.bind(this);
    this.renderFeesInProposal = this.renderFeesInProposal.bind(this);
    this.updatePercentageOneOff = this.updatePercentageOneOff.bind(this);
    this.updateOneOffPercentageDiscount =
      this.updateOneOffPercentageDiscount.bind(this);
    this.updatePercentageRecurringPrice =
      this.updatePercentageRecurringPrice.bind(this);
    this.updateRecurringPricePercentageDiscount =
      this.updateRecurringPricePercentageDiscount.bind(this);
    this.customBackStep = this.customBackStep.bind(this);
    this.updateOrganisation = this.updateOrganisation.bind(this);
    this.state = {
      validPricingDrivers: false,
      validCustomPackagedPricingDrivers: false,
      validQuote: false,
      warningSteps: [],
      hasFocused: false,
      hasFocusedOneOff: false,
      flag: this.props.showFullBreakdown,
      discountedPrice: 0,
      hasBlurred: true,
      hasBlurredOneOff: true,
      oneOffDiscountedPrice: 0,
      valid: true,
      oneOffDiscountedPricePercentage: 0,
      recurringPriceDiscountedPricePercentage: 0,
      customPackagedPricingSteps: [
        "Basic Information",
        "Select Packages",
        // "Additional Information",
        "Review Packages",
        "Preview",
      ],
      packagedPricingSteps: [
        "Basic Information",
        "Select Packages",
        "Review Packages",
        "Preview",
      ],
      aLaCartePricingSteps: [
        "Basic Information",
        "Select Services",
        // "Additional Information",
        "Review Services",
        "Preview",
      ],
      currentActiveStep: 0,
      currentDiscountPrice: 0,
      updatedPercentageOnceRecurring: false,
      updatedPercentageOnceOneOff: false,
      currentPerMonthValue: 12,
      showPricingModel: false,
      minPricingState: 0,
      minPricingMonthState: 0,
      maxDiscountState: 0,
      showFullBreakdownOptions: [
        { value: true, label: "Show Full Breakdown", isdisabled: true },
        { value: false, label: "Show Totals Only" },
      ],
      viaSelect: false,
    };
  }
  cancel() {
    this.props.history.goBack();
  }
  nextStep() {
    if (this.props.activeStep === 1) {
      if (
        this.validator.fieldValid("quoteClient") &&
        this.validator.fieldValid("quoteTemplate")
      ) {
        this.setState({
          valid: true,
        });
        this.validator.hideMessages();
        this.props.setActiveStep(this.props.activeStep + 1);
        this.setState({ warningSteps: [] });
      } else {
        this.setState({
          valid: false,
        });
        this.validator.showMessages();
        this.setState({ warningSteps: [1] });
      }
    } else if (this.props.activeStep === 2) {

      if (this.props.activePricingOption === "aLaCartePricing") {
        this.setState({ warningSteps: [] });
        let next = false;
        for (let driver of this.props.aLaCartePricingDrivers) {
          const otherValue = this.props[`${driver}-${this.props[driver]}_other`]
          console.log("this.props 111233 - ", this.props, otherValue, otherValue?.trim().length, this.validator, otherValue && otherValue.trim().length === 0);
          if (typeof otherValue === 'string' && otherValue.trim().length === 0) {
            console.log("next - ", false);
            next = false;
            break;
          }
          if (this.props[`${driver}`]) {
            next = true;
          } else {
            next = false;
            break;
          }
        }
        if (this.props.aLaCartePricingDrivers.length === 0) {
          next = true;
        }
        if (this.props.aLaCarteServices.length > 0) {
          console.log("this.props 111233 - ", next);
          if (next) {
            this.props.setActiveStep(this.props.activeStep + 1);
            this.setState({ warningSteps: [] });
          } else {
            this.setState({
              valid: false,
            });
            this.setState({ warningSteps: [2] });
            this.validator.showMessages();
          }
        } else {
          this.setState({
            valid: false,
          });
          this.setState({ warningSteps: [2] });
        }
      } else if (
        this.props.activePricingOption === "packagedPricing" ||
        this.props.activePricingOption === "customPackagedPricing"
      ) {
        if (
          Object.keys(this.props.customPackages).length +
          this.props.packagedPricingSelectedPackages.length ===
          0 ||
          Object.keys(this.props.customPackages).length +
          this.props.packagedPricingSelectedPackages.length >
          3
        ) {
          this.setState({
            valid: false,
          });
          this.setState({ warningSteps: [2] });
          this.validator.showMessages();
        } else {
          this.setState({
            valid: true,
          });
          this.validator.hideMessages();
          this.props.setActiveStep(this.props.activeStep + 1);
          this.setState({ warningSteps: [] });
        }
      } else {
        this.setState({
          valid: true,
        });
        this.validator.hideMessages();
        this.props.setActiveStep(this.props.activeStep + 1);
        this.setState({ warningSteps: [] });
      }
    } else if (this.props.activeStep === 3 || this.props.activeStep === 4) {
      console.log("this.validator - ", this.validator, this.props);
      const isCheckPricingStep =
        this.state.aLaCartePricingSteps.length === 5 ? 4 : 3;
      const isCheckPricing = isCheckPricingStep === this.props.activeStep;
      if (this.props.activePricingOption === "aLaCartePricing") {
        let next = false;
        for (let driver of this.props.requiredGlobalPricingDrivers) {
          if (this.props[`globalPricingDriver-${driver._id}`]) {
            next = true;
          } else {
            next = false;
            break;
          }
        }
        next =
          this.props.requiredGlobalPricingDrivers.length === 0 ? true : next;

        if (!next) {
          this.setState({
            valid: false,
          });
          this.setState({ warningSteps: [3] });
          this.validator.showMessages();
        } else {
          if (isCheckPricing) {
            if (
              (this.props.maxDiscount &&
                this.props.maxDiscount > 0 &&
                this.props.recurringTotal > 0
                ? this.validator.fields["packageRecurringPricePercentage"]
                : true) &&
              (this.props.maxDiscount &&
                this.props.maxDiscount > 0 &&
                this.props.oneOffTotal > 0
                ? this.validator.fields["packageOneOffPricePercentage"]
                : true) &&
              (this.props.minPricingMonth &&
                this.props.minPricingMonth > 0 &&
                this.props.recurringTotal > 0
                ? this.validator.fields["packageRecurringPrice"]
                : true) &&
              (this.props.minPricing &&
                this.props.minPricing > 0 &&
                this.props.oneOffTotal > 0
                ? this.validator.fields["packageOneOffPrice"]
                : true)
            ) {
              this.setState({
                valid: true,
                validQuote: true
              });
              this.validator.hideMessages();
              this.props.setActiveStep(this.props.activeStep + 1);
              this.setState({ warningSteps: [] });
            } else {
              this.setState({
                valid: false,
              });
              this.setState({ warningSteps: [isCheckPricingStep] });
              this.validator.showMessages();
            }
          } else {
            this.setState({
              valid: true,
            });
            this.validator.hideMessages();
            this.props.setActiveStep(this.props.activeStep + 1);
            this.setState({ warningSteps: [] });
          }
        }
      } else if (this.props.activePricingOption === "customPackagedPricing") {
        let next = false;
        for (let driver of this.props.customPackagedPricingDrivers) {
          if (this.props[driver]) {
            next = true;
          } else {
            next = false;
            break;
          }
        }
        next =
          this.props.customPackagedPricingDrivers.length === 0 ? true : next;

        if (!next) {
          this.setState({
            valid: false,
          });
          this.setState({ warningSteps: [3] });
          this.validator.showMessages();
        } else {
          if (isCheckPricing) {
            if (
              (this.props.maxDiscount &&
                this.props.maxDiscount > 0 &&
                this.props.recurringTotal > 0
                ? this.validator.fields["packageRecurringPricePercentage"]
                : true) &&
              (this.props.maxDiscount &&
                this.props.maxDiscount > 0 &&
                this.props.oneOffTotal > 0
                ? this.validator.fields["packageOneOffPricePercentage"]
                : true) &&
              (this.props.minPricingMonth &&
                this.props.minPricingMonth > 0 &&
                this.props.recurringTotal > 0
                ? this.validator.fields["packageRecurringPrice"]
                : true) &&
              (this.props.minPricing &&
                this.props.minPricing > 0 &&
                this.props.oneOffTotal > 0
                ? this.validator.fields["packageOneOffPrice"]
                : true)
            ) {
              this.setState({
                valid: true,
              });
              this.validator.hideMessages();
              this.props.setActiveStep(this.props.activeStep + 1);
              this.setState({ warningSteps: [] });
            } else {
              this.setState({
                valid: false,
              });
              this.setState({ warningSteps: [isCheckPricingStep] });
              this.validator.showMessages();
            }
          } else {
            this.setState({
              valid: true,
            });
            this.validator.hideMessages();
            this.props.setActiveStep(this.props.activeStep + 1);
            this.setState({ warningSteps: [] });
          }
        }
      } else {
        this.setState({
          valid: true,
        });
        this.validator.hideMessages();
        this.props.setActiveStep(this.props.activeStep + 1);
        this.setState({ warningSteps: [] });
      }
    } else {
      this.setState({
        valid: true,
      });
      this.validator.hideMessages();
      this.props.setActiveStep(this.props.activeStep + 1);
    }
  }
  backStep() {
    this.setState({ warningSteps: [] });
    if (this.props.activeStep - 1 == 2) {
      this.props.onInputChange('packageRecurringPrice', 0);
    }
    this.props.setActiveStep(this.props.activeStep - 1);
  }
  customBackStep(stepNumber) {
    this.setState({ warningSteps: [] });
    this.props.setActiveStep(stepNumber);
  }
  renderFeesInProposal() {
    return (
      <React.Fragment>
        <div className="row fieldset">
          <div className="col-2 text-right">
            <label className="fieldset-label">Fees in the Proposal</label>
          </div>
          <div className="col-10">
            <Select
              options={[
                { value: true, label: "Show Full Breakdown", isdisabled: this.props.packageRecurringPrice > this.props.recurringTotal || this.props.packageOneOffPrice > this.props.oneOffTotal },
                { value: false, label: "Show Totals Only" },
              ]}
              onChange={(selectedOption) => {
                this.props.onInputChange(
                  "showFullBreakdown",
                  selectedOption.value
                );
                if (selectedOption.value && this.props.recurringTotal < this.props.packageRecurringPrice) {
                  this.props.onInputChange(
                    "packageRecurringPrice",
                    this.props.recurringTotal,
                  );
                }
                this.props.onInputChange(
                  this.setState({
                    flag: selectedOption.value === true ? true : false,
                  })
                );
              }}
              value={this.state.showFullBreakdownOptions.filter(
                (option) => option.value === this.props.showFullBreakdown
              )}
              isOptionDisabled={(option) => option.isdisabled}
            />
          </div>
        </div>
        <div className="separator mb-2" />
      </React.Fragment>
    );
  }
  reviewPricing(globalPricingBlank = false, paymentFrequency = null) {
    this.setState({ hasFocused: false });
    let services = {};
    let globalDriversJson = {};
    if (this.props.activePricingOption === "aLaCartePricing") {
      services = buildCalculatePricingJson(
        this.props.aLaCarteServices,
        this.props.servicesByCategory,
        this.props.aLaCartePricingDrivers,
        this.props,
        this.props.paymentFrequency,
        this.props.packageRecurringPrice,
      );
      globalDriversJson = buildGlobalPricingDriversJson(
        this.props.requiredGlobalPricingDrivers,
        this.props
      );
    }

    this.props.calculatePackagePrice(
      services,
      globalPricingBlank ? {} : globalDriversJson,
      this.props.tempDiscountedPrice > 0 || this.props.packageRecurringPrice > this.props.recurringTotal ? this.props.packageRecurringPrice : null,
      this.state.viaSelect,
      paymentFrequency,
      this.props.tempDiscountedPriceOneOff > 0 || this.props.packageOneOffPrice > this.props.oneOffTotal ? this.props.packageOneOffPrice : null,
    );
  }
  validateCustomPackagedPricingDrivers() {
    let valid = true;
    if (this.props.customPackagedPricingDrivers) {
      for (let item of this.props.customPackagedPricingDrivers) {
        if (!this.validator.fields[item]) {
          valid = false;
          break;
        }
      }
    }
    return valid;
  }

  validateMinPackagedPricingPackages() {
    return Object.keys(this.props.customPackages).length +
      this.props.packagedPricingSelectedPackages.length ==
      0 && this.props.activeStep > 1
      ? false
      : true;
  }
  validateMaxPackagedPricingPackages() {
    return Object.keys(this.props.customPackages).length +
      this.props.packagedPricingSelectedPackages.length >
      3 && this.props.activeStep > 1
      ? false
      : true;
  }

  validateQuote() {
    if (
      (this.props.activePricingOption === "aLaCartePricing" &&
        checkPricingDriversValid(
          this.props.aLaCartePricingDrivers,
          this.props.servicesByCategory,
          this.validator,
          this.props
        ) &&
        (this.props.activeStep >= 3
          ? checkGlobalPricingDriversValid(
            this.props.requiredGlobalPricingDrivers,
            this.validator,
            this.props
          )
          : true) &&
        this.validator.fields["quoteClient"] &&
        this.validator.fields["quoteTemplate"] &&
        (this.props.activeStep >= 4
          ? (this.props.maxDiscount &&
            this.props.maxDiscount > 0 &&
            this.props.recurringTotal > 0
            ? this.validator.fields["packageRecurringPricePercentage"]
            : true) &&
          (this.props.maxDiscount &&
            this.props.maxDiscount > 0 &&
            this.props.oneOffTotal > 0
            ? this.validator.fields["packageOneOffPricePercentage"]
            : true) &&
          (this.props.minPricingMonth &&
            this.props.minPricingMonth > 0 &&
            this.props.recurringTotal > 0
            ? this.validator.fields["packageRecurringPrice"]
            : true) &&
          (this.props.minPricing &&
            this.props.minPricing > 0 &&
            this.props.oneOffTotal > 0
            ? this.validator.fields["packageOneOffPrice"]
            : true)
          : true)) ||
      (this.props.activePricingOption === "packagedPricing" &&
        this.validator.fields["quoteClient"] &&
        this.validator.fields["quoteTemplate"] &&
        this.validateMinPackagedPricingPackages() &&
        this.validateMaxPackagedPricingPackages()) ||
      (this.props.activePricingOption === "customPackagedPricing" &&
        this.validator.fields["quoteClient"] &&
        this.validator.fields["quoteTemplate"] &&
        this.validateCustomPackagedPricingDrivers() &&
        this.validateMinPackagedPricingPackages() &&
        this.validateMaxPackagedPricingPackages())
    ) {
      return true;
    } else {
      return false;
    }
  }
  updateStepperWarnings() {
    console.log("2");
  }
  getPDFQuotePreview() {
    const now = new Date();
    const previewPdfData = {
      type: this.props.activePricingOption,
      client: this.props.quoteClient,
      template: this.props.quoteTemplate,
      paymentFrequency: this.props.paymentFrequency,
      showFullBreakdown: this.props.showFullBreakdown,
      creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
    };
    if (this.props.activePricingOption === "aLaCartePricing") {
      previewPdfData.selectedServices = this.props.calculatedPackagePrice;
      // previewPdfData.packageRecurringPrice =
      //   this.props.packageRecurringPrice || this.props.recurringTotal;
      // previewPdfData.packageOneOffPrice =
      //   this.props.packageOneOffPrice || this.props.oneOffTotal;
      previewPdfData.packageRecurringPrice = this.props.packageRecurringPrice;
      previewPdfData.packageOneOffPrice = this.props.packageOneOffPrice;
      previewPdfData.globalPricingDrivers = buildGlobalPricingDriversJson(
        this.props.requiredGlobalPricingDrivers,
        this.props
      );
    } else if (this.props.activePricingOption === "packagedPricing") {
      previewPdfData.packagedPricingServicesByCategory =
        this.props.reviewPackagedPricingServicesByCategory;
      previewPdfData.packagedPricingSelectedPackages =
        this.props.reviewPackagedPricingSelectedPackages;
      previewPdfData.packagedPricingGlobalPricingDrivers =
        this.props.reviewPackagedPricingGlobalPricingDrivers;
      let tempGlobalPricingDriverIds = [];
      for (let key in previewPdfData.packagedPricingGlobalPricingDrivers) {
        for (let driver in previewPdfData.packagedPricingGlobalPricingDrivers[
          key
        ]) {
          tempGlobalPricingDriverIds.push({
            ...previewPdfData.packagedPricingGlobalPricingDrivers[key][driver],
            _id: driver,
          });
        }
      }
      previewPdfData.globalPricingDrivers = buildGlobalPricingDriversJson(
        tempGlobalPricingDriverIds,
        this.props
      );
    } else if (this.props.activePricingOption === "customPackagedPricing") {
      previewPdfData.packagedPricingServicesByCategory =
        this.props.reviewPackagedPricingServicesByCategory;
      previewPdfData.packagedPricingSelectedPackages =
        this.props.reviewPackagedPricingSelectedPackages;
      previewPdfData.globalPricingDrivers = buildGlobalPricingDriversJson(
        this.props.requiredGlobalPricingDrivers,
        this.props
      );
      previewPdfData.customPackages = this.props.customPackages;
      previewPdfData.customPackagedPricingDrivers =
        this.getCustomPackagedPricingDrivers();
      // this.props.reviewCustomPackagedPricingPackages(this.props.packagedPricingSelectedPackages, this.props.customPackages, this.getCustomPackagedPricingDrivers());
    }
    this.props.previewQuote(previewPdfData);
  }
  formatDataAndSubmitQuote(action) {
    // reviewPricing.call(this);
    // force user to show review service before submit
    if (this.validateQuote()) {
      if (action === "send") {
        const should_allow = this.props.allow_send_quote;
        if (!should_allow) return this.props.setShowUpgradeModal(true);

        const now = new Date();
        const data = {
          client: this.props.quoteClient,
          template: this.props.quoteTemplate,
          type: this.props.activePricingOption,
          paymentFrequency: this.props.paymentFrequency,
          showFullBreakdown: this.props.showFullBreakdown,
          creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
        };
        if (this.props.activePricingOption === "aLaCartePricing") {
          data.selectedServices = this.props.calculatedPackagePrice;
          // data.packageRecurringPrice =
          //   this.state.discountedPrice || this.props.packageRecurringPrice || this.props.recurringTotal;
          // data.packageOneOffPrice =
          //   this.props.packageOneOffPrice || this.props.oneOffTotal;
          data.packageRecurringPrice = this.props.packageRecurringPrice;
          data.packageOneOffPrice = this.props.packageOneOffPrice;
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
          data.oneOffDiscountedPricePercentage = this.props.tempDiscountedPriceOneOff;
          data.recurringPriceDiscountedPricePercentage = this.props.tempDiscountedPrice;
        } else if (this.props.activePricingOption === "packagedPricing") {
          data.packagedPricingServicesByCategory =
            this.props.reviewPackagedPricingServicesByCategory;
          data.packagedPricingSelectedPackages =
            this.props.reviewPackagedPricingSelectedPackages;
          data.packagedPricingGlobalPricingDrivers =
            this.props.reviewPackagedPricingGlobalPricingDrivers;
          let tempGlobalPricingDriverIds = [];
          for (let key in data.packagedPricingGlobalPricingDrivers) {
            for (let driver in data.packagedPricingGlobalPricingDrivers[key]) {
              tempGlobalPricingDriverIds.push({
                ...data.packagedPricingGlobalPricingDrivers[key][driver],
                _id: driver,
              });
            }
          }
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            tempGlobalPricingDriverIds,
            this.props
          );
        } else if (this.props.activePricingOption === "customPackagedPricing") {
          // data.packagedPricingServicesByCategory = this.props.reviewPackagedPricingServicesByCategory;
          // data.packagedPricingSelectedPackages = this.props.reviewPackagedPricingSelectedPackages;
          data.packagedPricingServicesByCategory =
            this.props.reviewPackagedPricingServicesByCategory;
          data.packagedPricingSelectedPackages =
            this.props.reviewPackagedPricingSelectedPackages;
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
          data.customPackages = this.props.customPackages;
          data.customPackagedPricingDrivers =
            this.getCustomPackagedPricingDrivers();
        }
        if (this.type === "new") {
          data.isNew = true;
        } else if (this.type === "edit") {
          data.isNew = false;
          data.id = this.props.quoteId;
        }
        this.props.sendQuotation(data);
      } else if (action === "draft") {
        const data = {
          client: this.props.quoteClient,
          template: this.props.quoteTemplate,
          type: this.props.activePricingOption,
          showFullBreakdown: this.props.showFullBreakdown,
          paymentFrequency: this.props.paymentFrequency,
        };
        if (this.props.activePricingOption === "aLaCartePricing") {
          data.tempDiscountedPrice = this.props.tempDiscountedPrice;
          data.tempDiscountedPriceOneOff = this.props.tempDiscountedPriceOneOff;
          data.tempSelectedServices = this.props.aLaCarteServices;
          data.tempPricingDrivers = this.props.tempPricingDrivers;
          data.selectedServices = buildCalculatePricingJson(
            this.props.aLaCarteServices,
            this.props.servicesByCategory,
            this.props.aLaCartePricingDrivers,
            this.props,
            this.props.paymentFrequency,
            this.props.packageRecurringPrice
          );
          data.packageRecurringPrice =
            this.props.packageRecurringPrice;
          data.packageOneOffPrice =
            this.props.packageOneOffPrice;
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
          data.oneOffDiscountedPricePercentage = this.props.tempDiscountedPriceOneOff;
          data.recurringPriceDiscountedPricePercentage = this.props.tempDiscountedPrice;
        } else if (this.props.activePricingOption === "packagedPricing") {
          // data.packagedPricingServicesByCategory = this.props.reviewPackagedPricingServicesByCategory;
          // data.packagedPricingSelectedPackages = this.props.reviewPackagedPricingSelectedPackages;
          // data.packagedPricingSelectedPackages = this.props.packagedPricingSelectedPackages;
          data.packagedPricingSelectedPackages =
            this.props.packagedPricingSelectedPackages.reduce((acc, cur) => {
              acc[cur] = 1;
              return acc;
            }, {});
        } else if (this.props.activePricingOption === "customPackagedPricing") {
          // data.packagedPricingServicesByCategory = this.props.reviewPackagedPricingServicesByCategory;
          // data.packagedPricingSelectedPackages = this.props.reviewPackagedPricingSelectedPackages;
          // data.packagedPricingServicesByCategory = this.props.reviewPackagedPricingServicesByCategory;
          // data.packagedPricingSelectedPackages = this.props.packagedPricingSelectedPackages;
          data.packagedPricingSelectedPackages =
            this.props.packagedPricingSelectedPackages.reduce((acc, cur) => {
              acc[cur] = 1;
              return acc;
            }, {});
          data.customPackages = this.props.customPackages;
          data.customPackagedPricingDrivers =
            this.getCustomPackagedPricingDrivers();
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
        }
        if (this.type === "new") {
          data.isNew = true;
        } else if (this.type === "edit") {
          data.isNew = false;
          data.id = this.props.quoteId;
        }
        // console.log("spec_check_draft", data);
        this.props.saveQuotationAsDraft(data);
      } else if (action === "skip") {
        const should_allow = this.props.allow_send_quote;
        if (!should_allow) return this.props.setShowUpgradeModal(true);

        const now = new Date();
        const data = {
          skip: true,
          client: this.props.quoteClient,
          template: this.props.quoteTemplate,
          type: this.props.activePricingOption,
          paymentFrequency: this.props.paymentFrequency,
          showFullBreakdown: this.props.showFullBreakdown,
          creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
        };
        if (this.props.activePricingOption === "aLaCartePricing") {
          data.selectedServices = this.props.calculatedPackagePrice;
          data.packageRecurringPrice =
            this.props.packageRecurringPrice || this.props.recurringTotal;
          data.packageOneOffPrice =
            this.props.packageOneOffPrice || this.props.oneOffTotal;
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
          data.oneOffDiscountedPricePercentage = this.props.tempDiscountedPriceOneOff;
          data.recurringPriceDiscountedPricePercentage = this.props.tempDiscountedPrice;
        } else if (this.props.activePricingOption === "packagedPricing") {
          data.packagedPricingServicesByCategory =
            this.props.reviewPackagedPricingServicesByCategory;
          data.packagedPricingSelectedPackages =
            this.props.reviewPackagedPricingSelectedPackages;
          data.packagedPricingGlobalPricingDrivers =
            this.props.reviewPackagedPricingGlobalPricingDrivers;
          let tempGlobalPricingDriverIds = [];
          for (let key in data.packagedPricingGlobalPricingDrivers) {
            for (let driver in data.packagedPricingGlobalPricingDrivers[key]) {
              tempGlobalPricingDriverIds.push({
                ...data.packagedPricingGlobalPricingDrivers[key][driver],
                _id: driver,
              });
            }
          }
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            tempGlobalPricingDriverIds,
            this.props
          );
        } else if (this.props.activePricingOption === "customPackagedPricing") {
          // data.packagedPricingServicesByCategory = this.props.reviewPackagedPricingServicesByCategory;
          // data.packagedPricingSelectedPackages = this.props.reviewPackagedPricingSelectedPackages;
          data.packagedPricingServicesByCategory =
            this.props.reviewPackagedPricingServicesByCategory;
          data.packagedPricingSelectedPackages =
            this.props.reviewPackagedPricingSelectedPackages;
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
          data.customPackages = this.props.customPackages;
          data.customPackagedPricingDrivers =
            this.getCustomPackagedPricingDrivers();
        }
        if (this.type === "new") {
          data.isNew = true;
        } else if (this.type === "edit") {
          data.isNew = false;
          data.id = this.props.quoteId;
        }
        this.props.skipQuotation(data, this.props.history);
      }
    } else {
      this.validator.showMessages();
      this.updateStepperWarnings();
      this.forceUpdate();
    }
  }
  getCustomPackagedPricingDrivers() {
    let drivers = {};
    for (let driver of this.props.customPackagedPricingDrivers) {
      drivers[driver.replace("customPackagedPricingDriver-", "")] =
        this.props[driver];
      if (this.props[`${driver}-${this.props[driver]}_other`]) {
        drivers[
          `${driver.replace("customPackagedPricingDriver-", "")}-${this.props[driver]
          }_other`
        ] = this.props[`${driver}-${this.props[driver]}_other`];
      }
    }
    return drivers;
  }
  updatePercentageOneOff(e) {
    const reg = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)+$/;
    if (!reg.test(e.target.value) && e.target.value != "") return;
    if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
      return false;
    }
    if (e.target.value < 0 || e.target.value > 100) return;
    this.setState({
      oneOffDiscountedPricePercentage: e.target.value,
      updatedPercentageOnceOneOff: true,
    });
    this.props.onInputChange('tempDiscountedPriceOneOff', e.target.value);
    const total = parseFloat(
      (this.props.oneOffTotal * e.target.value) / 100
    ).toFixed(2);
    this.setState({
      currentDiscountPrice: total,
    });
    if (this.props.oneOffTotal - total > this.props.oneOffTotal) {
      this.props.onInputChange("showFullBreakdown", false);
      // this.props.onInputChange(
      //   this.setState({
      //     flag: false,
      //   })
      // );
    } else {
      if (this.props.recurringTotal >= this.props.packageRecurringPrice) {
        this.props.onInputChange("showFullBreakdown", true);
      }
      // this.props.onInputChange(
      //   this.setState({
      //     flag: true,
      //   })
      // );
    }
    this.props.onInputChange(
      "packageOneOffPrice",
      parseFloat(this.props.oneOffTotal - total).toFixed(2)
    );
  }
  updateOneOffPercentageDiscount() {
    const discount = parseFloat(
      ((this.props.oneOffTotal - this.props.packageOneOffPrice) * 100) /
      this.props.oneOffTotal
    ).toFixed(2);
    this.setState({
      currentDiscountPrice:
        this.props.oneOffTotal - this.props.packageOneOffPrice,
    });
    this.setState({
      oneOffDiscountedPricePercentage: discount < 0 ? 0 : discount,
    });
    this.props.onInputChange('tempDiscountedPriceOneOff', discount < 0 ? 0 : discount);
  }

  updatePercentageRecurringPrice(e) {
    const reg = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)+$/;
    if (!reg.test(e.target.value) && e.target.value != "") return;
    if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
      return false;
    }
    if (e.target.value > 100) return;
    if (e.target.value == '' || e.target.value < 0) e.target.value = 0;
    this.setState({
      recurringPriceDiscountedPricePercentage: e.target.value,
      updatedPercentageOnceRecurring: true,
    });
    this.props.onInputChange('tempDiscountedPrice', e.target.value);
    const total = parseFloat(
      (this.props.recurringTotal * e.target.value) / 100
    ).toFixed(2);
    this.setState({
      currentDiscountPrice: total,
    });
    if (this.props.recurringTotal - total > this.props.recurringTotal) {
      this.props.onInputChange("showFullBreakdown", false);
      // this.props.onInputChange(
      //   this.setState({
      //     flag: false,
      //   })
      // );
    } else {
      if (this.props.oneOffTotal >= this.props.packageOneOffPrice) {
        this.props.onInputChange("showFullBreakdown", true);
      }
      // this.props.onInputChange(
      //   this.setState({
      //     flag: true,
      //   })
      // );
    }
    this.props.onInputChange(
      "packageRecurringPrice",
      parseFloat(this.props.recurringTotal - total).toFixed(2)
    );
    this.setState({
      discountedPrice: parseFloat(this.props.recurringTotal - total).toFixed(2),
    });
  }
  updateRecurringPricePercentageDiscount() {
    const discount = parseFloat(
      ((this.props.recurringTotal - this.props.packageRecurringPrice) * 100) /
      this.props.recurringTotal
    ).toFixed(2);
    this.setState({
      currentDiscountPrice:
        this.props.recurringTotal - this.props.packageRecurringPrice,
    });
    this.setState({
      recurringPriceDiscountedPricePercentage: discount < 0 ? 0 : discount,
    });
    this.props.onInputChange('tempDiscountedPrice', discount < 0 ? 0 : discount);
  }
  componentDidMount() {
    this.props.getMyOrganisation();
    this.props.getPricingSettings();
    this.props.getServicesByCategory();
    this.props.getClients(-1);
    this.props.getTemplates({ type: "quote,proposal", });
    if (this.type === "new") {
      // this.props.resetCreateNewPackageForm();
      this.props.resetCreateNewQuoteForm();
    } else if (this.type === "edit") {
      this.props.resetCreateNewQuoteForm();
      this.props.getQuote(this.props.match.params.id);
    }
  }
  componentWillUnmount() {
    this.props.setActiveStep(1);
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(
      "this.props 190900 - ",
      this.props
    );
    let updateStepperWarnings = false;
    if (
      (this.props.loading !== prevProps.loading ||
        this.props.clientLoading !== prevProps.clientLoading) &&
      !this.props.loading &&
      !this.props.clientLoading
    ) {
      let client;
      for (let item of this.props.clients) {
        if (item._id === this.props.quoteClient) {
          this.props.onInputChange(
            "quoteClientBusinessType",
            item.businessType
          );
          break;
        }
      }
    }
    if (this.props.activeStep == 1 && this.props.quoteStatus == 'draft' && parseFloat(this.props.packageRecurringPrice).toFixed(2) != parseFloat(this.state.discountedPrice).toFixed(2)) {
      this.setState({
        discountedPrice: parseFloat(this.props.packageRecurringPrice).toFixed(2)
      })
    }
    if (
      prevProps.activeStep !== 3 &&
      this.props.activeStep === 3 &&
      this.props.activePricingOption === "aLaCartePricing"
    ) {
      this.props.getGlobalDriversByServices(this.props.aLaCarteServices);
    }
    if (
      ((prevProps.activeStep !== 5 && this.props.activeStep === 5) ||
        (prevProps.activeStep !== 4 && this.props.activeStep === 4) ||
        this.props.paymentFrequency !== prevProps.paymentFrequency) &&
      this.props.activePricingOption === "aLaCartePricing" &&
      (this.props.requiredGlobalPricingDrivers.length > 0 ||
        this.props.aLaCartePricingDrivers.length > 0)
    ) {
      console.log("this.props - ", this.props, this.validator);
      if (
        checkPricingDriversValid(
          this.props.aLaCartePricingDrivers,
          this.props.servicesByCategory,
          this.validator,
          this.props
        ) &&
        checkGlobalPricingDriversValid(
          this.props.requiredGlobalPricingDrivers,
          this.validator,
          this.props
        )
      ) {
        if (this.props.paymentFrequency != prevProps.paymentFrequency) {
          this.reviewPricing(false, prevProps.paymentFrequency);
        } else {
          this.reviewPricing();
        }
        console.log("validPricingDrivers - ", true);
        this.setState({ validPricingDrivers: true });
      } else {
        this.setState({ validPricingDrivers: false });
      }
      updateStepperWarnings = true;
    } else {
      if (prevProps.activeStep !== 3 && this.props.activeStep == 3) {
        this.reviewPricing(true, this.props.paymentFrequency);
        this.setState({ validPricingDrivers: true });
      } else if (this.props.paymentFrequency !== prevProps.paymentFrequency) {
        this.reviewPricing(false, prevProps.paymentFrequency);
      }
    }
    if (
      this.props.recurringTotal !== prevProps.recurringTotal &&
      this.state.viaSelect
    ) {
      // if (this.props.tempDiscountedPrice > 0) {
      //   const newRecurringValue = this.props.recurringTotal;
      //   const discountedVal = this.props.tempDiscountedPrice > 0 ?
      //     (newRecurringValue * this.props.tempDiscountedPrice) / 100 : 0;
      //   this.props.onInputChange(
      //     "packageRecurringPrice",
      //     parseFloat(newRecurringValue - discountedVal).toFixed(2),
      //   );
      //   // this.props.onInputChange("tempDiscountedPrice", 0);
      //   this.setState({ discountedPrice: parseFloat(newRecurringValue - discountedVal).toFixed(2) });
      // } else {
      //   const newRecurringValue = this.props.packageRecurringPrice || this.props.recurringTotal;
      //   const discountedVal = this.props.tempDiscountedPrice > 0 ?
      //     (newRecurringValue * this.props.tempDiscountedPrice) / 100 : 0;
      //   let objDivision = {
      //     yearly: 1,
      //     halfYearly: 2,
      //     quarterly: 4,
      //     monthly: 12,
      //   };
      //   if (discountedVal > 0) {
      //     const updatedValue = (newRecurringValue - discountedVal) / objDivision[this.props.paymentFrequency];
      //     this.props.onInputChange(
      //       "packageRecurringPrice",
      //       parseFloat(updatedValue).toFixed(2),
      //     );
      //     this.setState({ discountedPrice: parseFloat(updatedValue).toFixed(2) });
      //   } else {
      //     if (parseFloat(this.props.packageRecurringPrice).toFixed(2) > parseFloat(this.props.recurringTotal).toFixed(2) && this.props.recurringTotal > 0) {
      //       this.setState({
      //         discountedPrice: parseFloat(this.props.packageRecurringPrice).toFixed(2)
      //       })

      //     } else {
      //       this.props.onInputChange(
      //         "packageRecurringPrice",
      //         parseFloat(this.props.recurringTotal).toFixed(2),
      //       );
      //       this.props.onInputChange("tempDiscountedPrice", 0);
      //       this.setState({ discountedPrice: parseFloat(this.props.recurringTotal).toFixed(2) });
      //     }
      //   }
      // }
      let obj = {
        yearly: 12,
        halfYearly: 6,
        quarterly: 3,
        monthly: 1,
      };
      const currentPerMonthValue = obj[
        this.props.paymentFrequency
      ];
      this.setState({ viaSelect: false, currentPerMonthValue });
    }
    if (
      this.props.recurringTotal !== prevProps.recurringTotal &&
      !this.state.viaSelect && this.props.paymentFrequency.length >= 1
    ) {
      let obj = {
        yearly: 12,
        halfYearly: 6,
        quarterly: 3,
        monthly: 1,
      };
      const currentPerMonthValue = obj[
        this.props.paymentFrequency
      ];
      this.setState({ currentPerMonthValue });
      if (this.props.tempDiscountedPrice > 0) {
        const discountedPrice = parseFloat(this.props.recurringTotal - (this.props.recurringTotal * this.props.tempDiscountedPrice) / 100).toFixed(2);
        this.props.onInputChange('packageRecurringPrice', discountedPrice);
      }
      // if (this.props.recurringTotal > this.props.packageRecurringPrice) {
      //   const discountVal = ((this.props.recurringTotal - this.props.packageRecurringPrice) * 100) / this.props.recurringTotal;
      //   if (parseFloat(discountVal).toFixed(2) != parseFloat(this.props.tempDiscountedPrice).toFixed(2)) {
      //     this.setState({
      //       recurringPriceDiscountedPricePercentage: parseFloat(discountVal).toFixed(2),
      //       discountedPrice: this.props.packageRecurringPrice
      //     });
      //     this.props.onInputChange('tempDiscountedPrice', parseFloat(discountVal).toFixed(2));
      //   }
      // }
    }
    // if (this.props.oneOffTotal !== prevProps.oneOffTotal && this.props.oneOffTotal == 0 && this.props.packageOneOffPrice > 0) {
    //   this.props.onInputChange('packageOneOffPrice', 0);
    //   this.props.onInputChange('tempDiscountedPriceOneOff', 0);
    // }
    if (this.props.oneOffTotal !== prevProps.oneOffTotal || this.props.oneOffTotal == 0 && this.props.packageOneOffPrice > 0) {
      
      const discountVal = ((this.props.oneOffTotal - this.props.packageOneOffPrice) * 100) / this.props.oneOffTotal;
      const discountedPrice = parseFloat(this.props.oneOffTotal - (this.props.oneOffTotal * this.props.tempDiscountedPriceOneOff) / 100).toFixed(2);
      if (parseFloat(discountVal).toFixed(2) != parseFloat(this.props.tempDiscountedPriceOneOff).toFixed(2)) {
        this.setState({
          oneOffDiscountedPricePercentage: parseFloat(discountVal).toFixed(2),
        });
        this.props.onInputChange('packageOneOffPrice', discountedPrice);
        this.props.onInputChange('tempDiscountedPriceOneOff', 0);
      }
    }

    // if (this.props.oneOffTotal !== prevProps.oneOffTotal && this.props.tempDiscountedPriceOneOff > 0) {
    //   const discountedPrice = parseFloat(this.props.oneOffTotal - (this.props.oneOffTotal * this.props.tempDiscountedPriceOneOff) / 100).toFixed(2);
    //   this.props.onInputChange('packageOneOffPrice', discountedPrice);
    // }
    if (
      (prevProps.activeStep !== 4 &&
        this.props.activeStep === 4 &&
        this.props.activePricingOption === "packagedPricing") ||
      (prevProps.activeStep !== 5 &&
        this.props.activeStep === 5 &&
        (this.props.activePricingOption === "aLaCartePricing" ||
          this.props.activePricingOption === "customPackagedPricing"))
    ) {
      if (this.validateQuote()) {
        this.getPDFQuotePreview();
        this.setState({ validQuote: true });
      } else {
        this.validator.showMessages();
        this.setState({ validQuote: false });
      }
      updateStepperWarnings = true;
    }
    if (
      (this.props.requiredGlobalPricingDrivers.length <= 0 &&
        this.props.activePricingOption === "aLaCartePricing" &&
        prevProps.activeStep !== 4 &&
        this.props.activeStep === 4) ||
      (this.props.requiredGlobalPricingDrivers.length > 0 &&
        this.props.activePricingOption === "aLaCartePricing" &&
        prevProps.activeStep == 4 &&
        this.props.activeStep == 5) ||
      (this.props.activePricingOption === "customPackagedPricing" &&
        prevProps.activeStep !== 4 &&
        this.props.activeStep === 4 &&
        (!this.props.customPackagedPricingDriversServicesByCategory ||
          Object.keys(this.props.customPackagedPricingDriversServicesByCategory)
            .length <= 0)) ||
      (prevProps.activeStep !== 4 &&
        this.props.activeStep === 4 &&
        this.props.activePricingOption === "packagedPricing" &&
        (!this.props.customPackagedPricingDriversServicesByCategory ||
          Object.keys(this.props.customPackagedPricingDriversServicesByCategory)
            .length <= 0))
    ) {
      if (this.validateQuote()) {
        this.getPDFQuotePreview();
        this.setState({ validQuote: true });
      } else {
        this.validator.showMessages();
        this.setState({ validQuote: false });
      }
      updateStepperWarnings = true;
    }
    if (
      (prevProps.activePricingOption !== "packagedPricing" &&
        this.props.activePricingOption === "packagedPricing") ||
      (prevProps.activePricingOption !== "customPackagedPricing" &&
        this.props.activePricingOption === "customPackagedPricing")
    ) {
      this.props.getPackages();
    }
    if (
      this.props.activePricingOption === "packagedPricing" &&
      ((prevProps.activeStep !== 3 && this.props.activeStep === 3) ||
        (this.props.paymentFrequency !== prevProps.paymentFrequency &&
          this.props.activeStep === 3))
    ) {
      this.props.reviewPackagedPricingPackages(
        this.props.packagedPricingSelectedPackages,
        this.props.paymentFrequency
      );
    }
    if (
      this.props.activePricingOption === "customPackagedPricing" &&
      ((prevProps.activeStep !== 4 && this.props.activeStep === 4) ||
        (
          prevProps.activeStep !== 3 && this.props.activeStep === 3 &&
          this.props.requiredGlobalPricingDrivers.length === 0
        ) ||
        (this.props.paymentFrequency !== prevProps.paymentFrequency &&
          this.props.activeStep === 4))
    ) {
      if (this.validateCustomPackagedPricingDrivers()) {
        this.props.reviewCustomPackagedPricingPackages(
          this.props.packagedPricingSelectedPackages,
          this.props.customPackages,
          this.getCustomPackagedPricingDrivers(),
          buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          ),
          this.props.paymentFrequency
        );
        this.setState({ validCustomPackagedPricingDrivers: true });
      } else {
        this.setState({ validCustomPackagedPricingDrivers: false });
        this.validator.showMessages();
        updateStepperWarnings = true;
      }
    }
    if (
      this.props.activePricingOption === "customPackagedPricing" &&
      prevProps.activeStep !== 3 &&
      this.props.activeStep === 3
    ) {
      this.props.getPricingDriversForCustomPackagedPricing(
        this.props.packagedPricingSelectedPackages,
        this.props.customPackages
      );
    }
    if (!updateStepperWarnings) {
      if (
        this.props.quoteClient !== prevProps.quoteClient ||
        this.props.quoteTemplate !== prevProps.quoteTemplate ||
        this.props.recurringTotal !== prevProps.recurringTotal ||
        this.props.oneOffTotal !== prevProps.oneOffTotal ||
        (this.props.recurringTotal > 0
          ? this.props.packageRecurringPrice !== prevProps.packageRecurringPrice
          : false) ||
        (this.props.oneOffTotal > 0
          ? this.props.packageOneOffPrice !== prevProps.packageOneOffPrice
          : false) ||
        this.props.activeStep !== prevProps.activeStep ||
        this.props.packagedPricingSelectedPackages !==
        prevProps.packagedPricingSelectedPackages ||
        this.props.customPackages !== prevProps.customPackages
      ) {
        updateStepperWarnings = true;
      }
    }
    if (!updateStepperWarnings) {
      if (
        this.props.aLaCartePricingDrivers.length !==
        prevProps.aLaCartePricingDrivers.length
      ) {
        updateStepperWarnings = true;
      } else {
        for (let pricingDriver of this.props.aLaCartePricingDrivers) {
          if (
            this.props[pricingDriver] !== prevProps[pricingDriver] ||
            this.props[
            `${pricingDriver}-${this.props[pricingDriver]}_other`
            ] !==
            prevProps[`${pricingDriver}-${prevProps[pricingDriver]}_other`]
          ) {
            updateStepperWarnings = true;
            break;
          }
        }
      }
    }
    if (!updateStepperWarnings) {
      if (
        this.props.requiredGlobalPricingDrivers.length !==
        prevProps.requiredGlobalPricingDrivers.length
      ) {
        updateStepperWarnings = true;
      } else {
        for (let pricingDriver of this.props.requiredGlobalPricingDrivers) {
          if (
            this.props[`globalPricingDriver-${pricingDriver._id}`] !==
            prevProps[`globalPricingDriver-${pricingDriver._id}`] ||
            this.props[
            `globalPricingDriver-${pricingDriver._id}-${this.props[`globalPricingDriver-${pricingDriver._id}`]
            }_other`
            ] !==
            prevProps[
            `globalPricingDriver-${pricingDriver._id}-${prevProps[`globalPricingDriver-${pricingDriver._id}`]
            }_other`
            ]
          ) {
            updateStepperWarnings = true;
            break;
          }
        }
      }
    }
    if (updateStepperWarnings) {
      this.updateStepperWarnings();
    }
    if (
      this.props.recurringTotal !== prevProps.recurringTotal &&
      !this.state.discountUpdated
    ) {
      console.log(
        "&^!@(&#)!@*7987&@)!()#&)!@(&#()&!@)#& !&@)#& ()!@&# )(!@&#)( &!@)&# !)@&# )!@&# 07"
      );
      // if (!this.isArrEqual()) {
      //   this.props.onInputChange(
      //     "packageRecurringPrice",
      //     this.props.recurringTotal
      //   );
      //   this.props.onInputChange("packageOneOffPrice", this.props.oneOffTotal);
      //   this.setState({ discountUpdated: true });
      // }
    }
    if (this.props.activePricingOption === "aLaCartePricing") {
      if (
        prevProps.requiredGlobalPricingDrivers.length !=
        this.props.requiredGlobalPricingDrivers.length ||
        (this.props.requiredGlobalPricingDrivers.length > 0 &&
          this.state.aLaCartePricingSteps.length == 4)
      ) {
        if (this.props.requiredGlobalPricingDrivers.length <= 0) {
          this.setState({
            aLaCartePricingSteps: [
              "Basic Information",
              "Select Services",
              "Review Services",
              "Preview",
            ],
          });
        } else {
          this.setState({
            aLaCartePricingSteps: [
              "Basic Information",
              "Select Services",
              "Additional Information",
              "Review Services",
              "Preview",
            ],
          });
        }
      }
    } else {
      if (
        prevProps.customPackagedPricingDriversServicesByCategory !=
        this.props.customPackagedPricingDriversServicesByCategory ||
        prevProps.requiredGlobalPricingDrivers.length !=
        this.props.requiredGlobalPricingDrivers.length ||
        (this.props.requiredGlobalPricingDrivers.length > 0 &&
          this.state.customPackagedPricingSteps.length == 4)
      ) {
        if (
          (!this.props.requiredGlobalPricingDrivers ||
            this.props.requiredGlobalPricingDrivers.length <= 0)
        ) {
          this.setState({
            customPackagedPricingSteps: [
              "Basic Information",
              "Select Packages",
              "Review Packages",
              "Preview",
            ],
          });
        } else {
          this.setState({
            customPackagedPricingSteps: [
              "Basic Information",
              "Select Packages",
              "Additional Information",
              "Review Packages",
              "Preview",
            ],
          });
        }
      }
    }
    console.log("spec_check_q", this.props);
    // if (
    //   this.props.recurringTotal &&
    //   !this.state.updatedPercentageOnceRecurring
    // ) {
    //   if (
    //     this.props.packageRecurringPrice &&
    //     this.props.packageRecurringPrice != this.props.recurringTotal
    //   ) {
    //     const discount = parseFloat(
    //       ((this.props.recurringTotal - this.props.packageRecurringPrice) *
    //         100) /
    //       this.props.recurringTotal
    //     ).toFixed(2);
    //     if (discount != parseFloat(this.props.tempDiscountedPrice).toFixed(2)) {
    //       this.setState({
    //         recurringPriceDiscountedPricePercentage: discount < 0 ? 0 : discount,
    //         updatedPercentageOnceRecurring: true,
    //       });
    //     }
    //     this.props.onInputChange('tempDiscountedPrice', discount < 0 ? 0 : discount);
    //   }
    // }
    // if (this.props.oneOffTotal && !this.state.updatedPercentageOnceOneOff) {
    //   if (
    //     this.props.packageOneOffPrice &&
    //     this.props.packageOneOffPrice > 0 &&
    //     this.props.packageOneOffPrice != this.props.oneOffTotal
    //   ) {
    //     const discount = parseFloat(
    //       ((this.props.oneOffTotal - this.props.packageOneOffPrice) * 100) /
    //       this.props.oneOffTotal
    //     ).toFixed(2);
    //     console.log('spec_chcejce', discount, parseFloat(this.props.tempDiscountedPriceOneOff).toFixed(2));
    //     if (discount != parseFloat(this.props.tempDiscountedPriceOneOff).toFixed(2)) {
    //       this.setState({
    //         oneOffDiscountedPricePercentage: discount < 0 ? 0 : discount,
    //         updatedPercentageOnceOneOff: true,
    //       });
    //       this.props.onInputChange('tempDiscountedPriceOneOff', discount < 0 ? 0 : discount);
    //     }
    //   }
    // }
    console.log('spec_check_dssds', prevProps.oneOffTotal, this.props.oneOffTotal, this.props.oneOffTotal, this.props.packageOneOffPrice, this.props.tempDiscountedPriceOneOff);
    // if (prevProps.oneOffTotal > this.props.oneOffTotal && this.props.oneOffTotal > this.props.packageOneOffPrice && this.props.tempDiscountedPriceOneOff == 0) {
    //   const discount = parseFloat(
    //     ((this.props.oneOffTotal - this.props.packageOneOffPrice) * 100) /
    //     this.props.oneOffTotal
    //   ).toFixed(2);
    //   console.log('spec_chcejce2', discount, parseFloat(this.props.tempDiscountedPriceOneOff).toFixed(2));
    //   if (discount != parseFloat(this.props.tempDiscountedPriceOneOff).toFixed(2)) {
    //     this.setState({
    //       oneOffDiscountedPricePercentage: discount < 0 ? 0 : discount,
    //       updatedPercentageOnceOneOff: true,
    //     });
    //     this.props.onInputChange('tempDiscountedPriceOneOff', discount < 0 ? 0 : discount);
    //   }
    // }
  }

  isArrEqual() {
    return (
      this.props.quoteData?.tempSelectedServices?.length ===
      this.props.aLaCarteServices?.length &&
      this.props.quoteData?.tempSelectedServices.every((c) =>
        this.props.aLaCarteServices.includes(c)
      )
    );
  }

  getDiscountedPrice() {
    return parseFloat(this.props.packageRecurringPrice) == parseFloat(0) &&
      this.props.tempDiscountedPrice != 100
      ? this.props.recurringTotal
      : this.props?.packageRecurringPrice;
  }

  updateOrganisation() {
    this.props.updatePricingSettings({
      minPricing: this.state.minPricingState,
      minPricingMonth: this.state.minPricingMonthState,
      maxDiscount: this.state.maxDiscountState,
    });
    this.setState({
      showPricingModel: false,
      minPricingState: 0,
      minPricingMonthState: 0,
      maxDiscountState: 0,
    })
  }

  render() {
    let categoryId,
      categoryName,
      serviceId,
      serviceChargeType,
      serviceName,
      pricingDrivers = [],
      dependencyArray = [];
    const categoryIds = Object.keys(this?.props?.servicesByCategory).map(
      (key) => key
    );

    const categoriesArray = Object.values(this?.props?.servicesByCategory).map(
      (category, idx) => {
        categoryId = categoryIds[idx];
        categoryName = category.categoryName;
        if (category?.services?.length > 0) {
          const servicesArray = category?.services?.map((service, idx) => {
            serviceId = service.id;
            serviceChargeType = service.chargeType;
            serviceName = service.name;
            if (service?.is_active && service?.pricingDrivers?.length > 0) {
              pricingDrivers = service?.pricingDrivers;
              const pricingDriversArray = service?.pricingDrivers?.map(
                (pricingDriver, idx) => {
                  if (
                    pricingDriver?.is_deleted === false &&
                    pricingDriver?.isDependent === true
                  ) {
                    const findDriver = pricingDrivers.filter(
                      (item) => item.name === pricingDriver.dependancyDriver
                    )[0];
                    const findDependency = dependencyArray.filter(
                      (item) =>
                        item.categoryId === categoryId &&
                        item.serviceId === serviceId &&
                        item.serviceChargeType === serviceChargeType &&
                        item.name === findDriver.name
                    );
                    if (findDependency?.length) {
                      const appendDependent = dependencyArray
                        .find((e) => e._id === findDependency[0]._id)
                        .dependents.push(pricingDriver);
                    } else {
                      dependencyArray.push({
                        ...findDriver,
                        categoryId,
                        categoryName,
                        serviceId,
                        serviceChargeType,
                        serviceName,
                        dependents: [pricingDriver],
                      });
                    }
                  }
                }
              );
            }
          });
        }
      }
    );

    const ongoing_Recurring_DependencyArray = dependencyArray.filter(
      (item) =>
        item.serviceChargeType === "recurring" ||
        item.serviceChargeType === "recurring/oneoff"
    );
    const oneOff_Adhoc_DependencyArray = dependencyArray.filter(
      (item) =>
        item.serviceChargeType === "oneoff" ||
        item.serviceChargeType === "recurring/oneoff"
    );

    this.type = this.props.match.path
      .replace("/quotes/", "")
      .replace("/:id", "");

    let activeStepCount = 1;

    const clientOptions = this.props.clients.map((item, index) => ({
      value: item._id,
      label: item.displayName,
      businessType: item.businessType,
    }));
    const templateOptions = this.props.templates
      .filter(
        (item) =>
          item.client_business_type === this.props.quoteClientBusinessType
      )
      .map((item, index) => ({ value: item._id, label: item.name }));
    let currentSteps = [];
    if (this.props.activePricingOption === "aLaCartePricing") {
      currentSteps = this.state.aLaCartePricingSteps;
    } else if (this.props.activePricingOption === "customPackagedPricing") {
      currentSteps = this.state.customPackagedPricingSteps;
    } else {
      currentSteps = this.state.packagedPricingSteps;
    }

    console.log(
      "this.props._subscriptionLoading - ",
      this.props._subscriptionLoading
    );
    if (this.props._subscriptionLoading) {
      return <Loader />;
    }

    if (!this.props._subscriptionLoading) {
      const prepare_quote = this.props.allow_prepare_quote;
      if (!prepare_quote) {
        return (
          <Redirect
            to={{
              pathname: "/quotes",
            }}
          />
        );
      }
    }
    return (
      <React.Fragment>
        {(this.props.loading ||
          this.props.serviceLoading ||
          this.props.packageLoading ||
          this.props.clientLoading ||
          this.props.pricingSettingsLoading ||
          this.props.templateLoading) && <Loader />}
        <div className="container-fluid new-item-page-container">
          <div className="new-item-page-nav">
            <i
              onClick={this.cancel}
              className="ion ion-md-arrow-round-back back-button"
            ></i>
          </div>
          <div className="new-item-page-content">
            <div className="row form-row">
              <div className="col-12">
                <h3>Create New Proposal</h3>
                <StepperWidget
                  onClick={(stepNumber) => this.customBackStep(stepNumber)}
                  activeStep={this.props.activeStep}
                  warningSteps={this.state.warningSteps}
                  steps={currentSteps}
                  custom={true}
                  currentActiveStep={this.state.currentActiveStep}
                />
                <TabContent activeTab={this.props.activeStep}>
                  <TabPane tabId={1} className="p-3">
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Select Prospect
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={clientOptions}
                          onChange={(selectedOption) => {
                            this.props.onInputChange(
                              "quoteClient",
                              selectedOption.value
                            );
                            this.props.onInputChange("quoteTemplate", null);
                            this.props.onInputChange(
                              "quoteClientBusinessType",
                              selectedOption.businessType
                            );
                          }}
                          value={
                            this.props.quoteClient
                              ? clientOptions.filter(
                                (option) =>
                                  option.value === this.props.quoteClient
                              )
                              : ""
                          }
                        />
                        {this.validator.message(
                          "quoteClient",
                          this.props.quoteClient,
                          "_required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Select Template
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={templateOptions}
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "quoteTemplate",
                              selectedOption.value
                            )
                          }
                          value={
                            this.props.quoteTemplate
                              ? templateOptions.filter(
                                (option) =>
                                  option.value === this.props.quoteTemplate
                              )
                              : (() => {
                                const lastOption = templateOptions[templateOptions.length - 1];
                                if (lastOption) {
                                  this.props.onInputChange("quoteTemplate", lastOption.value, lastOption.label); // Pass name and value
                                  return lastOption;
                                }
                                return null;
                              })()
                          }
                        />
                        {this.validator.message(
                          "quoteTemplate",
                          this.props.quoteTemplate,
                          "_required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Select Proposal Type
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={quotePricingOptions}
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "activePricingOption",
                              selectedOption.value
                            )
                          }
                          value={
                            this.props.activePricingOption
                              ? quotePricingOptions.filter(
                                (option) =>
                                  option.value ===
                                  this.props.activePricingOption
                              )
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </TabPane>
                  {this.props.activePricingOption === "aLaCartePricing" && (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      <div className="row">
                        <div className="col-6 border-right-separator">
                          <h4>Ongoing/Recurring Services</h4>
                          <div className="separator mb-2" />
                          <SelectServices
                            chargeType="recurring"
                            parentProps={this.props}
                            dependencyArray={ongoing_Recurring_DependencyArray}
                            onInputChange={(...e) => {
                              console.log("input ran - -- ");
                              this.props.onInputChange(...e);
                            }}
                            getGlobalPricingDriver={
                              this.props.getGlobalPricingDriver
                            }
                            servicesByCategory={this.props.servicesByCategory}
                            services={this.props.aLaCarteServices}
                            addService={this.props.addServiceToALaCartePackage}
                            removeService={
                              this.props.removeServiceFromALaCartePackage
                            }
                            validator={this.validator}
                          />
                        </div>
                        <div className="col-6">
                          <h4>One-Off/Ad hoc Services</h4>
                          <div className="separator mb-2" />
                          <SelectServices
                            chargeType="oneoff"
                            parentProps={this.props}
                            dependencyArray={oneOff_Adhoc_DependencyArray}
                            onInputChange={this.props.onInputChange}
                            getGlobalPricingDriver={
                              this.props.getGlobalPricingDriver
                            }
                            servicesByCategory={this.props.servicesByCategory}
                            services={this.props.aLaCarteServices}
                            addService={this.props.addServiceToALaCartePackage}
                            removeService={
                              this.props.removeServiceFromALaCartePackage
                            }
                            validator={this.validator}
                          />
                        </div>
                      </div>
                      {!this.state.valid &&
                        this.props.aLaCarteServices.length === 0 ? (
                        <p style={{ color: "red", textAlign: "center" }}>
                          Please select at least one service
                        </p>
                      ) : null}
                    </TabPane>
                  )}
                  {this.props.activePricingOption === "aLaCartePricing" &&
                    this.props.requiredGlobalPricingDrivers.length > 0 ? (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      <RequiredGlobalPricingDrivers
                        pricingDrivers={this.props.requiredGlobalPricingDrivers}
                        parentProps={this.props}
                        onInputChange={this.props.onInputChange}
                        validator={this.validator}
                        showNoInfoBlock
                      />
                    </TabPane>
                  ) : null}
                  {(this.props.activePricingOption === "packagedPricing" ||
                    this.props.activePricingOption ===
                    "customPackagedPricing") && (
                      <TabPane tabId={++activeStepCount} className="p-3">
                        {this.validator.messagesShown &&
                          !this.validateMinPackagedPricingPackages() && (
                            <div className="text-center text-danger">
                              Please select at least 1 package
                            </div>
                          )}
                        {!this.validateMaxPackagedPricingPackages() && (
                          <div className="text-center text-danger">
                            You can select maximum 3 packages
                          </div>
                        )}
                        {/* {this.props.activePricingOption==="customPackagedPricing" && <div className="text-right mb-3"><button onClick={() => this.props.setShowAddCustomPackageModal(true)} className="btn btn-success">Add Custom Package</button></div>} */}
                        {/* {this.props.activePricingOption==="customPackagedPricing" && Object.keys(this.props.customPackages).length>0 && <CustomPackagesList editAddCustomPackageModal={this.props.editAddCustomPackageModal} packages={this.props.customPackages} />} */}
                        {/* {this.props.activePricingOption==="customPackagedPricing" && <div className="separator mt-3 mb-3" />} */}
                        {this.props.activePricingOption ===
                          "customPackagedPricing" && (
                            <label className="fieldset-label mb-1">
                              Packaged (Customizable)
                            </label>
                          )}
                        {this.props.activePricingOption === "packagedPricing" && (
                          <label className="fieldset-label mb-1">
                            Packaged (Standard)
                          </label>
                        )}
                        <SelectPackagesStep
                          packages={this.props.packages}
                          packagedPricingSelectedPackages={
                            this.props.packagedPricingSelectedPackages
                          }
                          removePackagedPricingPackage={
                            this.props.removePackagedPricingPackage
                          }
                          addPackagedPricingPackage={
                            this.props.addPackagedPricingPackage
                          }
                          pagination={this.props.packagePagination}
                          getPackages={this.props.getPackages}
                        />
                      </TabPane>
                    )}
                  {this.props.activePricingOption === "customPackagedPricing" &&
                    (this.props.requiredGlobalPricingDrivers.length > 0 ||
                      (this.props
                        .customPackagedPricingDriversServicesByCategory &&
                        Object.keys(
                          this.props
                            .customPackagedPricingDriversServicesByCategory
                        ).length > 0)) ? (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      <div className="row">
                        <div className="col-12">
                          <RequiredGlobalPricingDrivers
                            dependencyArray={dependencyArray}
                            pricingDrivers={
                              this.props.requiredGlobalPricingDrivers
                            }
                            parentProps={this.props}
                            onInputChange={this.props.onInputChange}
                            validator={this.validator}
                            showNoInfoBlock={false}
                          />
                        </div>
                      </div>
                      {/* <div className="separator mb-2" /> */}
                      {/* <div className="row">
                        <div className="col-6 border-right-separator">
                          <h4>Ongoing/Recurring Services</h4>
                          <div className="separator mb-2" />
                          <RequiredDrivers
                            servicesByCategory={this.props.customPackagedPricingDriversServicesByCategory}
                            chargeType="recurring"
                            parentProps={this.props}
                            onInputChange={this.props.onInputChange}
                            validator={this.validator}
                          />
                        </div>
                        <div className="col-6">
                          <h4>One-Off/Ad hoc Services</h4>
                          <div className="separator mb-2" />
                          <RequiredDrivers
                            servicesByCategory={this.props.customPackagedPricingDriversServicesByCategory}
                            chargeType="oneoff"
                            parentProps={this.props}
                            onInputChange={this.props.onInputChange}
                            validator={this.validator}
                          />
                        </div>
                      </div> */}
                    </TabPane>
                  ) : null}
                  {this.props.activePricingOption === "aLaCartePricing" && (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      {!this.state.validPricingDrivers && (
                        <div className="text-center text-danger">
                          Please input all valid pricing driver values!
                        </div>
                      )}
                      {this.renderFeesInProposal()}
                      {this.state.validPricingDrivers ? (
                        <React.Fragment>
                          {Object.keys(this.props.calculatedPackagePrice)
                            .length === 0 &&
                            this.props.calculatedPackagePrice.constructor ===
                            Object && (
                              <div className="text-center">
                                No Services Found!
                              </div>
                            )}
                          <ReviewServices
                            isVatRegistered={
                              this.props.currentOrganisation.vatRegistered
                            }
                            flag={this.props.showFullBreakdown}
                            chargeType="recurring"
                            title="Recurring Services"
                            calculatedPackagePrice={
                              this.props.calculatedPackagePrice
                            }
                            discountedPrice={this.props.packageRecurringPrice}
                            discountPercentage={
                              this.props.tempDiscountedPrice
                            }
                          >
                            <div className="row fieldset">
                              <div className="col-2 text-right">
                                <label className="fieldset-label">
                                  Original Price (£)
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  value={formatCurrency(
                                    this.props.recurringTotal?.toFixed(2)
                                  )}
                                  readOnly
                                  className="input-text"
                                  type="text"
                                  placeholder="Original Price (£)"
                                />
                              </div>
                              <div className="col-2 p-0">
                                <label className="fieldset-label">
                                  Payment Frequency
                                </label>
                              </div>
                              <div className="col-4">
                                <Select
                                  options={paymentFrequencyOptions}
                                  onChange={(selectedOption) => {
                                    this.props.onInputChange(
                                      "paymentFrequency",
                                      selectedOption.value
                                    );
                                    this.setState({ viaSelect: true });
                                  }}
                                  value={
                                    this.props.paymentFrequency
                                      ? paymentFrequencyOptions.filter(
                                        (option) =>
                                          option.value ===
                                          this.props.paymentFrequency
                                      )
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="row fieldset">
                              <div className="col-2 text-right">
                                <label className="fieldset-label">
                                  Discount (%)
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  value={
                                    this.props.tempDiscountedPrice
                                  }
                                  onChange={(e) =>
                                    this.updatePercentageRecurringPrice(e)
                                  }
                                  className="input-text"
                                  type="text"
                                  placeholder="Discount (%)"
                                />
                                {this.props?.maxDiscount &&
                                  this.props.maxDiscount > 0
                                  ? this.validator.message(
                                    "packageRecurringPricePercentage",
                                    this.props.tempDiscountedPrice,
                                    `_numeric|_maxDiscount:${this.props.maxDiscount},num`,
                                    { className: "text-danger" }
                                  )
                                  : null}
                                {!this.validator.fields["packageRecurringPricePercentage"] && typeof this.validator.fields["packageRecurringPricePercentage"] != 'undefined'
                                  && this.validator.messagesShown ? (
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => this.setState({
                                      showPricingModel: true,
                                      minPricingState: this.props.minPricing ?? 0,
                                      minPricingMonthState: this.props.minPricingMonth ?? 0,
                                      maxDiscountState: this.props.maxDiscount ?? 0
                                    })}
                                  >Pricing Settings</a>
                                ) : null}
                              </div>
                              <div className="col-2 text-right p-0">
                                <label className="fieldset-label">
                                  Discounted Price (£)
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  value={
                                    !this.state.hasBlurred
                                      ? this.getDiscountedPrice()
                                      : formatCurrency(
                                        this.getDiscountedPrice()
                                      )
                                  }
                                  onFocus={() =>
                                    this.setState({
                                      hasFocused: true,
                                      hasBlurred: false,
                                    })
                                  }
                                  onBlur={() => {
                                    this.setState({ hasBlurred: true });
                                  }}
                                  // onKeyUp={(e) => {
                                  //   let obj = {
                                  //     yearly: (val) => val,
                                  //     halfYearly: (val) => val * 2,
                                  //     quarterly: (val) => val * 4,
                                  //     monthly: (val) => val * 12,
                                  //   };
                                  //   const afterValue = obj[
                                  //     this.props.paymentFrequency
                                  //   ](e.target.value);
                                  //   const discountedVal = this.props.tempDiscountedPrice > 0 ?
                                  //     (afterValue * this.props.tempDiscountedPrice)/100 : 0;
                                  //   this.setState(
                                  //     {
                                  //       discountedPrice: afterValue - discountedVal,
                                  //     }, () => {

                                  //       this.props.onInputChange("packageRecurringPrice", afterValue - discountedVal);
                                  //       this.updateRecurringPricePercentageDiscount()
                                  //     });
                                  //   }}
                                  onChange={(e) => {
                                    if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
                                      e.target.value = parseFloat(e.target.value).toFixed(2);
                                    }
                                    this.props.onInputChange(
                                      "packageRecurringPrice",
                                      e.target.value > 0 ? e.target.value : 0
                                    );
                                    this.setState({
                                      discountedPrice: e.target.value > 0 ? e.target.value : 0
                                    }, () => {
                                      this.updateRecurringPricePercentageDiscount()
                                    })
                                    if (
                                      e.target.value > this.props.recurringTotal
                                    ) {
                                      this.props.onInputChange(
                                        "showFullBreakdown",
                                        false
                                      );
                                      this.props.onInputChange(
                                        this.setState({
                                          flag: false,
                                        })
                                      );
                                    } else {
                                      if (this.props.oneOffTotal == 0 || this.props.oneOffTotal >= this.props.packageOneOffPrice) {
                                        this.props.onInputChange(
                                          "showFullBreakdown",
                                          true
                                        );
                                        // this.props.onInputChange(
                                        //   this.setState({
                                        //     flag: true,
                                        //   })
                                        // );
                                      }
                                    }
                                  }}
                                  className="input-text"
                                  type="text"
                                  placeholder="Discounted Price (£)"
                                />
                                {this.props?.minPricingMonth &&
                                  this.props.minPricingMonth > 0 &&
                                  (this.getDiscountedPrice() > 0 || this.props.tempDiscountedPrice == 100)
                                  ? this.validator.message(
                                    "packageRecurringPrice",
                                    (this.props.packageRecurringPrice > 0 || (this.props.packageRecurringPrice == 0 && this.props.tempDiscountedPrice == 100) ? this.props.packageRecurringPrice : this.props.recurringTotal) / this.state.currentPerMonthValue,
                                    `_numeric|_minPricingMonth:${this.props.minPricingMonth},num`,
                                    { className: "text-danger" }
                                  )
                                  : null}
                                {!this.validator.fields["packageRecurringPrice"]
                                  && this.validator.messagesShown ? (
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => this.setState({
                                      showPricingModel: true,
                                      minPricingState: this.props.minPricing ?? 0,
                                      minPricingMonthState: this.props.minPricingMonth ?? 0,
                                      maxDiscountState: this.props.maxDiscount ?? 0
                                    })}
                                  >Pricing Settings</a>
                                ) : null}
                                {/* {this.validator.message(
                                  "packageRecurringPrice",
                                  this.props.packageRecurringPrice,
                                  `_numeric|_min:${
                                    this.props.minPricing &&
                                    this.props.minPricing > 0
                                      ? this.props.minPricing
                                      : 0
                                  },num`,
                                  { className: "text-danger" }
                                )} */}
                              </div>
                            </div>
                          </ReviewServices>
                          <ReviewServices
                            isVatRegistered={
                              this.props.currentOrganisation.vatRegistered
                            }
                            flag={this.props.showFullBreakdown}
                            chargeType="oneoff"
                            title="One-Off Services"
                            calculatedPackagePrice={
                              this.props.calculatedPackagePrice
                            }
                            discountedPrice={this.props.packageOneOffPrice ?? this.props.oneOffTotal}
                            discountPercentage={
                              this.props.tempDiscountedPriceOneOff
                            }
                          >
                            <div className="row fieldset">
                              <div className="col-2 text-right">
                                <label className="fieldset-label">
                                  Original Price (£)
                                </label>
                              </div>
                              <div className="col-10">
                                <input
                                  value={formatCurrency(
                                    this.props.oneOffTotal?.toFixed(2)
                                  )}
                                  readOnly
                                  className="input-text"
                                  type="text"
                                  placeholder="Original Price (£)"
                                />
                              </div>
                            </div>
                            <div className="row fieldset">
                              <div className="col-2 text-right">
                                <label className="fieldset-label">
                                  Discount (%)
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  value={
                                    this.props.tempDiscountedPriceOneOff
                                  }
                                  onChange={(e) =>
                                    this.updatePercentageOneOff(e)
                                  }
                                  className="input-text"
                                  type="text"
                                  placeholder="Discount (%)"
                                />
                                {this.props?.maxDiscount &&
                                  this.props.maxDiscount > 0
                                  ? this.validator.message(
                                    "packageOneOffPricePercentage",
                                    this.state
                                      .oneOffDiscountedPricePercentage,
                                    `_numeric|_maxDiscount:${this.props.maxDiscount},num`,
                                    { className: "text-danger" }
                                  )
                                  : null}
                                {!this.validator.fields["packageOneOffPricePercentage"] && typeof this.validator.fields["packageOneOffPricePercentage"] != 'undefined'
                                  && this.validator.messagesShown ? (
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => this.setState({
                                      showPricingModel: true,
                                      minPricingState: this.props.minPricing ?? 0,
                                      minPricingMonthState: this.props.minPricingMonth ?? 0,
                                      maxDiscountState: this.props.maxDiscount ?? 0
                                    })}
                                  >Pricing Settings</a>
                                ) : null}
                              </div>
                              <div className="col-2 text-right p-0">
                                <label className="fieldset-label">
                                  Discounted Price (£)
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  value={
                                    this.state.hasBlurredOneOff ?
                                      formatCurrency(!this.props.packageOneOffPrice &&
                                        this.state
                                          .oneOffDiscountedPricePercentage != 100
                                        ? this.props.oneOffTotal
                                        : this.props.packageOneOffPrice)
                                      : !this.props.packageOneOffPrice &&
                                        this.state
                                          .oneOffDiscountedPricePercentage != 100
                                        ? this.props.oneOffTotal
                                        : this.props.packageOneOffPrice
                                  }
                                  onChange={(e) => {
                                    if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
                                      return false;
                                    }
                                    this.props.onInputChange(
                                      "packageOneOffPrice",
                                      e.target.value > 0 ? e.target.value : 0
                                    );
                                    if (
                                      e.target.value > this.props.oneOffTotal
                                    ) {
                                      this.props.onInputChange(
                                        "showFullBreakdown",
                                        false
                                      );
                                      this.props.onInputChange(
                                        this.setState({
                                          flag: false,
                                        })
                                      );
                                    } else {
                                      if (this.props.recurringTotal == 0 || this.props.recurringTotal >= this.props.packageRecurringPrice) {
                                        this.props.onInputChange(
                                          "showFullBreakdown",
                                          true
                                        );
                                        // this.props.onInputChange(
                                        //   this.setState({
                                        //     flag: true,
                                        //   })
                                        // );
                                      }
                                    }
                                  }}
                                  onKeyUp={(e) => {
                                    this.setState(
                                      {
                                        oneOffDiscountedPrice: e.target.value,
                                      },
                                      () =>
                                        this.updateOneOffPercentageDiscount()
                                    );
                                  }}
                                  onFocus={() =>
                                    this.setState({
                                      hasFocusedOneOff: true,
                                      hasBlurredOneOff: false,
                                    })
                                  }
                                  onBlur={() =>
                                    this.setState({ hasBlurredOneOff: true })
                                  }
                                  className="input-text"
                                  type="text"
                                  placeholder="Discounted Price (£)"
                                />
                                {this.props?.minPricing &&
                                  this.props.minPricing > 0 &&
                                  ((!this.props.packageOneOffPrice &&
                                    this.state
                                      .oneOffDiscountedPricePercentage != 100
                                    ? this.props.oneOffTotal
                                    : this.props.packageOneOffPrice) > 0 || this.props.packageOneOffPrice == 0 && this.props.tempDiscountedPriceOneOff == 100)
                                  ? this.validator.message(
                                    "packageOneOffPrice",
                                    this.props.packageOneOffPrice,
                                    `_numeric|_minPricing:${this.props.minPricing},num`,
                                    { className: "text-danger" }
                                  )
                                  : null}
                                {!this.validator.fields["packageOneOffPrice"]
                                  && this.validator.messagesShown ? (
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => this.setState({
                                      showPricingModel: true,
                                      minPricingState: this.props.minPricing ?? 0,
                                      minPricingMonthState: this.props.minPricingMonth ?? 0,
                                      maxDiscountState: this.props.maxDiscount ?? 0
                                    })}
                                  >Pricing Settings</a>
                                ) : null}
                                {/*this.validator.message('Recurring Min Price', this.props.packageRecurringMinPrice, `required|numeric|min:0,num|max:${this.props.packageRecurringDefaultPrice},num`, { className: 'text-danger' })*/}
                              </div>
                            </div>
                          </ReviewServices>
                        </React.Fragment>
                      ) : null}
                    </TabPane>
                  )}
                  {this.props.activePricingOption === "packagedPricing" && (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      {this.renderFeesInProposal()}
                      <ReviewPackagesStep
                        isVatRegistered={
                          this.props.currentOrganisation.vatRegistered
                        }
                        flag={this.props.showFullBreakdown}
                        packages={
                          this.props.reviewPackagedPricingSelectedPackages
                        }
                        servicesByCategory={
                          this.props.reviewPackagedPricingServicesByCategory
                        }
                        chargeType="recurring"
                        title="Recurring Services"
                      >
                        <div className="row fieldset">
                          <div className="col-2 p-0">
                            <label className="fieldset-label">
                              Payment Frequency
                            </label>
                          </div>
                          <div className="col-10">
                            <Select
                              options={paymentFrequencyOptions}
                              onChange={(selectedOption) =>
                                this.props.onInputChange(
                                  "paymentFrequency",
                                  selectedOption.value
                                )
                              }
                              value={
                                this.props.paymentFrequency
                                  ? paymentFrequencyOptions.filter(
                                    (option) =>
                                      option.value ===
                                      this.props.paymentFrequency
                                  )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </ReviewPackagesStep>
                      <ReviewPackagesStep
                        flag={this.props.showFullBreakdown}
                        isVatRegistered={
                          this.props.currentOrganisation.vatRegistered
                        }
                        packages={
                          this.props.reviewPackagedPricingSelectedPackages
                        }
                        servicesByCategory={
                          this.props.reviewPackagedPricingServicesByCategory
                        }
                        chargeType="oneoff"
                        title="One-Off Services"
                      />
                    </TabPane>
                  )}
                  {this.props.activePricingOption ===
                    "customPackagedPricing" && (
                      <TabPane tabId={++activeStepCount} className="p-3">
                        {!this.state.validCustomPackagedPricingDrivers && (
                          <div className="text-center text-danger">
                            Please input all valid pricing driver values!
                          </div>
                        )}
                        {this.renderFeesInProposal()}
                        {this.state.validCustomPackagedPricingDrivers && (
                          <CustomReviewPackagesStep
                            flag={this.props.showFullBreakdown}
                            isVatRegistered={
                              this.props.currentOrganisation.vatRegistered
                            }
                            packages={
                              this.props.reviewPackagedPricingSelectedPackages
                            }
                            servicesByCategory={
                              this.props.reviewPackagedPricingServicesByCategory
                            }
                            chargeType="recurring"
                            title="Recurring Services"
                          >
                            <div className="row fieldset">
                              <div className="col-2 p-0">
                                <label className="fieldset-label">
                                  Payment Frequency
                                </label>
                              </div>
                              <div className="col-10">
                                <Select
                                  options={paymentFrequencyOptions}
                                  onChange={(selectedOption) =>
                                    this.props.onInputChange(
                                      "paymentFrequency",
                                      selectedOption.value
                                    )
                                  }
                                  value={
                                    this.props.paymentFrequency
                                      ? paymentFrequencyOptions.filter(
                                        (option) =>
                                          option.value ===
                                          this.props.paymentFrequency
                                      )
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                          </CustomReviewPackagesStep>
                        )}
                        {this.state.validCustomPackagedPricingDrivers && (
                          <CustomReviewPackagesStep
                            flag={this.props.showFullBreakdown}
                            isVatRegistered={
                              this.props.currentOrganisation.vatRegistered
                            }
                            packages={
                              this.props.reviewPackagedPricingSelectedPackages
                            }
                            servicesByCategory={
                              this.props.reviewPackagedPricingServicesByCategory
                            }
                            chargeType="oneoff"
                            title="One-Off Services"
                          />
                        )}
                      </TabPane>
                    )}
                  <TabPane tabId={++activeStepCount} className="p-3">
                    {!this.state.validQuote && (
                      <div className="text-center text-danger">
                        Please input all valid values!
                      </div>
                    )}
                    {this.state.validQuote && this.props.previewQuotePDFUrl && (
                      <iframe
                        src={this.props.previewQuotePDFUrl}
                        className="pdfIframe"
                      />
                    )}
                  </TabPane>
                </TabContent>
                <div className="separator mt-3 mb-3" />
                <div className="row fieldset">
                  <div className="col-12 text-right">
                    <button onClick={this.cancel} className="btn">
                      Cancel
                    </button>
                    {this.props.activeStep > 1 && (
                      <button
                        onClick={this.backStep}
                        className="btn create-item ml-2"
                      >
                        Back
                      </button>
                    )}

                    {this.props.activeStep < currentSteps.length && (
                      <button
                        onClick={this.nextStep}
                        className="btn create-item ml-2"
                      >
                        Next
                      </button>
                    )}

                    <button
                      onClick={() => this.formatDataAndSubmitQuote("draft")}
                      className="btn create-item ml-2"
                    >
                      Save as a Draft
                    </button>
                    {this.props.activeStep === currentSteps.length && (
                      <button
                        onClick={() => this.formatDataAndSubmitQuote("send")}
                        className="btn create-item ml-2"
                      >
                        Send Proposal
                        <i className="ion ion-ios-send ml-1 send-proposal-ion-icon"></i>
                      </button>
                    )}
                    {this.props.activeStep === currentSteps.length && this.props?.currentOrganisation?.isVisible && (
                      <button
                        onClick={() => {
                          this.formatDataAndSubmitQuote("skip");
                        }}
                        className="btn create-item ml-2"
                      >
                        Skip to Engagement Letter
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddCustomPackageModal
          servicesByCategory={this.props.servicesByCategory}
          setShowAddCustomPackageModal={this.props.setShowAddCustomPackageModal}
          showAddCustomPackageModal={this.props.showAddCustomPackageModal}
        />
        {this.props.showCreateNewQuoteSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowCreateNewQuoteSuccessModal(false);
              this.props.history.replace("/quotes");
            }}
          >
            <div className="mb-2 success-alert-message">
              {this.props.createNewQuoteSuccessModalMessage}
            </div>
          </SweetAlert>
        )}
        <Modal
          isOpen={this.state.showPricingModel}
          toggle={() => this.setState({
            showPricingModel: !this.state.showPricingModel,
            minPricingState: 0,
            minPricingMonthState: 0,
            maxDiscountState: 0
          })} size="lg">
          <ModalBody>
            <h4>Change Pricing Settings</h4>
            <div className="separator mb-2" />
            <div className="add-custom-package-content">
              <div className="row fieldset">
                <div className="col-4">
                  <label className="fieldset-label">Min. One-Off Price for Proposal/Engagement Letter</label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="input-text"
                    min={0}
                    onChange={(e) => {
                      var reg = /^\d+$/;
                      if (!reg.test(e.target.value) && e.target.value != "")
                        return;
                      if (e.target.value.length > 8) return;
                      const value = e.target.value < 0 ? 0 : e.target.value;
                      this.setState({
                        minPricingState: value
                      });
                    }}
                    value={this.state.minPricingState}
                  />
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-4">
                  <label className="fieldset-label">Min. Monthly Price for Proposal/Engagement Letter</label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="input-text"
                    min={0}
                    onChange={(e) => {
                      var reg = /^\d+$/;
                      if (!reg.test(e.target.value) && e.target.value != "")
                        return;
                      if (e.target.value.length > 8) return;
                      const value = e.target.value < 0 ? 0 : e.target.value;
                      this.setState({
                        minPricingMonthState: value
                      });
                    }}
                    value={this.state.minPricingMonthState}
                  />
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-4">
                  <label className="fieldset-label">
                    Max. Discount (%) for Proposal/Engagement Letter
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    min={0}
                    max={100}
                    className="input-text"
                    onChange={(e) => {
                      var reg = /^\d+$/;
                      if (!reg.test(e.target.value) && e.target.value != "")
                        return;
                      if (e.target.value > 100) return;
                      const value = e.target.value < 0 ? 0 : e.target.value;
                      this.setState({
                        maxDiscountState: value
                      });
                    }}
                    value={this.state.maxDiscountState}
                  />
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-12">
                  <button
                    onClick={this.updateOrganisation}
                    className="btn mt-3 create-item"
                  >
                    Submit
                  </button>
                  <button className="btn ml-2 mt-3" onClick={() => this.setState({ showPricingModel: false, minPricingMonthState: 0, minPricingState: 0, maxDiscountState: 0 })}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    currentOrganisation,
    subscriptionPackage,
    loading: _subscriptionLoading,
    pricingSettingsLoading,
    allow_send_quote,
  } = state.Organisation;
  const { minPricing, minPricingMonth, maxDiscount, allow_prepare_quote } = state.Organisation;
  const { servicesByCategory, requiredGlobalPricingDrivers } = state.Service;
  const serviceLoading = state.Service.loading;
  const packageLoading = state.Package.loading;
  const packagePagination = state.Package.pagination;
  const clientLoading = state.Client.loading;
  const templateLoading = state.Template.loading;
  const { clients } = state.Client;
  const { templates } = state.Template;
  const { packages } = state.Package;
  return {
    currentOrganisation,
    servicesByCategory,
    requiredGlobalPricingDrivers,
    packageLoading,
    clientLoading,
    serviceLoading,
    templateLoading,
    clients,
    templates,
    packages,
    subscriptionPackage,
    packagePagination,
    minPricing,
    minPricingMonth,
    maxDiscount,
    ...state.Quote,
    _subscriptionLoading,
    pricingSettingsLoading,
    allow_send_quote,
    allow_prepare_quote,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onInputChange,
    getServicesByCategory,
    getGlobalDriversByServices,
    addServiceToALaCartePackage,
    removeServiceFromALaCartePackage,
    setActiveStep,
    getClients,
    getGlobalPricingDriver,
    calculatePackagePrice,
    previewQuote,
    sendQuotation,
    saveQuotationAsDraft,
    setShowCreateNewQuoteSuccessModal,
    resetCreateNewQuoteForm,
    setShowUpgradeModal,
    getQuote,
    getTemplates,
    getPackages,
    addPackagedPricingPackage,
    removePackagedPricingPackage,
    reviewPackagedPricingPackages,
    reviewCustomPackagedPricingPackages,
    getPricingDriversByPackageIds,
    getPricingDriversForCustomPackagedPricing,
    setShowAddCustomPackageModal,
    editAddCustomPackageModal,
    skipQuotation,
    getMyOrganisation,
    getPricingSettings,
    updatePricingSettings,
    onOrgInputChange,
  })(CreateNewQuote)
);
