import React from "react";
import { Redirect } from "react-router-dom";

import { ElementsConsumer } from "@stripe/react-stripe-js";

// Pages Component

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPwd from "../pages/Authentication/ResetPassword";
import Activate from "../pages/Authentication/Activate";
import Subscription from "../pages/Subscription/Subscription";
import Billing from "../pages/Billing/Billing";
import Subscriptionpackage from "../pages/Subscription-Packages/Subscriptionpackage";
import Practice from "../pages/Subscribed/Practice";
import Invoice from "../pages/Invoices/Invoice";
import Dashboard from "../pages/Dashboard";
import Calendar from "../pages/Apps/Calendar/index";
import EmailInbox from "../pages/Apps/Email/inbox";
import EmailRead from "../pages/Apps/Email/read";
import EmailCompose from "../pages/Apps/Email/compose";
import Emailtemplatealert from "../pages/Apps/EmailTemplate/email-template-alert";
import Emailtemplatebasic from "../pages/Apps/EmailTemplate/email-template-basic";
import Emailtemplatebilling from "../pages/Apps/EmailTemplate/email-template-billing";

// UI Pages
import UiSweetAlert from "../pages/UI/UiSweetAlert";
import UiAlerts from "../pages/UI/Alerts";
import UiButtons from "../pages/UI/Buttons";
import UiCards from "../pages/UI/Cards";
import UiCarousel from "../pages/UI/Carousel";
import UiDropdown from "../pages/UI/Dropdown";
import UiGrid from "../pages/UI/Grid";
import UiImages from "../pages/UI/Images";
import UiLightbox from "../pages/UI/Lightbox";
import UiModals from "../pages/UI/Modals";
import UiRangeSlider from "../pages/UI/RangeSlider";
import UiSessionTimeout from "../pages/UI/SessionTimeout";
import Progressbar from "../pages/UI/Progressbar";
import TabsAccordion from "../pages/UI/TabsAccordion";
import Typography from "../pages/UI/Typography";
import Video from "../pages/UI/Video";
import General from "../pages/UI/General";
import Colors from "../pages/UI/Colors";
import Rating from "../pages/UI/Rating";

// Form
import FormElement from "../pages/Forms/FormElement";
import FormValidation from "../pages/Forms/FormValidation";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormXeditable from "../pages/Forms/FormXeditable";
import FormWizard from "../pages/Forms/FormWizard";
import FormEditor from "../pages/Forms/FormEditor";

// Charts
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import ApexChart from "../pages/Charts/ApexChart";
import SarklineChart from "../pages/Charts/SarklineChart";
import EchartChart from "../pages/Charts/EChart";

//Tables
import TableBasic from "../pages/Tables/TableBasic";
import TableDatatable from "../pages/Tables/TableDatatable";
import TableResponsive from "../pages/Tables/TableResponsive";
import TableEditable from "../pages/Tables/TableEditable";

//Maps
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontAwesome from "../pages/Icons/IconFontAwesome";
import IconIon from "../pages/Icons/IconIon";
import IconMaterial from "../pages/Icons/IconMaterial";
import IconThemify from "../pages/Icons/IconThemify";
import IconTypicons from "../pages/Icons/IconTypicons";

//Maps
import MapsVector from "../pages/Maps/MapsVector";
import MapsGoogle from "../pages/Maps/MapsGoogle";

// Extra Pages
import PagesLogin2 from "../pages/ExtraPages/PagesLogin2";
import PagesRegister2 from "../pages/ExtraPages/PagesRegister2";
import PagesRecoverpw2 from "../pages/ExtraPages/PagesRecoverpw2";
import PagesLockScreen2 from "../pages/ExtraPages/PagesLockScreen2";

// Extra Pages
import PagesTimeline from "../pages/ExtraPages/PagesTimeline";
import PagesInvoice from "../pages/ExtraPages/PagesInvoice";
import PagesDirectory from "../pages/ExtraPages/PagesDirectory";
import PagesBlank from "../pages/ExtraPages/PagesBlank";
import PagesPricing from "../pages/ExtraPages/PagesPricing";
import PagesGallery from "../pages/ExtraPages/PagesGallery";
import PagesFaq from "../pages/ExtraPages/PagesFaq";
import Pages404 from "../pages/ExtraPages/Pages404";
import Pages500 from "../pages/ExtraPages/Pages500";
import PagesMaintenance from "../pages/ExtraPages/PagesMaintenance";
import PagesComingsoon from "../pages/ExtraPages/PagesComingsoon";

// Access Management
import RoleManagement from "../pages/AccessManagement/RoleManagement";
import DefaultServices from "../pages/DefaultServices";
import Services from "../pages/Services";
import Quotes from "../pages/Quotes";
import Contracts from "../pages/Contracts";
import TextTemplates from "../pages/TextTemplates";
import TermsAndConditions from "../pages/TermsAndConditions";
import Templates from "../pages/Templates";
import ServiceCategories from "../pages/ServiceCategories";
// import ServicePackages from "../pages/ServicePackages";
// import Users from "../pages/Users";
import Organisations from "../pages/Organisations";
import Packages from "../pages/Packages";
import Pricing from "../pages/Pricing";
import Clients from "../pages/Clients";
import CreateNewClient from "../pages/CreateNewClient";
import GetStarted from "../pages/GetStarted";
import CreateNewQuote from "../pages/CreateNewQuote";
import CreateNewPackage from "../pages/CreateNewPackage";
import CreateNewContract from "../pages/CreateNewContract";
import CreateNewTextTemplate from "../pages/CreateNewTextTemplate";
import CreateNewTermsAndConditionTemplate from "../pages/CreateNewTermsAndConditionTemplate";
import CreateNewTemplate from "../pages/CreateNewTemplate";
import CreateNewOrganisation from "../pages/CreateNewOrganisation";
import CreateNewService from "../pages/CreateNewService";
import CreateNewCategory from "../pages/CreateNewCategory";
import ViewClient from "../pages/ViewClient";
import ViewQuote from "../pages/ViewQuote";
import ViewContract from "../pages/ViewContract";
import InviteNewUser from "../pages/InviteNewUser";
import SearchLocationInput from "../pages/SearchLocationInput";
import AcceptInviteUser from "../pages/AcceptInviteUser";
import ErrorPage from "../pages/ErrorPage";
// import Settings from "../pages/Settings";
import ActivityLogs from "../pages/ActivityLogs";
import Notifications from "../pages/Notifications";
import UpdateOrganisationDetails from "../pages/Settings/UpdateOrganisationDetails";
import Users from "../pages/Settings/Users";
import GlobalPricingDrivers from "../pages/Settings/GlobalPricingDrivers";
import GlobalConstants from "../pages/Settings/GlobalConstants";
import PaymentGateways from "../pages/Settings/PaymentGateways";
import AccessKeys from "../pages/Settings/AccessKeys";

import DirectDebitSetupSuccess from "../pages/DirectDebitSetupSuccess";
import DirectDebitAlreadySetup from "../pages/DirectDebitAlreadySetup";

import NonAuthLayout from "../components/NonAuthLayout";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import EmailTemplate from "../pages/EmailTemplate";
import CreateNewEmailTemplate from "../pages/CreateNewEmailTemplate";
import GenerateContractClient from "../pages/GenerateContractClient.js";
import PricingSettings from "../pages/Settings/PricingSettings";
import DisplayPdfs from "../pages/DisplayPdfs";
import DisplayPdfsContract from "../pages/DisplayPdfsContract";
import GenerateContractClientPackaged from "../pages/GenerateContractClientPackaged";
import GenerateContractClientReject from "../pages/GenerateContractClientReject";
import EmailConfigs from "../pages/EmailConfigs";
import GenerateEmailTemplates from "../pages/GenerateEmailTemplates";
import LandingPage from "../pages/LandingPage/landing.js";
import SignUp from "../pages/LandingPage/sign-up.js";
import SignIn from "../pages/LandingPage/sign-in.js";
import ForgotPasswordNew from "../pages/LandingPage/forgot-password-new.js";
import ResetPasswordNew from "../pages/LandingPage/reset-password-new.js";
import booking from "../pages/LandingPage/booking.js";
import HtmlProposalAccepted from "../pages/HtmlProposalAccepted.js";
import HtmlProposalDeclined from "../pages/HtmlProposalDeclined.js";
import EmailConfig from "../pages/Settings/EmailConfig.js";

// const stripePromise = loadStripe("pk_live_51MxUuOSAq3KXUSAJLuqUD8YVZDovyHji0Zxy2GO3XGY1QwSKk8TOxr8W40p0fDWGcQmuYlV9VgQuxtMy43S95OTQ00ATCf8pm8"); // starts with pk_
const stripePromise = loadStripe(
  "pk_test_51NFxNdCPwhSkFOnpuHVCkcpCtIINkfLkX08BUppCtb8HlNCJ4wCl5Vakw8LTzHRUYdfaCxVnn2FpNzi4y6worJ1U00r0jvFXW9"
); // starts with pk_

const InjectedCreateNewOrganisation = () => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CreateNewOrganisation stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
);
const InjectedPractice = () => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Practice stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
);
const InjectedBilling = () => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Billing stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
);

const authProtectedRoutes = [
  // Calendar
  { path: "/calendar", component: Calendar },

  // Email & Email Templates
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-compose", component: EmailCompose },

  // Email Template
  { path: "/email-template-alert", component: Emailtemplatealert },
  { path: "/email-template-basic", component: Emailtemplatebasic },
  { path: "/email-template-billing", component: Emailtemplatebilling },

  // Ui Pages
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-alerts", component: UiAlerts },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModals },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-progressbars", component: Progressbar },
  { path: "/ui-tabs-accordions", component: TabsAccordion },
  { path: "/ui-typography", component: Typography },
  { path: "/ui-video", component: Video },
  { path: "/ui-general", component: General },
  { path: "/ui-colors", component: Colors },
  { path: "/ui-rating", component: Rating },

  // Form
  { path: "/form-elements", component: FormElement },
  { path: "/form-validation", component: FormValidation },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-xeditable", component: FormXeditable },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-editors", component: FormEditor },

  //Charts
  { path: "/charts-chartist", component: ChartistChart },
  { path: "/charts-chartjs", component: ChartjsChart },
  { path: "/apex-chart", component: ApexChart },
  { path: "/charts-sparkline", component: SarklineChart },
  { path: "/e-charts", component: EchartChart },

  //Tables
  { path: "/tables-basic", component: TableBasic },
  { path: "/tables-datatable", component: TableDatatable },
  { path: "/tables-responsive", component: TableResponsive },
  { path: "/tables-editable", component: TableEditable },

  //Icons
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-fontawesome", component: IconFontAwesome },
  { path: "/icons-ion", component: IconIon },
  { path: "/icons-material", component: IconMaterial },
  { path: "/icons-themify", component: IconThemify },
  { path: "/icons-typicons", component: IconTypicons },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },

  // Extra Pages
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-invoice", component: PagesInvoice },
  { path: "/pages-directory", component: PagesDirectory },
  { path: "/pages-blank", component: PagesBlank },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/pages-gallery", component: PagesGallery },
  { path: "/pages-faq", component: PagesFaq },

  // Access Management
  {
    path: "/access-management/role-management",
    component: RoleManagement,
    roles: ["superadmin"],
  },

  // Default Services
  {
    path: "/categories/new",
    layout: NonAuthLayout,
    component: CreateNewCategory,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/services/new",
    layout: NonAuthLayout,
    component: CreateNewService,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/services/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewService,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/default-services",
    component: DefaultServices,
    roles: ["superadmin"],
  },
  { path: "/services", component: Services, roles: ["superadmin", "admin"] },
  {
    path: "/categories",
    component: ServiceCategories,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/users/invite",
    layout: NonAuthLayout,
    component: InviteNewUser,
    roles: ["superadmin", "admin"],
  },
  // { path: "/users", component: Users, roles: ["superadmin", "admin"] },
  {
    path: "/organisations/new",
    layout: NonAuthLayout,
    component: InjectedCreateNewOrganisation,
    roles: ["superadmin", "admin", "unassigned"],
  },
  { path: "/organisations", component: Organisations, roles: ["superadmin"] },
  { path: "/users", component: Users, roles: ["superadmin"] },
  {
    path: "/packages/new",
    layout: NonAuthLayout,
    component: CreateNewPackage,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/packages/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewPackage,
    roles: ["superadmin", "admin"],
  },
  { path: "/packages", component: Packages, roles: ["superadmin", "admin"] },
  { path: "/pricing", component: Pricing, roles: ["superadmin"] },

  {
    path: "/clients/new",
    component: CreateNewClient,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/clients/:id",
    component: ViewClient,
    roles: ["superadmin", "admin"],
  },
  { path: "/clients", component: Clients, roles: ["superadmin", "admin"] },

  {
    path: "/quotes/new",
    layout: NonAuthLayout,
    component: CreateNewQuote,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/quotes/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewQuote,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/quotes/view-pdf/:id",
    layout: NonAuthLayout,
    component: DisplayPdfs,
    roles: ["superadmin", "admin"],
  },
  { path: "/quotes/:id", component: ViewQuote, roles: ["superadmin", "admin"] },
  { path: "/quotes", component: Quotes, roles: ["superadmin", "admin"] },

  {
    path: "/contracts/new",
    layout: NonAuthLayout,
    component: CreateNewContract,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/contracts/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewContract,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/contracts/view-pdf/:id",
    layout: NonAuthLayout,
    component: DisplayPdfsContract,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/contracts/:id",
    component: ViewContract,
    roles: ["superadmin", "admin"],
  },
  { path: "/contracts", component: Contracts, roles: ["superadmin", "admin"] },
  {
    path: "/subscription",
    component: InjectedBilling,
    roles: ["superadmin"],
  },
  {
    path: "/Billing",
    component: InjectedBilling,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/Subscriptionpackage",
    component: Subscriptionpackage,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/Practice",
    component: InjectedPractice,
    roles: ["superadmin", "admin"],
  },
  { path: "/Invoice", component: Invoice, roles: ["superadmin", "admin"] },
  {
    path: "/text-templates/new",
    layout: NonAuthLayout,
    component: CreateNewTextTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/text-templates/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewTextTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/text-templates",
    component: TextTemplates,
    roles: ["superadmin", "admin"],
  },

  {
    path: "/terms-and-conditions/new",
    layout: NonAuthLayout,
    component: CreateNewTermsAndConditionTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/terms-and-conditions/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewTermsAndConditionTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    roles: ["superadmin", "admin"],
  },

  {
    path: "/templates/new",
    layout: NonAuthLayout,
    component: CreateNewTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/templates/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewTemplate,
    roles: ["superadmin", "admin"],
  },
  { path: "/templates", component: Templates, roles: ["superadmin", "admin"] },

  {
    path: "/activity-logs",
    component: ActivityLogs,
    roles: ["admin", "superadmin"],
  },
  { path: "/notifications", component: Notifications, roles: ["admin"] },
  {
    path: "/settings/organisation-details",
    component: UpdateOrganisationDetails,
    roles: ["admin"],
  },
  { path: "/settings/users", component: Users, roles: ["admin"] },
  {
    path: "/settings/global-constants",
    component: GlobalConstants,
    roles: ["admin", "superadmin"],
  },
  {
    path: "/settings/global-pricing-drivers",
    component: GlobalPricingDrivers,
    roles: ["admin", "superadmin"],
  },
  {
    path: "/settings/payment-gateways",
    component: PaymentGateways,
    roles: ["admin"],
  },
  { path: "/settings/access-keys", component: AccessKeys, roles: ["admin"] },
  // { path: "/settings", component: Settings, roles: ["superadmin", "admin"] },

  { path: "/dashboard", component: Dashboard, roles: ["superadmin", "admin"] },
  { path: "/get-started", component: GetStarted },

  {
    path: "/settings/pricing-settings",
    component: PricingSettings,
    roles: ["admin"],
  },
//  {
//    path: "/settings/email-configuration",
//    component: EmailConfig,
//    roles: ["admin"],
//  },
  {
    path: "/email-config",
    component: EmailConfigs,
    roles: ["admin"],
  },
  {
    path: "/email-template/new",
    layout: NonAuthLayout,
    component: CreateNewEmailTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/email-template/edit/:id",
    layout: NonAuthLayout,
    component: CreateNewEmailTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/email-template",
    component: EmailTemplate,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/generate-email-templates/",
    component: GenerateEmailTemplates,
  },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  //Landing page---------------
  // { path: "", component: LandingPage },
  // { path: "/landingpage", component: SignIn },
  { path: "/signup", component: SignUp },
  { path: "/signIn", component: SignIn },
  { path: "/forgot-password-new", component: ForgotPasswordNew },
  { path: "/reset-password-new", component: ResetPasswordNew },
  {path:"/book-demo", component:booking},
  // { path: "", component: LandingPage },
  //Landing page---------------
  { path: "/logout", component: Logout },
  { path: "/login", component: SignIn },
  { path: "/forget-password", component: ForgetPwd },
  { path: "/reset-password/:userId/:token", component: ResetPasswordNew },
  { path: "/register", component: SignUp },
  { path: "/activate", component: Activate },
  {
    path: "/accept-invite",
    layout: NonAuthLayout,
    component: AcceptInviteUser,
  },

  // Extra Pages
  { path: "/pages-login-2", component: PagesLogin2 },
  { path: "/pages-register-2", component: PagesRegister2 },
  { path: "/pages-recoverpw-2", component: PagesRecoverpw2 },
  { path: "/pages-lock-screen-2", component: PagesLockScreen2 },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/pages-Maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/places", component: SearchLocationInput },
  { path: "/error", component: ErrorPage },

  { path: "/mandate/success", component: DirectDebitSetupSuccess },
  { path: "/mandate/already-setup", component: DirectDebitAlreadySetup },
  {
    path: "/generate-contract/:id/:packageId",
    component: GenerateContractClientPackaged,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/generate-contract-reject/:id",
    component: GenerateContractClientReject,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/generate-contract/:id",
    component: GenerateContractClient,
    roles: ["superadmin", "admin"],
  },
  {
    path: "/accepted/:id/:packageId?",
    component: HtmlProposalAccepted,
    roles: ["admin"],
  },
  {
    path: "/declined/:id",
    component: HtmlProposalDeclined,
    roles: ["admin"],
  },
];

export { authProtectedRoutes, publicRoutes };
