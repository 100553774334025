import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import logo from "../assets/images/outbooks.png";

class GetStarted extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-12">
              <div className="card">
                <div className="card-body get-started-card">
                  <div className="row">
                    <div className="col-6">
                      <img src={logo} />
                    </div>
                    <div className="col-6">
                      <h2>Welcome to Outbooks!</h2>
                      <p>Please create a new Practice to get started or contact your administrator to request access to the portal.</p>
                      <button onClick={() => this.props.history.push("/organisations/new")} className="btn btn-lg btn-dark">Create New Practice</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GetStarted;
