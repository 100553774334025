import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ClientsTable from "../components/ClientsTable";
import AutoCompleteInput from "../components/AutoCompleteInput";
import CreateClientModal from "../components/CreateClientModal";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
import { setShowCreateClientModal, getClients, searchClient, onInputChange } from "../store/client/actions";
import { setShowConfirmDeleteModal } from "../store/delete/actions";
const delObject = "Prospect";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.selectSearchClient = this.selectSearchClient.bind(this);
  }
  componentDidMount() {
    this.props.getClients();
  }
  selectSearchClient(client) {
    this.props.history.push(`/clients/${client._id}`);
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Prospects</div>
            <div className="text-right flex1">
              <button onClick={() => this.props.setShowCreateClientModal(true)} className="btn btn-success create-item"><i className="ion ion-md-add-circle"></i> <span>Add New Prospect</span></button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <div className="mb-3">
                    <AutoCompleteInput
                      className="table-search"
                      value={this.props.clientSearchInput || ""}
                      onInputChange={this.props.onInputChange}
                      onSelect={this.selectSearchClient}
                      search={this.props.searchClient}
                      name="clientSearchInput"
                      placeholder="Search Prospect"
                      items={this.props.searchedClients}
                      valueProperty="display_name"
                    />
                  </div>
                  <ClientsTable delete={this.props.setShowConfirmDeleteModal} clients={this.props.clients} pagination={this.props.pagination} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateClientModal/>
        <ConfirmDeleteModal />
        <DeleteSuccessModal delObject={delObject}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { clients, pagination, clientSearchInput, searchedClients, loading } = state.Client;
  return { clients, pagination, clientSearchInput, searchedClients, loading };
};

export default withRouter(connect(mapStateToProps, { setShowCreateClientModal, setShowConfirmDeleteModal, getClients, searchClient, onInputChange })(Clients));