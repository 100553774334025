import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { getTermsAndConditionTemplates } from "../store/termsAndConditionTemplate/actions";
import { setShowConfirmDeleteModal } from "../store/delete/actions";
import TermsAndConditionListTable from "../components/TermsAndConditionListTable";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
const delObject = "Terms & Conditions";
class TermsAndConditions extends Component {
  componentDidMount() {
    this.props.getTermsAndConditionTemplates();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Terms & Conditions</div>
            <div className="text-right flex1">
                <button onClick={() => this.props.history.push("/terms-and-conditions/new")} className="btn btn-success create-item"><i className="ion ion-md-add-circle"></i> <span>Add New Terms & Conditions</span></button>
              </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <TermsAndConditionListTable
                    delete={this.props.setShowConfirmDeleteModal}
                    termsAndConditionTemplates={this.props.termsAndConditionTemplates}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDeleteModal />
        <DeleteSuccessModal delObject={delObject} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    termsAndConditionTemplates, loading
  } = state.TermsAndConditionTemplate;
  return {
    termsAndConditionTemplates, loading
  };
}

export default withRouter(connect(mapStateToProps, { getTermsAndConditionTemplates, setShowConfirmDeleteModal })(TermsAndConditions));
