import React, { Component } from "react";
import "./style.css";
import "./style-m.css";
import logoImg from "./assets/outbooks-logo.webp";
import loginImg from "./assets/login.png";
import iconGoogle from "./assets/icon-google.png";
import iconMicrosoft from "./assets/icon-microsoft.png";
import iconFacebook from "./assets/Icon-Facebook-White.png";
import iconX from "./assets/Icon-X-White.png";
import iconLinkedin from "./assets/Icon-Linkedin-White.png";
import iconYoutube from "./assets/Icon-YouTube-White.png";
import { getValidator } from "../../Utils";
import {
  loginUser,
  loginUserWithProvider,
  resetLoginForm,
} from "../../store/actions";
import { onInputChange } from "../../store/auth/login/actions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import Loader from "../../components/Loader";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = {
      // showSuccessAlert: false, // Added state to control the SweetAlert visibility
      // redirectToLogin: false,
      isVisible: true, // Define isVisible state variable for mbMenu
    };    
  }
  handleValidSubmit(event, values) {
    if (this.validator.allValid()) {
      this.props.loginUser(
        this.props.email,
        this.props.password,
        this.props.history
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }
  componentDidMount() {
    this.props.resetLoginForm();
  }
  componentWillUnmount() {
    this.props.resetLoginForm();
  }

  handleMicrosoftLogin = () => {
    this.props.loginUserWithProvider("microsoft", this.props.history);
  };

  handleGoogleLogin = () => {
    this.props.loginUserWithProvider("google", this.props.history);
  };

  toggleVisibility = () => {
    const divElement = document.getElementById('mbMenu');
    if (divElement) {
      divElement.style.display = this.isVisible ? 'none' : 'block';
      //setIsVisible(!isVisible);
      this.setState(prevState => ({ isVisible: !prevState.isVisible }));
    }
  };
  
  render() {
    return (
      <React.Fragment>
        <div>
        <header className="lnheader">
            <div className="lnnav-bar">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks" />
                </a>
                <div className="mbMenuCont">
                  <button className="mbTgl" onClick={this.toggleVisibility}>&#9776;</button>

                  <div id="mbMenu" className="lnmenu mb-lnmenu" style={{ display: this.state.isVisible ? 'none' : 'block' }}>
                    <ul className="lnul">
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                      </li>
                      <li className="tosb-nav">
                        <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                        <ul class="sb-nav">
                          <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                        </ul>
                      </li>
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                      </li>
                      <li>
                        <a className="lna" href="/signIn">Login</a>
                      </li>
                      <li>
                        <a className="lna" href="/signup">Signup</a>
                      </li>
                      <li>
                        <a className="lna" href="https://calendly.com/amit-outbooks/outbooksproposal" target="_Blank">Book a Free Demo</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="lnmenu lp-lnmenu">
                <ul className="lnul">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                    <ul class="sb-nav">
                      <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                    </ul>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <div className="lnbtn lp-lnmenu">
                <a href="signup"  class="top3btn t3btn3">SIGNUP</a>
                <a href="signIn"  class="top3btn t3btn2">LOGIN</a>   
                <a href="https://calendly.com/amit-outbooks/outbooksproposal" target="_blank" class="top3btn t3btn1">BOOK A FREE DEMO</a>
              </div>
            </div>
          </header>
          {this.props.loading ? <Loader /> : null}
          <div className="lnmain-content">
            <section className="lnsection-one">
              <div className="lnhead-panel">
                <div className="lntext lnleft">
                  <h1 className="lnh1 martno">Welcome Back</h1>
                  <p className="lnp">Sign in to continue to Outbooks Proposal</p>
                </div>
              </div>
              <div className="lnlogin-panel">
                <a className="lna" name="login"></a>
                <div className="lnlogin-right">
                  <table className="lntable" border="0" cellPadding={5}>
                    <tbody>
                      <tr>
                        <td vertical-align="middle" width={300} align="center">
                          <img className="left-img-blk" src={loginImg} alt="Proposl Tool Login/Signup" />
                        </td>
                        <td width={25}></td>
                        <td>
                          <div className="sign-ar">
                            {this.props.error ? (
                              <Alert
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  color: "#F9060E",
                                }}
                              >
                                {this.props.error}
                              </Alert>
                            ) : null}

                            <form onSubmit={this.handleValidSubmit}>
                              <div className="row-f">
                                <input
                                  type="text"
                                  name="email"
                                  value={this.props.email || ""}
                                  onChange={(e) =>
                                    this.props.onInputChange(
                                      "email",
                                      e.target.value
                                    )
                                  }
                                  className="lninput lntext sign-f big martno"
                                  placeholder="Email*"
                                />
                              </div>

                              <div className="row-f">
                              {this.validator.message(
                                    "Email",
                                    this.props.email,
                                    "required|email",
                                    { className: "text-danger" }
                                  )}
                              </div>

                              <div
                                className="row-f">
                                <input
                                  type="password"
                                  name="password"
                                  value={this.props.password || ""}
                                  onChange={(e) =>
                                    this.props.onInputChange(
                                      "password",
                                      e.target.value
                                    )
                                  }
                                  className="lninput lntext sign-f big"
                                  placeholder="Password*"
                                />
                              </div>

                              <div className="row-f">
                                {this.validator.message(
                                    "Password",
                                    this.props.password,
                                    "required",
                                    { className: "text-danger" }
                                  )}</div>

                              <div className="row-f mart">
                                <button
                                  className="lnpage-btn lnlogin sign-f"
                                  onClick={this.handleValidSubmit}
                                >
                                  LOG IN
                                </button>
                              </div>
                            </form>

                            <div className="row-f ordiv mart">
                              <hr></hr>
                              <div className="or-devider">or</div>
                            </div>

                            <div className="row-f mart">
                              <div
                                className="btn-div sign-f"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleGoogleLogin();
                                }}
                              >
                                <tr>
                                  <td>
                                    <img
                                      src={iconGoogle}
                                      alt="Login with Google"
                                      height={30}
                                    />
                                  </td>
                                  <td>Login with Google</td>
                                </tr>
                              </div>
                              <div
                                className="btn-div sign-f"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleMicrosoftLogin();
                                }}
                              >
                                <tr>
                                  <td>
                                    <img
                                      src={iconMicrosoft}
                                      alt="Login with Microsoft"
                                      height={30}
                                    />
                                  </td>
                                  <td>Login with Microsoft</td>
                                </tr>
                              </div>
                            </div>

                            <div className="row-f">
                              <div className="tc-div sign-f">
                                <a
                                  href="forgot-password-new"
                                  className="lna tc-2 sign-f"
                                >
                                  &#x1F512;Forgot Password ?
                                </a>
                              </div>
                            </div>
                            <div className="row-f">
                              <div className="tc-div sign-f">
                                <span className="tc-2 sign-f">
                                  {" "}
                                  &nbsp;Don't have an account?
                                </span>{" "}
                                &nbsp;{" "}
                                <a className="lna" href="signup">
                                  <strong>SIGN UP</strong>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>

          <footer className="lnfooter">
            <div className="lncol-1">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks Proposal" />
                </a>
              </div>
              <div className="lnmenu">
                <ul className="lnul lnnav-list">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="signup">SIGN UP</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <p className="lnp lncopy-right">
                Copyright © Outbooks Proposal {new Date().getFullYear()} | All Rights Reserved
              </p>
            </div>
            <div className="lncol-2"><div className="lnmenu footer-m1">
                <ul className="lnul lnnav-list">
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/contact-us">Help Center</a>
                    </li>
                    <li>
                      <a className="lna" href="#">Blogs</a>
                    </li>
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/terms-and-conditions/">Terms and Conditions</a>
                    </li>
                  </ul>
              </div></div>
            <div className="lncol-3">
              <p className="lnp lnadr">
                Suite 18, Winsor & Newton Building,
                <br />
                Whitefriars Avenue, Harrow HA3 5RN
              </p>
              <p className="lnp lnregno">Registration No: 10746177</p>
              <ul className="lnul lncontact-links">
                <li>
                  <a className="lna" href="mailto:info@outbooks.com">
                    &#9993; &nbsp; info@outbooks.com
                  </a>
                </li>
                <li>
                  <a className="lna" href="tel:+443300578597">
                    &#9743; &nbsp; +44 330 057 8597
                  </a>
                </li>
              </ul>
              <ul className="lnul lnsocial-links">
                <li>
                  <a className="lna" href="https://www.facebook.com/profile.php?id=61556567720993">
                    <img src={iconFacebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.linkedin.com/company/outbooksproposal/">
                    <img src={iconLinkedin} alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://twitter.com/outbookproposal">
                    <img src={iconX} alt="Twitter / X" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.youtube.com/channel/UCCucVxt5QuYrJI6SDCDW7sQ">
                    <img src={iconYoutube} alt="Youtube" />
                  </a>
                </li>
              </ul>
              <a
                href="https://outbooks.com/proposal/contact-us/"
                className="lna lnpage-btn"
              >
                CONTACT US
              </a>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { email, password, error, loading } = state.Login;
  return { email, password, error, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    loginUser,
    loginUserWithProvider,
    onInputChange,
    resetLoginForm,
  })(SignIn)
);
