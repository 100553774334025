import React, { Component } from 'react';

class AccessKeysTable extends Component {
	constructor(props) {
		super(props);
		this.renderAccessKeys = this.renderAccessKeys.bind(this);
	}

	renderAccessKeys(item, index) {
		return (
			<tr key={index}>
				<td>{item.name}</td>
				<td>{item.token}</td>
				<td className="serivce-actions text-right">
					<button onClick={() => { console.log("kdsnfjkds"); this.props.delete(true, item._id, "accessKey")} } data-toggle="tooltip" title="Delete Access Key" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>
				</td>
			</tr>
		);
	}

	render() {
		return (
			<div className="table-rep-plugin">
				<div className="table-responsive mb-0" data-pattern="priority-columns">
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Name</th>
								<th>Token</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.accessKeys.map(this.renderAccessKeys)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default AccessKeysTable;