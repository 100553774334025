import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Paginator from "./Paginator";
import { getCategories, setShowCreateCategoryModal } from '../store/service/actions'
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
class CategoryListTable extends Component {
	constructor(props) {
		super(props);
		this.renderCategories = this.renderCategories.bind(this)
		this.editModal = this.editModal.bind(this)
		this.deleteModal = this.deleteModal
	}
	deleteModal(id, index){
		this.props.getCategories(id);
		alert(id);
	  
	  }
	editModal(id, index) {
		this.props.setShowCreateCategoryModal(true, "edit", id, index);
	}
	renderCategories(item, index) {
		return (
			<tr key={index}>
				<td>

				{item.name.substring(0,70)}
					
				</td>
				<td className="serivce-actions text-right">
					<button onClick={() => this.editModal.bind(this)(item._id, index)} data-id={item._id} data-index={index} data-toggle="tooltip" title="Edit Category" className="btn btn-sm btn-dark"><i className="ion ion-md-create" /></button>
					
					  <button
					// onClick={()=> console.log(item._id)}
					 onClick={() => {this.props.delete(true, item._id, "category"); 
					//  this.deleteModal(item._id)
					}}
					  data-toggle="tooltip"
					 title="Delete Category" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>
				</td>
			</tr>
		);
	}

	render() {
		return (
			this.props.categories.length > 0 ? (<div className="table-rep-plugin">
				<div className="table-responsive mb-0" data-pattern="priority-columns">
				
				 
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Categories</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.categories.map(this.renderCategories)}
						</tbody>
					</table>
					<Paginator
						totalPages={this.props.pagination.total_pages}
						pageNum={this.props.pagination.page_num}
						getObjects={this.props.getCategories}
					/>
				</div>
			</div>) : (<div className="text-center">No Categories Found!</div>)
		);
	}
}

export default connect(null, { getCategories, setShowCreateCategoryModal })(CategoryListTable);