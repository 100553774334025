import React, { Component } from "react";
import AutoCompleteInput from "../components/AutoCompleteInput";
import QuoteListTable from "../components/QuoteListTable";
import Loader from "../components/Loader";
import {
  getQuotes,
  onInputChange,
  searchQuote,
  resetSearchInput,
} from "../store/quote/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { setShowUpgradeModal } from "../store/common/actions";
import { getMyOrganisation } from "../store/organisation/actions";

class Quotes extends Component {
  constructor(props) {
    super(props);
    this.selectSearchQuote = this.selectSearchQuote.bind(this);

    const query = queryString.parse(this.props.location.search);
    this.state = { status: query.status };
    const filterQuery = queryString.parse(this.props.location.search);
    this.filterState = { filter: filterQuery.filter };
  }
  componentDidMount() {
    this.props.getMyOrganisation();
    this.props.getQuotes(0, this.state.status, this.filterState.filter);
    this.props.resetSearchInput();
  }
  selectSearchQuote(quote) {
    this.props.history.push(`/quotes/${quote._id}`);
  }

  render() {
    return (
      <React.Fragment>
        {(this.props.loading || this.props.subscriptionLoading) && <Loader />}
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Proposals</div>
            <div className="text-right flex1">
              <button
                onClick={() => {
                  // this.props.history.push("/quotes/new");
                  const should_allow = this.props.allow_prepare_quote;
                  should_allow
                    ? this.props.history.push("/quotes/new")
                    : this.props.setShowUpgradeModal(true);
                }}
                className="btn btn-success create-item"
              >
                <i className="ion ion-md-add-circle"></i>{" "}
                <span>Create New Proposal</span>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <div className="mb-3">
                    <AutoCompleteInput
                      className="table-search"
                      value={this.props.quoteSearchInput || ""}
                      onInputChange={this.props.onInputChange}
                      onSelect={this.selectSearchQuote}
                      search={this.props.searchQuote}
                      name="quoteSearchInput"
                      placeholder="Search Proposal"
                      items={this.props.searchedQuotes}
                      valueProperty="prefix,code"
                    />
                  </div>

                  {!this.filterState.filter ||
                    (this.filterState.filter !== undefined &&
                      this.filterState.filter !== "All" && (
                        <p style={{ marginLeft: 300, marginTop: -45 }}>
                          Showing results for your selected filter:{" "}{this.filterState.filter},{" "}
                          {this.state.status} Proposals. Click{" "}
                          <a href="/quotes">here</a> to display all Proposals.
                        </p>
                      ))}
                      
                  <QuoteListTable
                    quotes={this.props.quotes}
                    pagination={this.props.pagination}
                    status={this.state.status}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { quotes, pagination, quoteSearchInput, searchedQuotes, loading } =
    state.Quote;
  const { loading: subscriptionLoading, allow_prepare_quote } =
    state.Organisation;
  return {
    quotes,
    pagination,
    quoteSearchInput,
    searchedQuotes,
    loading,
    subscriptionLoading,
    allow_prepare_quote,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getQuotes,
    onInputChange,
    searchQuote,
    setShowUpgradeModal,
    getMyOrganisation,
    resetSearchInput,
  })(Quotes)
);
