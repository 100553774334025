import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import CreateGlobalPricingDriversModal from "../../components/Settings/CreateGlobalPricingDriversModal";
import GlobalPricingDriversTable from "../../components/Settings/GlobalPricingDriversTable";
import { setShowConfirmDeleteModal } from "../../store/delete/actions";
import { getGlobalPricingDriver, getGlobalPricingDrivers, setShowCreateGlobalPricingDriverModal } from "../../store/service/actions";

class GlobalPricingDrivers extends Component {
	componentDidMount() {
		this.props.getGlobalPricingDrivers('pricingDriver');
	}
	render() {
    	return (
			<React.Fragment>
		        <div className="page-info-header">
		          <div className="container-fluid">
		            <div className="page-display-title">Global Pricing Drivers</div>
		            <div className="text-right flex1">
		            	<button onClick={() => this.props.setShowCreateGlobalPricingDriverModal(true)} className="btn btn-success create-item"><i className="ion ion-md-add-circle"></i> <span>Add New Driver</span></button>
		            </div>
		          </div>
		        </div>
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-12">
		              <div className="card cus-card">
		                <div className="card-body">
							<GlobalPricingDriversTable delete={this.props.setShowConfirmDeleteModal} getGlobalPricingDriver={this.props.getGlobalPricingDriver} setShowCreateGlobalPricingDriverModal={this.props.setShowCreateGlobalPricingDriverModal} drivers={this.props.globalDrivers} linkedServices={this.props.linkedServices} {...this.props}/>
						</div>
		              </div>
		            </div>
		          </div>
		        </div>
		        <CreateGlobalPricingDriversModal />
		        {this.props.loading && <Loader />}
			</React.Fragment>
    	);
	}
}

const mapStateToProps = state => {
	const { globalDrivers, loading ,linkedServices } = state.Service;
	const { showConfirmDeleteModal } = state.Delete;
	return { globalDrivers, loading,linkedServices,showConfirmDeleteModal };
}

export default connect(mapStateToProps, {getGlobalPricingDriver, getGlobalPricingDrivers, setShowCreateGlobalPricingDriverModal, setShowConfirmDeleteModal})(GlobalPricingDrivers);
