import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import { apiBaseUrl } from './config';
import GoogleAnalytics from './GoogleAnalytics';
import MaintenanceMode from './MaintenanceMode';

axios.defaults.baseURL = apiBaseUrl;
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';


const app = (
    <Provider store={store}>
        <BrowserRouter>
        <GoogleAnalytics/>
             <App /> 
           {/* <MaintenanceMode/> */}
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
