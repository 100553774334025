import React, { Component } from "react";
import Select from "react-select";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import MyEditor from "../components/MyEditor";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectServices from "../components/SelectServices";
import ReviewServices from "../components/ReviewServices";
import {
  Modal, ModalBody
} from 'reactstrap';
import {
  setActiveStep,
  onInputChange,
  addServiceToContract,
  removeServiceFromContract,
  previewContract,
  sendContract,
  saveContractAsDraft,
  getContract,
  resetCreateNewContractForm,
  setShowCreateNewContractSuccessModal,
  addSignatory,
  deleteSignatory,
  setSignatories,
  reviewContract,
  getTncTemplates,
  getAvailablePaymentGateways,
} from "../store/contract/actions";
import { getSentQuotes, getSkipQuotes } from "../store/quote/actions";
import { getClients } from "../store/client/actions";
import { getTemplates } from "../store/template/actions";
import {
  getServicesByCategory,
  getGlobalDriversByServices,
  resetCreateNewServiceForm,
} from "../store/service/actions";
import {
  getAvailablePaymentMethods,
  setShowUpgradeModal,
} from "../store/common/actions";
import ContractSignatory from "../components/ContractSignatory";
import StepperWidget from "../components/StepperWidget";
import RequiredGlobalPricingDrivers from "../components/RequiredGlobalPricingDrivers";
import Loader from "../components/Loader";
import {
  buildCalculatePricingJson,
  buildGlobalPricingDriversJson,
  getValidator,
  checkPricingDriversValid,
  checkGlobalPricingDriversValid,
  formatCurrency,
  getPaymentFrequencyMulitplier,
} from "../Utils";
import {
  contractSourceOptions,
  paymentFrequencyOptions,
  showFullBreakdownOptions,
} from "../config";
import {
  getMyOrganisation,
  getPricingSettings,
  updatePricingSettings,
  onInputChange as onOrgInputChange,
} from "../store/organisation/actions";

class CreateNewContract extends Component {
  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.reviewPricing = this.reviewPricing.bind(this);
    this.validateContract = this.validateContract.bind(this);
    this.validateSignatories = this.validateSignatories.bind(this);
    this.updateStepperWarnings = this.updateStepperWarnings.bind(this);
    this.getSignatoriesData = this.getSignatoriesData.bind(this);
    this.setSignatories = this.setSignatories.bind(this);
    this.setContractClientBusinessType =
      this.setContractClientBusinessType.bind(this);
    this.validator = getValidator();
    this.tncEditor = React.createRef();
    this.renderFeesInProposal = this.renderFeesInProposal.bind(this);
    this.customBackStep = this.customBackStep.bind(this);
    this.updateOneOffPercentageDiscount =
      this.updateOneOffPercentageDiscount.bind(this);
    this.updatePercentageOneOff = this.updatePercentageOneOff.bind(this);
    this.updatePercentageRecurringPrice =
      this.updatePercentageRecurringPrice.bind(this);
    this.updateRecurringPricePercentageDiscount =
      this.updateRecurringPricePercentageDiscount.bind(this);
    this.updateOrganisation = this.updateOrganisation.bind(this);
    this.state = {
      validPricingDrivers: false,
      validContract: false,
      warningSteps: [],
      hasFocused: false,
      hasFocusedOneOff: false,
      flag: true,
      isReviewApiCalled: false,
      reCalculatePrice: false,
      discountedPrice: 0,
      oneOffDiscountedPrice: 0,
      valid: true,
      hasBlurred: true,
      hasBlurredOneOff: true,
      sv: false,
      discountUpdated: false,
      currentActiveStep: 0,
      oneOffDiscountedPricePercentage: 0,
      recurringPriceDiscountedPricePercentage: 0,
      currentDiscountPrice: 0,
      updatedPercentageOnce: false,
      currentPerMonthValue: 12,
      showPricingModel: false,
      minPricingState: 0,
      minPricingMonthState: 0,
      maxDiscountState: 0,
      showFullBreakdownOptions: [
        { value: true, label: "Show Full Breakdown", isdisabled: true },
        { value: false, label: "Show Totals Only" },
      ]
    };
  }
  renderFeesInProposal() {
    return (
      <React.Fragment>
        <div className="row fieldset">
          <div className="col-2 text-right">
            <label className="fieldset-label">Fees in the Proposal</label>
          </div>
          <div className="col-10">
            <Select
              options={[
                { value: true, label: "Show Full Breakdown", isdisabled: this.props.packageRecurringPrice > this.props.recurringTotal || this.props.packageOneOffPrice > this.props.oneOffTotal },
                { value: false, label: "Show Totals Only" },
              ]}
              onChange={(selectedOption) => {
                this.props.onInputChange(
                  "showFullBreakdown",
                  selectedOption.value
                );
                if (selectedOption.value && this.props.recurringTotal < this.props.packageRecurringPrice) {
                  this.props.onInputChange(
                    "packageRecurringPrice",
                    this.props.recurringTotal,
                  );
                  this.props.onInputChange(
                    "OriginalPackageRecurringPrice",
                    this.props.recurringTotal
                  );
                }
                // this.props.onInputChange(
                // this.setState({
                //   flag: selectedOption.value === true ? true : false,
                // });
                // );
              }}
              value={this.state.showFullBreakdownOptions.filter(
                (option) => option.value === this.props.showFullBreakdown
              )}
              isOptionDisabled={(option) => option.isdisabled}
            />
          </div>
        </div>
        <div className="separator mb-2" />
      </React.Fragment>
    );
  }
  renderSignatories() {
    return this.props.signatories.map((item, index) => (
      <ContractSignatory
        onInputChange={this.props.onInputChange}
        delete={this.props.deleteSignatory}
        code={item}
        key={index}
        index={index}
        firstName={this.props[`signatory${item}FirstName`]}
        lastName={this.props[`signatory${item}LastName`]}
        email={this.props[`signatory${item}Email`]}
        signaturePosition={this.props[`signatory${item}SignaturePosition`]}
        validator={this.validator}
      />
    ));
  }
  generateNewSignatoryCode() {
    return new Date().getTime();
  }
  cancel() {
    // this.props.history.goBack();
    this.props.history.push({
      pathname: '/contracts'
    })
  }
  nextStep() {
    this.validator.hideMessages();
    this.setState({ warningSteps: [] });
    this.forceUpdate();
    this.updateStepperWarnings();

    console.log(this.props.activeStep);
    if (this.props.activeStep === 1) {
      if (
        (this.props.contractSource &&
          this.props.contractSource === "fromQuote" &&
          this.props.contractClient &&
          this.props.contractQuote &&
          this.props.selectedQuoteType &&
          this.props.contractTemplate) ||
        (this.props.contractSource &&
          this.props.contractSource === "fromScratch" &&
          this.props.contractClient &&
          this.props.contractTemplate)
      ) {
        this.setState({ warningSteps: [] });
        this.props.setActiveStep(this.props.activeStep + 1);
      } else {
        this.validator.showMessages();
        this.setState({ warningSteps: [1] });
      }
    } else if (
      (this.props.contractSource === "fromQuote" &&
        this.props.activeStep == 2) ||
      (this.props.contractSource === "fromScratch" &&
        this.props.activeStep == 3)
    ) {
      if (
        this.validateSignatories() &&
        this.getSignatoriesData()[0].firstName !== "" &&
        this.getSignatoriesData()[0].lastName !== "" &&
        this.getSignatoriesData()[0].email !== "" &&
        checkPricingDriversValid(
          this.props.contractPricingDrivers,
          this.props.servicesByCategory,
          this.validator,
          this.props
        ) &&
        checkGlobalPricingDriversValid(
          this.props.requiredGlobalPricingDrivers,
          this.validator,
          this.props
        )
      ) {
        this.setState({ warningSteps: [] });
        this.setState({ sv: false });
        this.props.setActiveStep(this.props.activeStep + 1);
      } else {
        this.setState({ sv: true });
        this.setState({ warningSteps: [this.props.activeStep] });
        this.validator.showMessages();
      }

      // this.props.setActiveStep(this.props.activeStep + 1);
    } else if (
      this.props.contractSource === "fromScratch" &&
      this.props.activeStep == 2
    ) {
      console.log("this.props - ", this.props, this.validator);
      let next = false;
      console.clear();
      for (let driver of this.props.contractPricingDrivers) {
        if (this.props[`${driver}`]) {
          next = true;
        } else {
          next = false;
          break;
        }
      }
      if (this.props.contractPricingDrivers.length === 0) {
        next = true;
      }
      if (this.props.contractServices.length > 0) {
        if (next) {
          this.props.setActiveStep(this.props.activeStep + 1);
          this.setState({ warningSteps: [] });
        } else {
          this.setState({
            valid: true,
          });
          this.setState({ warningSteps: [2] });
          this.validator.showMessages();
        }
      } else {
        this.setState({
          valid: false,
        });
        this.setState({ warningSteps: [2] });
      }
    } else if (
      this.props.activeStep === 3 &&
      ((this.props.contractSource &&
        this.props.contractSource === "fromQuote" &&
        this.props.contractPaymentMethod &&
        (this.props.maxDiscount &&
          this.props.maxDiscount > 0 &&
          this.props.recurringTotal > 0
          ? this.validator.fields["packageRecurringPricePercentage"]
          : true) &&
        (this.props.maxDiscount &&
          this.props.maxDiscount > 0 &&
          this.props.oneOffTotal > 0
          ? this.validator.fields["packageOneOffPricePercentage"]
          : true) &&
        (this.props.minPricingMonth &&
          this.props.minPricingMonth > 0 &&
          this.props.recurringTotal > 0
          ? this.validator.fields["packageRecurringPrice"]
          : true) &&
        (this.props.minPricing &&
          this.props.minPricing > 0 &&
          this.props.oneOffTotal > 0
          ? this.validator.fields["packageOneOffPrice"]
          : true)) ||
        (this.props.contractSource &&
          this.props.contractSource === "fromScratch"))
    ) {
      if (this.props.contractSource === "fromScratch") {
        let next = false;
        for (let driver of this.props.requiredGlobalPricingDrivers) {
          if (this.props[`globalPricingDriver-${driver._id}`]) {
            next = true;
          } else {
            next = false;
            break;
          }
        }
        next =
          this.props.requiredGlobalPricingDrivers.length === 0 ? true : next;
        if (
          next &&
          this.getSignatoriesData()[0].firstName !== "" &&
          this.getSignatoriesData()[0].lastName !== "" &&
          this.getSignatoriesData()[0].email !== ""
        ) {
          this.setState({ warningSteps: [] });
          this.setState({ sv: false });
          this.props.setActiveStep(this.props.activeStep + 1);
        } else {
          this.setState({ sv: true });
          this.setState({ warningSteps: [3] });
          this.validator.showMessages();
        }
      } else if (this.props.contractSource === "fromQuote") {
        if (
          this.getSignatoriesData()[0].firstName !== "" &&
          this.getSignatoriesData()[0].lastName !== "" &&
          this.getSignatoriesData()[0].email !== ""
        ) {
          this.setState({ warningSteps: [] });
          this.setState({ sv: false });
          this.props.setActiveStep(this.props.activeStep + 1);
        } else {
          this.setState({ sv: true });
          this.setState({ warningSteps: [2] });
        }
        // this.props.setActiveStep(this.props.activeStep + 1);
      }
    } else if (
      this.props.activeStep === 4 &&
      this.props.contractSource &&
      this.props.contractSource === "fromScratch" &&
      this.props.contractPaymentMethod &&
      (this.props.maxDiscount &&
        this.props.maxDiscount > 0 &&
        this.props.recurringTotal > 0
        ? this.validator.fields["packageRecurringPricePercentage"]
        : true) &&
      (this.props.maxDiscount &&
        this.props.maxDiscount > 0 &&
        this.props.oneOffTotal > 0
        ? this.validator.fields["packageOneOffPricePercentage"]
        : true) &&
      (this.props.minPricingMonth &&
        this.props.minPricingMonth > 0 &&
        this.props.recurringTotal > 0
        ? this.validator.fields["packageRecurringPrice"]
        : true) &&
      (this.props.minPricing &&
        this.props.minPricing > 0 &&
        this.props.oneOffTotal > 0
        ? this.validator.fields["packageOneOffPrice"]
        : true) &&
      (this.props.packageRecurringPrice ||
        this.props.packageOneOffPrice ||
        this.props.recurringTotal ||
        this.props.oneOffTotal)
    ) {
      this.props.setActiveStep(this.props.activeStep + 1);
    } else if (this.props.activeStep === 5) {
    } else {
      this.validator.showMessages();
    }
    this.forceUpdate();
  }
  backStep() {
    this.setState({ warningSteps: [] });
    this.setState({ sv: false });
    this.props.setActiveStep(this.props.activeStep - 1);
    if (this.state.isReviewApiCalled) {
      this.setState({
        isReviewApiCalled: false
      })
    }
  }
  reviewPricing(prevFrequency) {
    this.setState({ hasFocused: false });
    console.log(
      "this.props.paymentFrequency - ",
      this.props,
      this.props.paymentFrequency,
      this.state.discountedPrice
    );
    let data = {
      source: this.props.contractSource,
      // paymentFrequency: this.props.paymentFrequency,
    };
    if (this.props.contractSource === "fromScratch") {
      data.services = buildCalculatePricingJson(
        this.props.contractServices,
        this.props.servicesByCategory,
        this.props.contractPricingDrivers,
        this.props,
        this.props.currentPaymentFrequency
      );
      data.globalPricingDrivers = buildGlobalPricingDriversJson(
        this.props.requiredGlobalPricingDrivers,
        this.props
      );
      // console.log(
      //   "this.state.discountedPrice = ",
      //   this.state.discountedPrice,
      //   this.props.packageRecurringPrice,
      //   this.props.recurringTotal,
      //   this.state.viaSelect,
      //   this.props.paymentFrequency,
      //   prevFrequency
      // );
      // data.packageRecurringPrice = this.isArrEqual()
      //   ? parseFloat(
      //       this.state.discountedPrice > 0 && !this.state.viaSelect
      //         ? this.state.discountedPrice
      //         : this.props.recurringTotal !==
      //             this.props.packageRecurringPrice &&
      //           parseInt(this.props.recurringTotal) > 0
      //         ? this.props.recurringTotal
      //         : this.props.packageRecurringPrice *
      //           (1 /
      //             getPaymentFrequencyMulitplier(
      //               prevFrequency && prevFrequency.length > 0
      //                 ? prevFrequency
      //                 : this.props.paymentFrequency
      //             ))
      //     )
      //   : 0;
      // if (this.state.recurringPriceDiscountedPricePercentage > 0) {
      //   data.packageRecurringPrice = this.state.discountedPrice ?? this.props.packageRecurringPrice;
      // } else {
      const value = this.props.packageRecurringPrice;
      console.log('spec_checdssd', value, this.state.discountedPrice, this.props.packageRecurringPrice)
      data.packageRecurringPrice = value > this.props.recurringTotal ? value : null;
      console.log('spec_checdssd', data.packageRecurringPrice, this.props.recurringTotal);
      data.packageOneOffPrice = this.props.packageOneOffPrice > this.props.oneOffTotal ? this.props.packageOneOffPrice : null;
      // }
    } else if (this.props.contractSource === "fromQuote") {
      data.quoteId = this.props.contractQuote;
      console.log("this.state.discountedPrice - ", this.state.discountedPrice);
      // if (this.state.recurringPriceDiscountedPricePercentage > 0) {
      //   data.packageRecurringPrice = this.state.discountedPrice ?? this.props.packageRecurringPrice;
      // } else {
      const value = this.props.packageRecurringPrice;
      console.log('spec_checdssd', value, this.state.discountedPrice, this.props.packageRecurringPrice)
      data.packageRecurringPrice = value > this.props.recurringTotal ? value : null;
      console.log('spec_checdssd', data.packageRecurringPrice, this.props.recurringTotal);
      // }
      data.packageOneOffPrice = this.props.packageOneOffPrice > this.props.oneOffTotal ? this.props.packageOneOffPrice : null;
      data.quoteType = this.props.selectedQuoteType;
      data.services = buildCalculatePricingJson(
        this.props.contractServices,
        this.props.servicesByCategory,
        this.props.contractPricingDrivers,
        this.props,
        this.props.currentPaymentFrequency
      );
      if (
        this.props.selectedQuoteType === "packagedPricing" ||
        this.props.selectedQuoteType === "customPackagedPricing"
      ) {
        data.quotePackageId = this.props.contractQuotePackage;
        data.quotePackageName = this.props.sentQuotes.filter(
          (item) => item._id === this.props.contractQuote
        )[0]?.data?.packagedPricingSelectedPackages[
          this.props.contractQuotePackage
        ].name;
      }
    }
    this.props.reviewContract(data);
  }
  validateSignatories() {
    let valid = true;
    for (let signatory of this.props.signatories) {
      if (
        !(
          this.validator.fields[`signatory${signatory}FirstName`] &&
          this.validator.fields[`signatory${signatory}LastName`] &&
          this.validator.fields[`signatory${signatory}Email`] &&
          this.validator.fields[`signatory${signatory}SignaturePosition`]
        )
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  validateContract() {
    console.log("this.validator - ", this.validator);
    if (this.props.activeStep == 1) {
      if (
        (this.props.contractSource === "fromScratch" &&
          this.validator.fields["contractClient"] &&
          this.validator.fields["contractTemplate"]) ||
        (this.props.contractSource === "fromQuote" &&
          this.validator.fields["contractQuote"] &&
          this.validator.fields["contractTemplate"])
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      (this.props.contractSource === "fromQuote" &&
        this.props.activeStep == 2) ||
      (this.props.contractSource === "fromScratch" &&
        this.props.activeStep == 3)
    ) {
      return this.validateSignatories();
    } else if (
      this.props.contractSource === "fromScratch" &&
      this.props.activeStep == 2
    ) {
      if (
        this.props.contractServices &&
        this.props.contractServices.length > 0
      ) {
        return true;
      }
    }
    if (
      this.props.signatories.length > 0 &&
      this.validator.fields["contractPaymentMethod"] &&
      ((this.props.contractSource === "fromScratch" &&
        checkPricingDriversValid(
          this.props.contractPricingDrivers,
          this.props.servicesByCategory,
          this.validator,
          this.props
        ) &&
        checkGlobalPricingDriversValid(
          this.props.requiredGlobalPricingDrivers,
          this.validator,
          this.props
        ) &&
        this.validator.fields["contractClient"] &&
        this.validator.fields["contractTemplate"] &&
        (this.props.maxDiscount &&
          this.props.maxDiscount > 0 &&
          this.props.recurringTotal > 0
          ? this.validator.fields["packageRecurringPricePercentage"] !== false
          : true) &&
        (this.props.maxDiscount &&
          this.props.maxDiscount > 0 &&
          this.props.oneOffTotal > 0
          ? this.validator.fields["packageOneOffPricePercentage"] !== false
          : true) &&
        (this.props.minPricingMonth &&
          this.props.minPricingMonth > 0 &&
          this.props.recurringTotal > 0
          ? this.validator.fields["packageRecurringPrice"] !== false
          : true) &&
        (this.props.minPricing &&
          this.props.minPricing > 0 &&
          this.props.oneOffTotal > 0
          ? this.validator.fields["packageOneOffPrice"] !== false
          : true)) ||
        (this.props.contractSource === "fromQuote" &&
          this.validator.fields["contractQuote"] &&
          this.validator.fields["contractTemplate"] &&
          (this.props.maxDiscount &&
            this.props.maxDiscount > 0 &&
            this.props.recurringTotal > 0
            ? this.validator.fields["packageRecurringPricePercentage"] !== false
            : true) &&
          (this.props.maxDiscount &&
            this.props.maxDiscount > 0 &&
            this.props.oneOffTotal > 0
            ? this.validator.fields["packageOneOffPricePercentage"] !== false
            : true) &&
          (this.props.minPricingMonth &&
            this.props.minPricingMonth > 0 &&
            this.props.recurringTotal > 0
            ? this.validator.fields["packageRecurringPrice"] !== false
            : true) &&
          (this.props.minPricing &&
            this.props.minPricing > 0 &&
            this.props.oneOffTotal > 0
            ? this.validator.fields["packageOneOffPrice"] !== false
            : true)))
    ) {
      return true;
    } else {
      return false;
    }
  }
  updateStepperWarnings() {
    console.log("hiii");
  }
  getSignatoriesData() {
    let signatoriesData = [];
    for (let signatory of this.props.signatories) {
      signatoriesData.push({
        firstName: this.props[`signatory${signatory}FirstName`],
        lastName: this.props[`signatory${signatory}LastName`],
        email: this.props[`signatory${signatory}Email`],
        signaturePosition: this.props[`signatory${signatory}SignaturePosition`],
      });
    }
    return signatoriesData;
  }
  formatDataAndSubmitContract(action) {
    // reviewPricing.call(this);
    // force user to show review service before submit
    if (this.validateContract() && this.validateSignatories()) {
      if (action === "send") {
        const should_allow =
          this.props.allow_send_contract &&
          this.props.left_signed_contracts > 0;
        if (!should_allow)
          return this.props.setShowUpgradeModal(
            this.props.allow_send_contract
              ? this.props.left_signed_contracts > 0
                ? true
                : "sign"
              : true
          );

        console.log("coming here send");
        const now = new Date();
        const data = {
          source: this.props.contractSource,
          template: this.props.contractTemplate,
          selectedServices: this.props.reviewedPrice,
          creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
          showFullBreakdown: this.props.showFullBreakdown,
          // packageRecurringPrice:
          //   this.props.packageRecurringPrice ||
          //   this.props.final_recurring_total ||
          //   this.props.recurringTotal,
          // packageOneOffPrice:
          //   this.props.packageOneOffPrice ||
          //   this.props.final_oneOff_total ||
          //   this.props.oneOffTotal,
          packageRecurringPrice: this.props.packageRecurringPrice,
          packageOneOffPrice: this.props.packageOneOffPrice,
          signatories: this.getSignatoriesData(),
          paymentMethod: this.props.contractPaymentMethod,
          tnc: this.props.tnc,
          tncType: this.props.tncType,
        };

        if (this.props.tncType === "pdf") {
          data["template_url"] = this.props.template_url;
        }
        if (this.props.contractSource === "fromScratch") {
          data.client = this.props.contractClient;
          data.paymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.currentPaymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
        } else if (this.props.contractSource === "fromQuote") {
          data.paymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.currentPaymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.quoteId = this.props.contractQuote;
          data.quoteType = this.props.selectedQuoteType;
          let globalDrivers = [];
          for (let key in this.props.globalPricingDrivers) {
            globalDrivers.push({
              ...this.props.globalPricingDrivers[key],
              _id: key,
            });
          }
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            globalDrivers,
            this.props
          );
          if (
            this.props.selectedQuoteType === "packagedPricing" ||
            this.props.selectedQuoteType === "customPackagedPricing"
          ) {
            data.quotePackageId = this.props.contractQuotePackage;
            data.quotePackageName = this.props.sentQuotes.filter(
              (item) => item._id === this.props.contractQuote
            )[0]?.data?.packagedPricingSelectedPackages[
              this.props.contractQuotePackage
            ].name;
          }
        }
        if (this.type === "new") {
          data.isNew = true;
        } else if (this.type === "edit") {
          data.isNew = false;
          data.id = this.props.contractId;
        }
        this.props.sendContract(data);
      } else if (action === "draft") {
        console.log("coming here draft");
        const data = {
          source: this.props.contractSource,
          template: this.props.contractTemplate,
          tncTemplate: this.props.tncTemplate,
          selectedServices: buildCalculatePricingJson(
            this.props.contractServices,
            this.props.servicesByCategory,
            this.props.contractPricingDrivers,
            this.props
          ),
          showFullBreakdown: this.props.showFullBreakdown,
          recurringTotal: this.props.recurringTotal,
          // packageRecurringPrice:
          //   this.props.packageRecurringPrice ||
          //   this.props.recurringTotal ||
          //   this.props.final_recurring_total,
          // packageOneOffPrice:
          //   this.props.packageOneOffPrice ||
          //   this.props.final_oneOff_total ||
          //   this.props.oneOffTotal,
          packageRecurringPrice: this.props.packageRecurringPrice,
          packageOneOffPrice: this.props.packageOneOffPrice,
          signatories: this.getSignatoriesData(),
          paymentMethod: this.props.contractPaymentMethod,
          tnc: this.props.tnc,
          tncType: this.props.tncType,
          oneOffTotal: this.props.oneOffTotal,
          oneOffDiscountPercentage: this.props.oneOffDiscountPercentage,
          recurringDiscountPercentage: this.props.recurringDiscountPercentage,
        };
        if (this.props.contractSource === "fromScratch") {
          data.client = this.props.contractClient;
          data.paymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.currentPaymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.globalPricingDrivers = buildGlobalPricingDriversJson(
            this.props.requiredGlobalPricingDrivers,
            this.props
          );
          data.tempPricingDrivers = this.props.tempPricingDrivers;
        } else if (this.props.contractSource === "fromQuote") {
          data.paymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.currentPaymentFrequency =
            this.props.currentPaymentFrequency &&
              this.props.currentPaymentFrequency.trim().length > 0
              ? this.props.currentPaymentFrequency
              : "yearly";
          data.quoteId = this.props.contractQuote;
          data.quoteType = this.props.selectedQuoteType;
          if (
            this.props.selectedQuoteType === "packagedPricing" ||
            this.props.selectedQuoteType === "customPackagedPricing"
          ) {
            data.quotePackageId = this.props.contractQuotePackage;
            data.quotePackageName = this.props.sentQuotes.filter(
              (item) => item._id === this.props.contractQuote
            )[0]?.data?.packagedPricingSelectedPackages[
              this.props.contractQuotePackage
            ].name;
          }
        }
        if (this.type === "new") {
          data.isNew = true;
        } else if (this.type === "edit") {
          data.isNew = false;
          data.id = this.props.contractId;
        }
        this.props.saveContractAsDraft(data);
      }
    } else {
      console.log("coming here else");
      this.validator.showMessages();
      this.updateStepperWarnings();
      this.forceUpdate();
    }
  }
  getPDFContractPreview() {
    let reviewPrices = this.props.reviewedPrice;
    if (this.props.paymentFrequency != this.props.currentPaymentFrequency) {
      let yearlyPrice = {
        yearly: (val) => val,
        halfYearly: (val) => val * 2,
        quarterly: (val) => val * 4,
        monthly: (val) => val * 12,
      };
      let calculateRecurringPrice = {
        yearly: (val) => val,
        halfYearly: (val) => val / 2,
        quarterly: (val) => val / 4,
        monthly: (val) => val / 12,
      };
      for (let categoryKey in reviewPrices) {
        let serviceJsx = [];
        for (let serviceKey in reviewPrices[categoryKey].services) {
          let serviceChargeType = serviceKey.split("_")[1];
          if (serviceChargeType == 'recurring') {
            let serviceAmount = reviewPrices[categoryKey].services[serviceKey].servicePrice;
            const pFreq = !this.props.paymentFrequency || this.props.paymentFrequency == '' ? 'yearly' : this.props.paymentFrequency;
            serviceAmount = calculateRecurringPrice[this.props.currentPaymentFrequency](yearlyPrice[pFreq](serviceAmount));
            reviewPrices[categoryKey].services[serviceKey].servicePrice = serviceAmount;
          }
        }
      }
      reviewPrices.paymentFrequency = this.props.currentPaymentFrequency;
    }
    const now = new Date();
    const previewPdfData = {
      source: this.props.contractSource,
      template: this.props.contractTemplate,
      selectedServices: reviewPrices,
      // packageRecurringPrice:
      //   this.props.packageRecurringPrice ||
      //   this.props.final_recurring_total ||
      //   this.props.recurringTotal,
      // packageOneOffPrice:
      //   this.props.packageOneOffPrice ||
      //   this.props.final_oneOff_total ||
      //   this.props.oneOffTotal,
      packageRecurringPrice: this.props.packageRecurringPrice,
      packageOneOffPrice: this.props.packageOneOffPrice,
      creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
      showFullBreakdown: this.props.showFullBreakdown,
      signatories: this.getSignatoriesData(),
      tnc: this.props.tnc,
      tncType: this.props.tncType,
    };
    if (this.props.tncType === "pdf") {
      previewPdfData["template_url"] = this.props.template_url;
    }
    if (this.props.contractSource === "fromScratch") {
      previewPdfData.client = this.props.contractClient;
      previewPdfData.paymentFrequency = this.props.currentPaymentFrequency;
      previewPdfData.globalPricingDrivers = buildGlobalPricingDriversJson(
        this.props.requiredGlobalPricingDrivers,
        this.props
      );
    } else if (this.props.contractSource === "fromQuote") {
      previewPdfData.paymentFrequency = this.props.currentPaymentFrequency;
      previewPdfData.quoteId = this.props.contractQuote;
      previewPdfData.quoteType = this.props.selectedQuoteType;
      let globalDrivers = [];
      for (let key in this.props.globalPricingDrivers) {
        globalDrivers.push({
          ...this.props.globalPricingDrivers[key],
          _id: key,
        });
      }
      previewPdfData.globalPricingDrivers = buildGlobalPricingDriversJson(
        globalDrivers,
        this.props
      );
      if (
        this.props.selectedQuoteType === "packagedPricing" ||
        this.props.selectedQuoteType === "customPackagedPricing"
      ) {
        const quotesData = this.props?.location?.state?.skip
          ? this.props?.skipQuotes
          : this.props?.sentQuotes || [];
        previewPdfData.quotePackageId = this.props.contractQuotePackage;
        previewPdfData.quotePackageName =
          quotesData.filter((item) => item._id === this.props.contractQuote)[0]
            ?.data?.packagedPricingSelectedPackages[
            this.props.contractQuotePackage
          ].name ||
          this.props.sentQuotes.filter(
            (item) => item._id === this.props.contractQuote
          )[0]?.data?.packagedPricingSelectedPackages[
            this.props.contractQuotePackage
          ].name;
      }
    }
    this.props.onInputChange('paymentFrequency', this.props.currentPaymentFrequency);
    this.props.onInputChange('OriginalPackageRecurringPrice', this.props.packageRecurringPrice);
    this.props.onInputChange('OriginalRecurringTotal', this.props.recurringTotal);

    this.props.previewContract(previewPdfData);
  }
  handleTncChange = (selectedOption) => {
    let tnc = this.props.tncTemplates.find(item => item._id === selectedOption.value);

    if (tnc) {
      let tncType = tnc.tnc_template_type;
      this.props.onInputChange("tncType", tncType);

      if (tncType === "customTemplate") {
        let tncHtml = tnc.template[0].tncBlock;
        this.props.onInputChange("tnc", tncHtml);
      } else {
        let template_url = tnc.template_url;
        this.props.onInputChange("template_url", template_url);
      }
    }
  };
  initializeTnc() {
    if (this.props.tncTemplates.length > 0) {
      const firstOption = this.props.tncTemplates[0];
      this.handleTncChange({ value: firstOption._id, label: firstOption.name });
    }
  }
  componentDidMount() {
    this.initializeTnc();
    this.props.resetCreateNewServiceForm();
    this.props.getServicesByCategory();
    this.props.getAvailablePaymentMethods();
    this.props.getMyOrganisation();
    this.props.getClients();
    this.props.getTemplates({ type: "contract,engagement letter" });
    this.props.getPricingSettings();
    if (this.type === "new") {
      // this.props.resetCreateNewPackageForm();
      this.props.resetCreateNewContractForm();
      if (this.props.location.state && this.props.location.state.quoteId) {
        this.props.getSentQuotes();
        this.props.getSkipQuotes();
        this.props.onInputChange("contractSource", "fromQuote");
        this.props.onInputChange(
          "selectedQuoteType",
          this.props.location.state.quoteType
        );
        this.props.onInputChange(
          "contractQuote",
          this.props.location.state.quoteId
        );
        this.props.onInputChange(
          "contractClient",
          this.props.location.state.quoteClient
        );
        this.props.onInputChange(
          "contractClientBusinessType",
          this.props.location.state.quoteClientBusinessType
        );
      }
    } else if (this.type === "edit") {
      this.props.resetCreateNewContractForm();
      this.props.getContract(this.props.match.params.id);
    }
  }
  setSignatories() {
    let client;
    for (let item of this.props.clients) {
      if (item._id === this.props.contractClient) {
        client = item;
        break;
      }
    }
    console.log('client - ', client);
    let signatories = [];
    if (client && client.officers) {
      for (let officer of client.officers) {
        if (officer.is_authorised_signatory) {
          signatories.push({
            id: officer._id,
            firstName: officer.first_name,
            lastName: officer.last_name,
            email: officer.email,
            signaturePosition: "right",
          });
        }
      }
    }
    this.props.setSignatories(signatories);
  }
  setContractClientBusinessType() {
    for (let item of this.props.clients) {
      if (item._id === this.props.contractClient) {
        this.props.onInputChange(
          "contractClientBusinessType",
          item.businessType
        );
        break;
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tncTemplates !== this.props.tncTemplates && this.props.tncTemplates.length > 0) {
      this.initializeTnc();
    }
    console.log("this.props - ", this.props.activeStep);
    if (this.props.activeStep == 1 && this.props.contractStatus == 'draft' && parseFloat(this.props.packageRecurringPrice).toFixed(2) != parseFloat(this.state.discountedPrice).toFixed(2) && this.props.packageRecurringPrice > this.props.recurringTotal) {
      this.setState({
        discountedPrice: parseFloat(this.props.packageRecurringPrice).toFixed(2)
      });
    }
    if (
      this.type === "new" &&
      (this.props.contractClient !== prevProps.contractClient ||
        this.props.sentQuotes !== prevProps.sentQuotes)
    ) {
      this.setSignatories();
    }
    if (
      this.props.contractQuote !== prevProps.contractQuote ||
      this.props.sentQuotes !== prevProps.sentQuotes
    ) {
      this.setContractClientBusinessType();
    }
    if (
      this.props.contractSource === "fromScratch" &&
      this.props.activeStep === 3 &&
      this.props.activeStep !== prevProps.activeStep
    ) {
      this.props.getGlobalDriversByServices(this.props.contractServices);
    }
    if (
      (this.props.contractSource === "fromScratch" &&
        this.props.activeStep === 3 &&
        this.props.activeStep !== prevProps.activeStep) ||
      (this.props.contractSource === "fromQuote" &&
        this.props.activeStep === 2 &&
        this.props.activeStep !== prevProps.activeStep)
    ) {
      this.props.getTncTemplates();
    }
    if (
      (this.props.loading !== prevProps.loading ||
        this.props.clientLoading !== prevProps.clientLoading) &&
      !this.props.loading &&
      !this.props.clientLoading
    ) {
      let client;
      for (let item of this.props.clients) {
        if (item._id === this.props.contractClient) {
          this.props.onInputChange(
            "contractClientBusinessType",
            item.businessType
          );
          break;
        }
      }
    }
    let updateStepperWarnings = false;
    if (
      this.props.contractSource === "fromScratch" && prevProps.activeStep !== 4 && this.props.activeStep === 4
    ) {
      if (
        checkPricingDriversValid(
          this.props.contractPricingDrivers,
          this.props.servicesByCategory,
          this.validator,
          this.props
        ) &&
        checkGlobalPricingDriversValid(
          this.props.requiredGlobalPricingDrivers,
          this.validator,
          this.props
        )
      ) {
        this.reviewPricing(prevProps.currentPaymentFrequency);
        this.setState({ validPricingDrivers: true });
      } else {
        this.setState({ validPricingDrivers: false });
      }
      updateStepperWarnings = true;
    }
    if (
      this.props.contractSource === "fromQuote" &&
      !this.state.isReviewApiCalled &&
      ((prevProps.activeStep !== 3 && this.props.activeStep === 3) ||
        (this.props.activeStep === 3 &&
          this.props.currentPaymentFrequency !== prevProps.currentPaymentFrequency))
    ) {
      this.reviewPricing(prevProps.currentPaymentFrequency);
      this.setState({ validPricingDrivers: true, isReviewApiCalled: true });
      updateStepperWarnings = true;
    }
    if (
      this.props.recurringTotal !== prevProps.recurringTotal &&
      this.state.viaSelect
    ) {
      // if (this.state.recurringPriceDiscountedPricePercentage > 0) {
      //   const newRecurringValue = this.props.recurringTotal;
      //   const discountedVal = this.state.recurringPriceDiscountedPricePercentage > 0 ?
      //     (newRecurringValue * this.state.recurringPriceDiscountedPricePercentage) / 100 : 0;
      //   this.props.onInputChange(
      //     "packageRecurringPrice",
      //     parseFloat(newRecurringValue - discountedVal).toFixed(2),
      //   );
      //   this.props.onInputChange(
      //     "OriginalPackageRecurringPrice",
      //     parseFloat(newRecurringValue - discountedVal).toFixed(2),
      //   );
      // } else {
      //   const newRecurringValue = this.props.packageRecurringPrice || this.props.recurringTotal;
      //   const discountedVal = this.state.recurringPriceDiscountedPricePercentage > 0 ?
      //     (newRecurringValue * this.state.recurringPriceDiscountedPricePercentage) / 100 : 0;
      //   let objDivision = {
      //     yearly: 1,
      //     halfYearly: 2,
      //     quarterly: 4,
      //     monthly: 12,
      //   };
      //   const updatedValue = (newRecurringValue - discountedVal) / objDivision[this.props.currentPaymentFrequency];
      //   if (discountedVal > 0) {
      //     this.props.onInputChange(
      //       "packageRecurringPrice",
      //       parseFloat(updatedValue).toFixed(2),
      //     );
      //     this.props.onInputChange(
      //       "OriginalPackageRecurringPrice",
      //       parseFloat(updatedValue).toFixed(2),
      //     );
      //   }
      // }
      let obj = {
        yearly: 12,
        halfYearly: 6,
        quarterly: 3,
        monthly: 1,
      };
      const currentPerMonthValue = obj[
        this.props.currentPaymentFrequency
      ];
      this.setState({ viaSelect: false, currentPerMonthValue });
    }
    // console.log('specpcpecpce', this.state.currentPerMonthValue, this.props.currentPaymentFrequency);
    if (
      this.props.recurringTotal !== prevProps.recurringTotal &&
      !this.state.viaSelect && this.props.currentPaymentFrequency.length >= 1
    ) {
      let obj = {
        yearly: 12,
        halfYearly: 6,
        quarterly: 3,
        monthly: 1,
      };
      const currentPerMonthValue = obj[
        this.props.currentPaymentFrequency
      ];
      console.log('specpcpecpce', currentPerMonthValue, this.props.currentPaymentFrequency);
      this.setState({ currentPerMonthValue });
    }
    if (this.props.oneOffTotal == 0 && this.props?.packageOneOffPrice > 0) {
      this.props.onInputChange('packageOneOffPrice', 0);
      this.setState({
        oneOffDiscountedPricePercentage: 0
      });
      this.props.onInputChange('oneOffDiscountPercentage', 0);
    }
    if (
      (this.props.contractSource === "fromScratch" &&
        prevProps.activeStep !== 5 &&
        this.props.activeStep === 5) ||
      (this.props.contractSource === "fromQuote" &&
        prevProps.activeStep !== 4 &&
        this.props.activeStep === 4)
    ) {
      if (this.validateContract() && this.validateSignatories()) {
        this.getPDFContractPreview();
        this.setState({ validContract: true });
      } else {
        this.validator.showMessages();
        this.setState({ validContract: false });
      }
      updateStepperWarnings = true;
    }
    // (!this.props.location.state || !this.props.location.state.quoteId) &&
    if (
      this.props.contractQuote &&
      this.props.sentQuotes &&
      (prevProps.contractQuote !== this.props.contractQuote ||
        prevProps.sentQuotes !== this.props.sentQuotes)
    ) {
      const quote = this.props.sentQuotes.find(
        (c) => c._id.toString() === this.props.contractQuote.toString()
      );
      console.log("changing from here", quote);
      if (quote) {
        this.props.onInputChange(
          "paymentFrequency",
          this.props.currentPaymentFrequency && this.props.currentPaymentFrequency.length > 0
            ? this.props.currentPaymentFrequency
            : quote.payment_frequency
        );
        this.props.onInputChange(
          "currentPaymentFrequency",
          this.props.currentPaymentFrequency && this.props.currentPaymentFrequency.length > 0
            ? this.props.currentPaymentFrequency
            : quote.payment_frequency
        );
      } else {
        const quote = this.props.skipQuotes.find(
          (c) => c._id.toString() === this.props.contractQuote.toString()
        );
        console.log("changing from here", quote);
        if (quote) {
          this.props.onInputChange(
            "paymentFrequency",
            this.props.currentPaymentFrequency && this.props.currentPaymentFrequency.length > 0
              ? this.props.currentPaymentFrequency
              : quote.payment_frequency
          );
          this.props.onInputChange(
            "currentPaymentFrequency",
            this.props.currentPaymentFrequency && this.props.currentPaymentFrequency.length > 0
              ? this.props.currentPaymentFrequency
              : quote.payment_frequency
          );
        }
      }
    }
    if (
      prevProps.contractSource !== "fromQuote" &&
      this.props.contractSource === "fromQuote"
    ) {
      this.props.getSentQuotes();
      this.props.getSkipQuotes();
    }
    if (!updateStepperWarnings) {
      if (
        this.props.contractClient !== prevProps.contractClient ||
        this.props.contractTemplate !== prevProps.contractTemplate ||
        this.props.contractQuote !== prevProps.contractQuote ||
        this.props.recurringTotal !== prevProps.recurringTotal ||
        this.props.oneOffTotal !== prevProps.oneOffTotal ||
        (this.props.recurringTotal > 0
          ? this.props.packageRecurringPrice !== prevProps.packageRecurringPrice
          : false) ||
        (this.props.oneOffTotal > 0
          ? this.props.packageOneOffPrice !== prevProps.packageOneOffPrice
          : false)
      ) {
        updateStepperWarnings = true;
      }
    }
    if (!updateStepperWarnings) {
      if (
        this.props.contractPricingDrivers.length !==
        prevProps.contractPricingDrivers.length
      ) {
        updateStepperWarnings = true;
      } else {
        for (let pricingDriver of this.props.contractPricingDrivers) {
          if (this.props[pricingDriver] !== prevProps[pricingDriver]) {
            updateStepperWarnings = true;
            break;
          }
        }
      }
    }
    if (!updateStepperWarnings) {
      if (
        this.props.requiredGlobalPricingDrivers.length !==
        prevProps.requiredGlobalPricingDrivers.length
      ) {
        updateStepperWarnings = true;
      } else {
        for (let pricingDriver of this.props.requiredGlobalPricingDrivers) {
          if (
            this.props[`globalPricingDriver-${pricingDriver._id}`] !==
            prevProps[`globalPricingDriver-${pricingDriver._id}`] ||
            this.props[
            `globalPricingDriver-${pricingDriver._id}-${this.props[`globalPricingDriver-${pricingDriver._id}`]
            }_other`
            ] !==
            prevProps[
            `globalPricingDriver-${pricingDriver._id}-${prevProps[`globalPricingDriver-${pricingDriver._id}`]
            }_other`
            ]
          ) {
            updateStepperWarnings = true;
            break;
          }
        }
      }
    }
    if (updateStepperWarnings) {
      this.updateStepperWarnings();
    }

    // if (
    //   this.props.recurringTotal !== prevProps.recurringTotal &&
    //   !this.state.discountUpdated &&
    //   this.props.activeStep === 4
    // ) {
    //   if (!this.isArrEqual()) {
    //     this.props.onInputChange(
    //       "packageRecurringPrice",
    //       this.props.recurringTotal
    //     );
    //     this.setState({ discountUpdated: true });
    //   }
    // }

    if (
      this.props.activeStep !== prevProps.activeStep &&
      this.props.activeStep < 3
    ) {
      // this.props.onInputChange(
      //   "packageRecurringPrice",
      //   0
      // )
    }
    if (this?.props?.contractStatus != 'draft' && this.props?.contractData && !this.state.updatedPercentageOnce) {
      console.log('spec_ChechJ_data', this?.props);
      if (
        this.props.contractData.packageRecurringPrice &&
        this.props.contractData.packageRecurringPrice !=
        this.props.contractData.recurringTotal &&
        this.props.contractData.recurringTotal > this.props.contractData.packageRecurringPrice
      ) {
        const discount = parseFloat(
          ((this.props.contractData.recurringTotal -
            this.props.contractData.packageRecurringPrice) *
            100) /
          this.props.contractData.recurringTotal
        ).toFixed(2);
        if (parseFloat(this.state.recurringPriceDiscountedPricePercentage).toFixed(2) != discount) {
          this.setState({
            recurringPriceDiscountedPricePercentage: discount < 0 ? 0 : discount,
            discountedPrice: parseFloat(this.props.contractData.packageRecurringPrice).toFixed(2)
          });
          this.props.onInputChange('recurringDiscountPercentage', discount < 0 ? 0 : discount);
          this.props.onInputChange('packageRecurringPrice', parseFloat(this.props.contractData.packageRecurringPrice).toFixed(2));
        }
      }
      if (
        this.props.contractData.packageOneOffPrice &&
        this.props.contractData.packageOneOffPrice > 0 &&
        this.props.contractData.packageOneOffPrice !=
        this.props.contractData.oneOffTotal &&
        this.props.contractData.oneOffTotal > this.props.contractData.packageOneOffPrice
      ) {
        const discount = parseFloat(
          ((this.props.contractData.oneOffTotal -
            this.props.contractData.packageOneOffPrice) *
            100) /
          this.props.contractData.oneOffTotal
        ).toFixed(2);
        if (parseFloat(this.state.oneOffDiscountedPricePercentage).toFixed(2) != discount) {
          this.setState({
            oneOffDiscountedPricePercentage: discount < 0 ? 0 : discount,
          });
          this.props.onInputChange('oneOffDiscountPercentage', discount < 0 ? 0 : discount);
        }
      }
      // this.setState({
      //   updatedPercentageOnce: true,
      // })
    }
    console.log('spec_Chech_dafdafa', this.props.contractStatus);
    if (this?.props?.contractStatus == 'draft' && !this.state.updatedPercentageOnce) {
      if (
        (this.props?.contractData?.packageRecurringPrice == 0 || !this.props?.contractData?.packageRecurringPrice) &&
        this.props?.packageRecurringPrice &&
        this.props?.packageRecurringPrice !=
        this.props?.recurringTotal &&
        this.props?.recurringTotal > this.props?.packageRecurringPrice
      ) {
        const discount = parseFloat(
          ((this.props.recurringTotal -
            this.props.packageRecurringPrice) *
            100) /
          this.props.recurringTotal
        ).toFixed(2);
        if (parseFloat(this.state.recurringPriceDiscountedPricePercentage).toFixed(2) != discount) {
          this.setState({
            recurringPriceDiscountedPricePercentage: discount < 0 ? 0 : discount,
          });
          this.props.onInputChange('recurringDiscountPercentage', discount < 0 ? 0 : discount);
        }
      }
      if (
        this.props?.contractData?.packageOneOffPrice == 0
      ) {
        const value = this.props?.final_oneOff_total && this.props?.final_oneOff_total != 0 ? this.props.final_oneOff_total : this.props?.oneOffTotal;
        if (value != this.props?.oneOffTotal && value < this.props.oneOffTotal) {
          const discount = parseFloat(
            ((this.props.oneOffTotal -
              value) *
              100) /
            this.props.oneOffTotal
          ).toFixed(2);
          if (parseFloat(this.state.oneOffDiscountedPricePercentage).toFixed(2) != discount) {
            this.setState({
              oneOffDiscountedPricePercentage: discount < 0 ? 0 : discount,
            });
            this.props.onInputChange('oneOffDiscountPercentage', discount < 0 ? 0 : discount);
          }
        }
      } else if (
        this.props?.contractData?.packageOneOffPrice > 0 && this.props?.oneOffTotal > 0
      ) {
        const value = this.props?.final_oneOff_total && this.props?.final_oneOff_total != 0 ? this.props.final_oneOff_total : this.props?.packageOneOffPrice;
        if (value != this.props?.oneOffTotal && value < this.props?.oneOffTotal) {
          const discount = parseFloat(
            ((this.props.oneOffTotal -
              value) *
              100) /
            this.props.oneOffTotal
          ).toFixed(2);
          if (parseFloat(this.state.oneOffDiscountedPricePercentage).toFixed(2) != discount) {
            this.setState({
              oneOffDiscountedPricePercentage: discount < 0 ? 0 : discount,
            });
            this.props.onInputChange('oneOffDiscountPercentage', discount < 0 ? 0 : discount);
          }
        }
      }
      // this.setState({
      //   updatedPercentageOnce: true,
      // })
    }
    console.log('spec_check_Data_ds', this.props.location.state);
    if (this.props.location.state && this.props.location.state.quoteId && !this.state.updatedPercentageOnce) {
      if (this.props.location.state.oneOffDiscountedPricePercentage || this.props.location.state.recurringPriceDiscountedPricePercentage) {
        this.setState({
          oneOffDiscountedPricePercentage: this.props.location.state.oneOffDiscountedPricePercentage && this.props.location.state.oneOffDiscountedPricePercentage > 0 ? this.props.location.state.oneOffDiscountedPricePercentage : 0,
          recurringPriceDiscountedPricePercentage: this.props.location.state.recurringPriceDiscountedPricePercentage && this.props.location.state.recurringPriceDiscountedPricePercentage > 0 ? this.props.location.state.recurringPriceDiscountedPricePercentage : 0,
          updatedPercentageOnce: true
        });
        this.props.onInputChange('oneOffDiscountPercentage', this.props.location.state.oneOffDiscountedPricePercentage && this.props.location.state.oneOffDiscountedPricePercentage > 0 ? this.props.location.state.oneOffDiscountedPricePercentage : 0);
        this.props.onInputChange('recurringDiscountPercentage', this.props.location.state.recurringPriceDiscountedPricePercentage && this.props.location.state.recurringPriceDiscountedPricePercentage > 0 ? this.props.location.state.recurringPriceDiscountedPricePercentage : 0);
      } else {
        const oneValue = this.props.packageOneOffPrice > 0 ? this.props.packageOneOffPrice : this.props?.final_oneOff_total ?? 0;
        let oneDiscount = -1;
        let recurringDiscount = -1;
        if (oneValue && oneValue != this.props?.oneOffTotal) {
          oneDiscount = parseFloat(((this.props.oneOffTotal - oneValue) * 100) / this.props.oneOffTotal).toFixed(2);
        }
        const recurringValue = this.props.packageRecurringPrice ?? 0;
        if (recurringValue && recurringValue != this.props?.recurringTotal) {
          recurringDiscount = parseFloat(((this.props.recurringTotal - recurringValue) * 100) / this.props.recurringTotal).toFixed(2);
        }
        if (oneDiscount != -1 || recurringDiscount != -1) {
          this.setState({
            oneOffDiscountedPricePercentage: oneDiscount < 0 ? 0 : oneDiscount,
            recurringPriceDiscountedPricePercentage: recurringDiscount < 0 ? 0 : recurringDiscount,
            updatedPercentageOnce: true
          });
          this.props.onInputChange('recurringDiscountPercentage', recurringDiscount < 0 ? 0 : recurringDiscount);
          this.props.onInputChange('oneOffDiscountPercentage', oneDiscount < 0 ? 0 : oneDiscount);
        }
      }
    }
    if (this.props.contractSource === "fromQuote" && this.props.activeStep == 3) {
      const discountedNewValue = this.props.packageRecurringPrice || this.props.final_recurring_total;
      if (this.props.recurringTotal > discountedNewValue && this.state.recurringPriceDiscountedPricePercentage == 0) {
        const discountPrice = this.props.recurringTotal - discountedNewValue;
        const discountPer = parseFloat((discountPrice * 100) / this.props.recurringTotal).toFixed(2);
        this.setState({
          recurringPriceDiscountedPricePercentage: discountPer < 0 ? 0 : discountPer
        });
        this.props.onInputChange('recurringDiscountPercentage', discountPer < 0 ? 0 : discountPer);
      }
      const discountedNewValueOneOff = this.props.packageOneOffPrice || this.props.final_oneOff_total;
      if (this.props.oneOffTotal > discountedNewValueOneOff && this.state.oneOffDiscountedPricePercentage == 0) {
        const discountPriceOneOff = this.props.oneOffTotal - discountedNewValueOneOff;
        const discountPerOne = parseFloat((discountPriceOneOff * 100) / this.props.oneOffTotal).toFixed(2);
        this.setState({
          oneOffDiscountedPricePercentage: discountPerOne < 0 ? 0 : discountPerOne
        });
        this.props.onInputChange('oneOffDiscountPercentage', discountPerOne < 0 ? 0 : discountPerOne);
      }
      console.log('spec_check_discoouns', this.props.recurringTotal, discountedNewValue);
      console.log('spec_check_discoouns_one', this.props.oneOffTotal, discountedNewValueOneOff);
      if (
        (this.props.recurringTotal > 0 && this.props.recurringTotal < discountedNewValue && discountedNewValue > 0) ||
        (this.props.oneOffTotal > 0 && this.props.oneOffTotal < discountedNewValueOneOff && discountedNewValueOneOff > 0)
      ) {
        this.props.onInputChange("showFullBreakdown", false);
        // this.props.onInputChange(
        //   this.setState({
        //     flag: false,
        //   })
        // );
      }
    }

    if (this.state.reCalculatePrice) {
      let yearlyPrice = {
        yearly: (val) => val,
        halfYearly: (val) => val * 2,
        quarterly: (val) => val * 4,
        monthly: (val) => val * 12,
      };
      let calculateRecurringPrice = {
        yearly: (val) => val,
        halfYearly: (val) => val / 2,
        quarterly: (val) => val / 4,
        monthly: (val) => val / 12,
      };
      if (this.props.currentPaymentFrequency == this.props.paymentFrequency) {
        // const recurringPriceOrg = calculateRecurringPrice[this.props.currentPaymentFrequency](this.props.OriginalRecurringTotal);
        // const packageRecurringPriceOrg = calculateRecurringPrice[this.props.currentPaymentFrequency](this.props.OriginalPackageRecurringPrice);
        if (this.props.recurringDiscountPercentage > 0) {
          this.props.onInputChange('packageRecurringPrice', this.props.OriginalRecurringTotal - ((this.props.OriginalRecurringTotal * this.props.recurringDiscountPercentage) / 100));
        } else {
          this.props.onInputChange('packageRecurringPrice', this.props.OriginalPackageRecurringPrice);
        }
        this.props.onInputChange('recurringTotal', this.props.OriginalRecurringTotal);
        this.setState({
          reCalculatePrice: false,
          discountedPrice: this.props.OriginalPackageRecurringPrice
        });
      } else {
        const recurringPriceOrg = calculateRecurringPrice[this.props.currentPaymentFrequency == '' ? 'yearly' : this.props.currentPaymentFrequency](yearlyPrice[prevProps.currentPaymentFrequency == '' ? 'yearly' : prevProps.currentPaymentFrequency](this.props.recurringTotal));
        const packageRecurringPriceOrg = calculateRecurringPrice[this.props.currentPaymentFrequency == '' ? 'yearly' : this.props.currentPaymentFrequency](
          yearlyPrice[prevProps.currentPaymentFrequency == '' ? 'yearly' : prevProps.currentPaymentFrequency](
            this.props.packageRecurringPrice
          )
        );
        this.props.onInputChange('packageRecurringPrice', packageRecurringPriceOrg);
        this.props.onInputChange('recurringTotal', recurringPriceOrg);
        this.setState({
          reCalculatePrice: false,
          discountedPrice: packageRecurringPriceOrg
        });
      }
    }

    if (this.props.contractSource == 'fromScratch' &&
      (
        this.props.activeStep == 4 ||
        this.props.activeStep == 3
      )
    ) {
      const discountedNewValue = this.props.packageRecurringPrice;
      if (this.props.recurringTotal > discountedNewValue && this.state.recurringPriceDiscountedPricePercentage == 0) {
        const discountPrice = this.props.recurringTotal - discountedNewValue;
        const discountPer = parseFloat((discountPrice * 100) / this.props.recurringTotal).toFixed(2);
        this.setState({
          recurringPriceDiscountedPricePercentage: discountPer < 0 ? 0 : discountPer
        });
        this.props.onInputChange('recurringDiscountPercentage', discountPer < 0 ? 0 : discountPer);
      }
      const discountedNewValueOneOff = this.props.packageOneOffPrice;
      if (this.props.oneOffTotal > discountedNewValueOneOff && this.state.oneOffDiscountedPricePercentage == 0) {
        const discountPriceOneOff = this.props.oneOffTotal - discountedNewValueOneOff;
        const discountPerOne = parseFloat((discountPriceOneOff * 100) / this.props.oneOffTotal).toFixed(2);
        this.setState({
          oneOffDiscountedPricePercentage: discountPerOne < 0 ? 0 : discountPerOne
        });
        this.props.onInputChange('oneOffDiscountPercentage', discountPerOne < 0 ? 0 : discountPerOne);
      }
    }
  }

  componentWillUnmount() {
    this.props.setActiveStep(1);
  }

  isArrEqual() {
    function getServicesArr(selectedServices) {
      const tempServices = selectedServices;
      if (!tempServices) return null;
      let keys = Object.keys(tempServices);
      const selectedTempServices = keys.reduce((acc, key) => {
        let _keys = Object.keys(tempServices[key].services);
        _keys = _keys.map((c) => key + "-" + c);
        return acc.concat(_keys);
      }, []);
      return selectedTempServices;
    }
    const tempServices = getServicesArr(
      this.props.contractData?.selectedServices
    );
    console.log(
      "this.props.contractServices, tempServices - ",
      this.props.contractServices,
      tempServices
    );
    return (
      tempServices?.length === this.props.contractServices?.length &&
      tempServices?.every((c) => this.props.contractServices.includes(c))
    );
  }
  customBackStep(stepNumber) {
    this.setState({
      warningSteps: [],
      currentActiveStep: this.props.activeStep,
    });
    this.props.setActiveStep(stepNumber);
  }

  updatePercentageOneOff(e) {
    const reg = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)+$/;
    if (!reg.test(e.target.value) && e.target.value != "") return;
    if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
      return false;
    }
    if (e.target.value < 0 || e.target.value > 100) return;
    this.setState({
      oneOffDiscountedPricePercentage: e.target.value,
      updatedPercentageOnce: true,
    });
    this.props.onInputChange('oneOffDiscountPercentage', e.target.value);
    const total = parseFloat(
      (this.props.oneOffTotal * e.target.value) / 100
    ).toFixed(2);
    this.setState({
      currentDiscountPrice: total,
    });
    if (this.props.oneOffTotal - total > this.props.oneOffTotal) {
      this.props.onInputChange("showFullBreakdown", false);
      // this.props.onInputChange(
      //   this.setState({
      //     flag: false,
      //   })
      // );
    } else {
      if (this.props.recurringTotal >= this.props.packageRecurringPrice) {
        this.props.onInputChange("showFullBreakdown", true);
      }
      // this.props.onInputChange(
      // this.setState({
      //   flag: true,
      // })
      // );
    }
    this.props.onInputChange(
      "packageOneOffPrice",
      parseFloat(this.props.oneOffTotal - total).toFixed(2)
    );
    this.props.onInputChange(
      "final_oneOff_total",
      parseFloat(this.props.oneOffTotal - total).toFixed(2)
    );
  }

  updateOneOffPercentageDiscount() {
    const discount = parseFloat(
      ((this.props.oneOffTotal - this.props.packageOneOffPrice) * 100) /
      this.props.oneOffTotal
    ).toFixed(2);
    this.setState({
      currentDiscountPrice:
        this.props.oneOffTotal - this.props.packageOneOffPrice,
    });
    this.setState({
      oneOffDiscountedPricePercentage: discount < 0 ? 0 : discount,
    });
    this.props.onInputChange('oneOffDiscountPercentage', discount < 0 ? 0 : discount);
  }

  updatePercentageRecurringPrice(e) {
    const reg = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)+$/;
    if (e.target.value == 100) return;
    if (!reg.test(e.target.value) && e.target.value != "") return;
    if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
      return false;
    }
    if (e.target.value > 100) return;
    if (e.target.value == '' || e.target.value < 0) e.target.value = 0;
    this.setState({
      recurringPriceDiscountedPricePercentage: e.target.value,
    });
    this.props.onInputChange('recurringDiscountPercentage', e.target.value);
    const total = parseFloat(
      (this.props.recurringTotal * e.target.value) / 100
    ).toFixed(2);
    this.setState({
      currentDiscountPrice: total,
    });
    if (this.props.recurringTotal - total > this.props.recurringTotal) {
      this.props.onInputChange("showFullBreakdown", false);
      // this.props.onInputChange(
      //   this.setState({
      //     flag: false,
      //   })
      // );
    } else {
      if (this.props.oneOffTotal >= this.props.packageOneOffPrice) {
        this.props.onInputChange("showFullBreakdown", true);
      }
      // this.props.onInputChange(
      // this.setState({
      //   flag: true,
      // });
      // );
    }
    this.props.onInputChange(
      "packageRecurringPrice",
      parseFloat(this.props.recurringTotal - total).toFixed(2)
    );
    this.props.onInputChange(
      "OriginalPackageRecurringPrice",
      parseFloat(this.props.recurringTotal - total).toFixed(2)
    );
    this.setState({
      discountedPrice: parseFloat(this.props.recurringTotal - total).toFixed(2),
    });
  }

  updateRecurringPricePercentageDiscount() {
    const discount = parseFloat(
      ((this.props.recurringTotal - this.props.packageRecurringPrice) * 100) /
      this.props.recurringTotal
    ).toFixed(2);
    this.setState({
      currentDiscountPrice:
        this.props.recurringTotal - this.props.packageRecurringPrice,
    });
    this.setState({
      recurringPriceDiscountedPricePercentage: discount < 0 ? 0 : discount,
    });
    this.props.onInputChange('recurringDiscountPercentage', discount < 0 ? 0 : discount);
  }

  updateOrganisation() {
    this.props.updatePricingSettings({
      minPricing: this.state.minPricingState,
      minPricingMonth: this.state.minPricingMonthState,
      maxDiscount: this.state.maxDiscountState,
    });
    this.setState({
      showPricingModel: false,
      minPricingState: 0,
      minPricingMonthState: 0,
      maxDiscountState: 0,
    })
  }

  render() {
    let categoryId,
      categoryName,
      serviceId,
      serviceChargeType,
      serviceName,
      pricingDrivers = [],
      dependencyArray = [];
    const categoryIds = Object.keys(this?.props?.servicesByCategory).map(
      (key) => key
    );

    const categoriesArray = Object.values(this?.props?.servicesByCategory).map(
      (category, idx) => {
        categoryId = categoryIds[idx];
        categoryName = category.categoryName;
        if (category?.services?.length > 0) {
          const servicesArray = category?.services?.map((service, idx) => {
            serviceId = service.id;
            serviceChargeType = service.chargeType;
            serviceName = service.name;
            if (service?.is_active && service?.pricingDrivers?.length > 0) {
              pricingDrivers = service?.pricingDrivers;
              const pricingDriversArray = service?.pricingDrivers?.map(
                (pricingDriver, idx) => {
                  if (
                    pricingDriver?.is_deleted === false &&
                    pricingDriver?.isDependent === true
                  ) {
                    const findDriver = pricingDrivers.filter(
                      (item) => item.name === pricingDriver.dependancyDriver
                    )[0];
                    const findDependency = dependencyArray.filter(
                      (item) =>
                        item.categoryId === categoryId &&
                        item.serviceId === serviceId &&
                        item.serviceChargeType === serviceChargeType &&
                        item.name === findDriver.name
                    );
                    if (findDependency?.length) {
                      const appendDependent = dependencyArray
                        .find((e) => e._id === findDependency[0]._id)
                        .dependents.push(pricingDriver);
                    } else {
                      dependencyArray.push({
                        ...findDriver,
                        categoryId,
                        categoryName,
                        serviceId,
                        serviceChargeType,
                        serviceName,
                        dependents: [pricingDriver],
                      });
                    }
                  }
                }
              );
            }
          });
        }
      }
    );

    const ongoing_Recurring_DependencyArray = dependencyArray.filter(
      (item) =>
        item.serviceChargeType === "recurring" ||
        item.serviceChargeType === "recurring/oneoff"
    );
    const oneOff_Adhoc_DependencyArray = dependencyArray.filter(
      (item) =>
        item.serviceChargeType === "oneoff" ||
        item.serviceChargeType === "recurring/oneoff"
    );

    const availablePaymentOptions = this.props.availablePaymentMethods.map(
      (item) => ({ value: item, label: item })
    );
    this.type = this.props.match.path
      .replace("/contracts/", "")
      .replace("/:id", "");
    const fromScratchContractSteps = [
      "Basic Information",
      "Select Services",
      "Additional Information",
      "Review Services",
      "Preview",
    ];
    const fromQuoteContractSteps = [
      "Basic Information",
      "Additional Information",
      "Review Services",
      "Preview",
    ];
    // const clientOptions = this.props.clients.map((item, index) => ({value: item._id, label: item.displayName}));
    const clientOptions = this.props.clients.map((item, index) => ({
      value: item._id,
      label: item.displayName,
      businessType: item.businessType,
    }));
    // console.log("this.props.location -= ", this.props.location);
    let quotesLists = this.props?.location?.state?.skip
      ? this.props?.skipQuotes
      : this.props?.sentQuotes || [];
    if (this.props.contractQuote) {
      let isInSkip = this.props.skipQuotes?.find(
        (c) => c._id === this.props.contractQuote
      );
      if (isInSkip) quotesLists = this.props.skipQuotes;
      else quotesLists = this.props.sentQuotes;
    }
    const quoteOptions = quotesLists?.map((item, index) => {
      let date = new Date(item.createdAt).getDate();
      let month = new Date(item.createdAt).getMonth() + 1;
      let year = new Date(item.createdAt).getFullYear();
      let hour = new Date(item.createdAt).getHours();
      let minutes = new Date(item.createdAt).getMinutes();
      return {
        clientName: item.data.client.displayName,
        createAt: `${date}-${month}-${year}, ${hour}:${minutes}`,
        label: `${item.data.client.displayName}  ${date}-${month}-${year}, ${hour}:${minutes}`,
        value: item._id,
        label_old: `${item.prefix}-${item.code}`,
        type: item.type,
        client: item.client,
        packagedPricingSelectedPackages:
          item.data.packagedPricingSelectedPackages || item.data,
      };
    });
    const selectedQuote = quoteOptions.filter(
      (option) => option.value === this.props.contractQuote
    );
    const templateOptions = this.props.templates
      .filter(
        (item) =>
          item.client_business_type === this.props.contractClientBusinessType
      )
      .map((item, index) => ({ value: item._id, label: item.name }));
    const quotePackageOptions =
      selectedQuote &&
        selectedQuote.length > 0 &&
        (selectedQuote[0].type === "packagedPricing" ||
          selectedQuote[0].type === "customPackagedPricing")
        ? Object.entries(selectedQuote[0].packagedPricingSelectedPackages).map(
          (item) => ({ value: item[0], label: item[1].name })
        )
        : [];
    const tncOptions = this.props.tncTemplates.map(item => {
      return { value: item._id, label: item.name };
    });

    const defaultValue = tncOptions.length > 0 ? tncOptions[0] : null;

    let activeStepCount = 1;

    if (this.props.subscriptionLoading) {
      return <Loader />;
    }

    if (!this.props.subscriptionLoading) {
      const prepare_contract = this.props.allow_prepare_contract;
      if (!prepare_contract) {
        return (
          <Redirect
            to={{
              pathname: "/contracts",
            }}
          />
        );
      }
    }

    return (
      <React.Fragment>
        {(this.props.loading ||
          this.props.quoteLoading ||
          this.props.serviceLoading ||
          this.props.clientLoading ||
          this.props.templateLoading) && <Loader />}
        <div className="container-fluid new-item-page-container">
          <div className="new-item-page-nav">
            <i
              onClick={this.cancel}
              className="ion ion-md-arrow-round-back back-button"
            ></i>
          </div>
          <div className="new-item-page-content">
            <div className="row form-row">
              <div className="col-12">
                <h3>Create New Engagement Letter</h3>
                <StepperWidget
                  onClick={(stepNumber) => this.customBackStep(stepNumber)}
                  activeStep={this.props.activeStep}
                  warningSteps={this.state.warningSteps}
                  steps={eval(`${this.props.contractSource}ContractSteps`)}
                  custom={true}
                  currentActiveStep={this.state.currentActiveStep}
                />
                <TabContent activeTab={this.props.activeStep}>
                  <TabPane tabId={1} className="p-3">
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Select Source
                        </label>
                      </div>
                      {/* {console.log('spec_Check_erro', contractSourceOptions, this.props.contractSource
                        ? contractSourceOptions.filter(
                          (option) =>
                            option.value === this.props.contractSource
                        ) : "")} */}
                      <div className="col-8">
                        <Select
                          options={contractSourceOptions}
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "contractSource",
                              selectedOption.value
                            )
                          }
                          value={
                            this.props.contractSource
                              ? contractSourceOptions.filter(
                                (option) =>
                                  option.value === this.props.contractSource
                              )
                              : ""
                          }
                        />
                      </div>
                    </div>
                    {this.props.contractSource === "fromQuote" && (
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            Select Proposal
                          </label>
                        </div>
                        <div className="col-8">
                          <Select
                            options={quoteOptions}
                            onChange={(selectedOption) => {
                              console.log("selectedOption - ", selectedOption);
                              this.props.onInputChange(
                                "contractClient",
                                selectedOption.client
                              );
                              this.props.onInputChange(
                                "contractQuote",
                                selectedOption.value
                              );
                              this.props.onInputChange(
                                "selectedQuoteType",
                                selectedOption.type
                              );
                              this.props.onInputChange(
                                "paymentFrequency",
                                selectedOption.packagedPricingSelectedPackages
                                  .paymentFrequency
                              );
                              this.props.onInputChange(
                                "currentPaymentFrequency",
                                selectedOption.packagedPricingSelectedPackages
                                  .paymentFrequency
                              );
                            }}
                            value={
                              this.props.contractQuote
                                ? quoteOptions.filter(
                                  (option) =>
                                    option.value === this.props.contractQuote
                                )
                                : ""
                            }
                          />
                          {this.validator.message(
                            "contractQuote",
                            this.props.contractQuote,
                            "_required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                    )}
                    {this.props.contractSource === "fromQuote" &&
                      (this.props.selectedQuoteType === "packagedPricing" ||
                        this.props.selectedQuoteType ===
                        "customPackagedPricing") && (
                        <div className="row fieldset">
                          <div className="col-4 text-right">
                            <label className="fieldset-label required">
                              Select Package
                            </label>
                          </div>
                          <div className="col-8">
                            <Select
                              options={quotePackageOptions}
                              onChange={(selectedOption) => {
                                this.props.onInputChange(
                                  "contractQuotePackage",
                                  selectedOption.value
                                )
                                if (this.props.OriginalContractQuotePackage != selectedOption.value) {
                                  this.props.onInputChange(
                                    "prevContractQuotePackage",
                                    true
                                  );
                                } else {
                                  this.props.onInputChange(
                                    "prevContractQuotePackage",
                                    false
                                  );
                                }
                              }
                              }
                              value={
                                this.props.contractQuotePackage
                                  ? quotePackageOptions.filter(
                                    (option) =>
                                      option.value ===
                                      this.props.contractQuotePackage
                                  )
                                  : ""
                              }
                            />
                            {this.validator.message(
                              "contractQuotePackage",
                              this.props.contractQuotePackage,
                              "_required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                      )}
                    {this.props.contractSource === "fromScratch" && (
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            Select Prospect
                          </label>
                        </div>
                        <div className="col-8">
                          <Select
                            options={clientOptions}
                            onChange={(selectedOption) => {
                              this.props.onInputChange(
                                "contractClient",
                                selectedOption.value
                              );
                              this.props.onInputChange(
                                "contractTemplate",
                                null
                              );
                              this.props.onInputChange(
                                "contractClientBusinessType",
                                selectedOption.businessType
                              );
                            }}
                            value={
                              this.props.contractClient
                                ? clientOptions.filter(
                                  (option) =>
                                    option.value === this.props.contractClient
                                )
                                : ""
                            }
                          />
                          {this.validator.message(
                            "contractClient",
                            this.props.contractClient,
                            "_required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                    )}
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Select Template
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={templateOptions}
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "contractTemplate",
                              selectedOption.value
                            )
                          }
                          value={
                            this.props.contractTemplate
                              ? templateOptions.filter(
                                (option) =>
                                  option.value === this.props.contractTemplate
                              )
                              : (() => {
                                const lastOption = templateOptions[templateOptions.length - 1];
                                if (lastOption) {
                                  this.props.onInputChange("contractTemplate", lastOption.value, lastOption.label); // Pass name and value
                                  return lastOption;
                                }
                                return null;
                              })()
                          }
                        />
                        {this.validator.message(
                          "contractTemplate",
                          this.props.contractTemplate,
                          "_required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                  </TabPane>
                  {this.props.contractSource === "fromScratch" && (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      <div className="row">
                        <div className="col-6 border-right-separator">
                          <h4>Ongoing/Recurring Services</h4>
                          <div className="separator mb-2" />
                          <SelectServices
                            chargeType="recurring"
                            type="contract"
                            parentProps={this.props}
                            dependencyArray={ongoing_Recurring_DependencyArray}
                            onInputChange={(...e) => {
                              console.log("input ran - -- ");
                              this.props.onInputChange(...e);
                            }}
                            servicesByCategory={this.props.servicesByCategory}
                            services={this.props.contractServices}
                            addService={this.props.addServiceToContract}
                            removeService={this.props.removeServiceFromContract}
                            validator={this.validator}
                          />
                        </div>
                        <div className="col-6">
                          <h4>One-Off/Ad hoc Services</h4>
                          <div className="separator mb-2" />
                          <SelectServices
                            chargeType="oneoff"
                            parentProps={this.props}
                            dependencyArray={oneOff_Adhoc_DependencyArray}
                            onInputChange={this.props.onInputChange}
                            servicesByCategory={this.props.servicesByCategory}
                            services={this.props.contractServices}
                            addService={this.props.addServiceToContract}
                            removeService={this.props.removeServiceFromContract}
                            validator={this.validator}
                          />
                        </div>
                        {!this.state.valid &&
                          this.props.contractServices.length === 0 ? (
                          <p
                            style={{
                              textAlign: "center",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            Please select atleast one service
                          </p>
                        ) : null}
                      </div>
                    </TabPane>
                  )}
                  <TabPane tabId={++activeStepCount} className="p-3">
                    <div className="row fieldset">
                      <div className="col-12">
                        {this.props.contractSource === "fromScratch" && (
                          <RequiredGlobalPricingDrivers
                            pricingDrivers={
                              this.props.requiredGlobalPricingDrivers
                            }
                            parentProps={this.props}
                            onInputChange={this.props.onInputChange}
                            validator={this.validator}
                            showNoInfoBlock={false}
                          />
                        )}
                        <h4 className="mt-2">Signatories</h4>
                        <div className="separator mb-3" />
                        {this.props.signatories.length === 0 && (
                          <div className="text-center text-danger">
                            Please add atleast one signatory!
                          </div>
                        )}
                        {this.renderSignatories()}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-12 text-right">
                        <button
                          onClick={() =>
                            this.props.addSignatory(
                              this.generateNewSignatoryCode()
                            )
                          }
                          className="btn btn-sm create-item"
                        >
                          <i className="ion ion-md-add-circle"></i>Add Signatory
                        </button>
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-12">
                        <h4 className="mt-2">Terms & Conditions</h4>
                        <div className="separator mb-3" />
                        <div className="row fieldset">
                          <div className="col-2">
                            <label className="fieldset-label">
                              TnC Template
                            </label>
                          </div>
                          <div className="col-10">
                            <Select
                              options={tncOptions}
                              onChange={this.handleTncChange}
                              value={tncOptions.find(c => c.value === this.props.tncTemplate)}
                              defaultValue={defaultValue} // Set the default value
                            />
                          </div>
                        </div>
                        {this.props.tncType === "customTemplate" && (
                          <MyEditor
                            // ref={this.tncEditor}
                            value={this.props.tnc}
                            variable={"tnc"}
                            onChange={this.props.onInputChange}
                          />
                        )}
                        {this.props.tncType === "pdf" && (
                          <iframe
                            src={this.props.template_url}
                            className="pdfIframe"
                          />
                        )}
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId={++activeStepCount} className="p-3">
                    {!this.state.validPricingDrivers && (
                      <div className="text-center text-danger">
                        Please input all valid pricing driver values!
                      </div>
                    )}
                    {this.renderFeesInProposal()}
                    {this.state.validPricingDrivers && (
                      <React.Fragment>
                        <div className="row fieldset">
                          <div className="col-2 text-right">
                            <label className="fieldset-label">
                              Payment Gateway
                            </label>
                          </div>
                          <div className="col-10">
                            <Select
                              options={availablePaymentOptions}
                              onChange={(selectedOption) =>
                                this.props.onInputChange(
                                  "contractPaymentMethod",
                                  selectedOption.value
                                )
                              }
                              value={
                                this.props.contractPaymentMethod
                                  ? availablePaymentOptions.filter(
                                    (option) =>
                                      option.value ===
                                      this.props.contractPaymentMethod
                                  )
                                  : ""
                              }
                            />
                            {this.validator.message(
                              "contractPaymentMethod",
                              this.props.contractPaymentMethod,
                              "_required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                        {Object.keys(this.props.reviewedPrice).length === 0 &&
                          this.props.reviewedPrice.constructor === Object && (
                            <div className="text-center">
                              No Services Found!
                            </div>
                          )}
                        <ReviewServices
                          isVatRegistered={
                            this.props.currentOrganisation.vatRegistered
                          }
                          flag={this.props.showFullBreakdown}
                          chargeType="recurring"
                          title="Recurring Services"
                          calculatedPackagePrice={this.props.reviewedPrice}
                          discountedPrice={
                            this.props.packageRecurringPrice
                          }
                          discountPercentage={
                            this.state.recurringPriceDiscountedPricePercentage
                          }
                          paymentFrequency={this.props.paymentFrequency == '' ? "yearly" : this.props.paymentFrequency}
                          currentPaymentFrequency={this.props.currentPaymentFrequency == '' ? "yearly" : this.props.currentPaymentFrequency}
                        >
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Original Price (£)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={formatCurrency(
                                  this.props.recurringTotal.toFixed(2) || 0
                                )}
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Original Price (£)"
                              />
                            </div>
                            <div className="col-2 text-right p-0">
                              <label className="fieldset-label">
                                Payment Frequency
                              </label>
                            </div>
                            <div className="col-4">
                              <Select
                                options={paymentFrequencyOptions}
                                onChange={(selectedOption) => {
                                  this.props.onInputChange(
                                    "currentPaymentFrequency",
                                    selectedOption.value
                                  );
                                  this.setState({
                                    reCalculatePrice: true,
                                    viaSelect: true,
                                  });
                                }}
                                value={
                                  this.props.currentPaymentFrequency
                                    ? paymentFrequencyOptions.filter(
                                      (option) =>
                                        option.value ===
                                        this.props.currentPaymentFrequency
                                    )
                                    : ""
                                  // paymentFrequencyOptions.filter(
                                  //   (option) => option.value === "yearly"
                                  // )
                                }
                              />
                              {/* {this.props.contractSource === "fromQuote" && <input value={this.props.paymentFrequency || ""} readOnly className="input-text" type="text" placeholder="Original Price (£)" />} */}
                            </div>
                          </div>
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Discount (%)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  isNaN(this.props
                                    .recurringDiscountPercentage) ? 0 : this.props
                                    .recurringDiscountPercentage
                                }
                                onChange={(e) =>
                                  this.updatePercentageRecurringPrice(e)
                                }
                                // readOnly
                                className="input-text"
                                type="text"
                                placeholder="Discount (%)"
                              />
                              {this.props?.maxDiscount &&
                                this.props.maxDiscount > 0
                                ? this.validator.message(
                                  "packageRecurringPricePercentage",
                                  this.state
                                    .recurringPriceDiscountedPricePercentage,
                                  `_numeric|_maxDiscount:${this.props.maxDiscount},num`,
                                  { className: "text-danger" }
                                )
                                : null}
                              {!this.validator.fields["packageRecurringPricePercentage"] && typeof this.validator.fields["packageRecurringPricePercentage"] != 'undefined'
                                && this.validator.messagesShown ? (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => this.setState({
                                    showPricingModel: true,
                                    minPricingState: this.props.minPricing ?? 0,
                                    minPricingMonthState: this.props.minPricingMonth ?? 0,
                                    maxDiscountState: this.props.maxDiscount ?? 0
                                  })}
                                >Pricing Settings</a>
                              ) : null}
                            </div>
                            <div className="col-2 text-right p-0">
                              <label className="fieldset-label">
                                Discounted Price (£)
                              </label>
                            </div>
                            <div className="col-4">
                              {/* <input value={ this.state.hasFocused ? ( this.props.packageRecurringPrice || "") :this.props.recurringTotal } */}
                              {/* <input value={ this.props.packageRecurringPrice == parseInt(0) ? this.props.contractSource==="fromQuote"  ? this.props.final_recurring_total || this.props.recurringTotal : this.props.recurringTotal : this.props.packageRecurringPrice } */}
                              <input
                                value={
                                  !this.state.hasBlurred
                                    ? parseFloat(
                                      this.props.packageRecurringPrice
                                    ) == parseFloat(0) &&
                                      this.state
                                        .recurringPriceDiscountedPricePercentage !=
                                      100
                                      ? this.props.recurringTotal
                                      : this.props.packageRecurringPrice
                                    : formatCurrency(
                                      parseFloat(
                                        this.props.packageRecurringPrice
                                      ) == parseFloat(0) &&
                                        this.state
                                          .recurringPriceDiscountedPricePercentage !=
                                        100
                                        ? this.props.recurringTotal
                                        : this.props?.packageRecurringPrice
                                    )
                                }
                                onFocus={() =>
                                  this.setState({
                                    hasFocused: true,
                                    hasBlurred: false,
                                  })
                                }
                                onBlur={() =>
                                  this.setState({ hasBlurred: true })
                                }
                                onKeyUp={(e) => {
                                  let obj = {
                                    yearly: (val) => val,
                                    halfYearly: (val) => val * 2,
                                    quarterly: (val) => val * 4,
                                    monthly: (val) => val * 12,
                                  };
                                  console.log("changed from here -- ");
                                  let func = obj[this.props.currentPaymentFrequency];
                                  if (!func) func = obj.yearly;
                                  this.setState(
                                    {
                                      discountedPrice: func(e.target.value),
                                    },
                                    () =>
                                      this.updateRecurringPricePercentageDiscount()
                                  );
                                }}
                                onChange={(e) => {
                                  if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
                                    return false;
                                  }
                                  console.log("changes - ");
                                  this.props.onInputChange(
                                    "packageRecurringPrice",
                                    e.target.value > 0 ? e.target.value : 0
                                  );
                                  this.props.onInputChange(
                                    "OriginalPackageRecurringPrice",
                                    e.target.value > 0 ? e.target.value : 0
                                  );
                                  if (
                                    e.target.value > this.props.recurringTotal
                                  ) {
                                    this.props.onInputChange(
                                      "showFullBreakdown",
                                      false
                                    );
                                    // this.props.onInputChange(
                                    //   this.setState({
                                    //     flag: false,
                                    //   })
                                    // );
                                  } else {
                                    if (this.props.oneOffTotal >= this.props.packageOneOffPrice) {
                                      this.props.onInputChange(
                                        "showFullBreakdown",
                                        true
                                      );
                                    }
                                    // this.props.onInputChange(
                                    // this.setState({
                                    //   flag: true,
                                    // });
                                    // );
                                  }
                                }}
                                className="input-text"
                                type="text"
                                placeholder="Discounted Price (£)"
                              />

                              {/* {this.props.final_recurring_total &&
                                !this.props.payment_frequency &&
                                this.state.discountedPrice == parseInt(0)
                                ? this.setState({
                                  discountedPrice:
                                    parseFloat(this.props.final_recurring_total).toFixed(2),
                                })
                                : ""}
                              {this.props.packageRecurringPrice &&
                                !this.props.payment_frequency &&
                                this.state.discountedPrice == parseInt(0)
                                ? this.setState({
                                  discountedPrice:
                                    parseFloat(this.props.packageRecurringPrice).toFixed(2),
                                })
                                : ""} */}
                              {this.props.minPricingMonth &&
                                this.props.minPricingMonth > 0 &&
                                ((parseFloat(this.props.packageRecurringPrice) == parseFloat(0) &&
                                  this.state.recurringPriceDiscountedPricePercentage != 100
                                  ? this.props.recurringTotal
                                  : this.props?.packageRecurringPrice) > 0 || (parseFloat(this.props.packageRecurringPrice) == parseFloat(0) &&
                                    this.state.recurringPriceDiscountedPricePercentage == 100))
                                ? this.validator.message(
                                  "packageRecurringPrice",
                                  (this.props.packageRecurringPrice ? this.props.packageRecurringPrice : this.props.recurringTotal) / this.state.currentPerMonthValue,
                                  `_required|_numeric|_minPricingMonth:${this.props.minPricingMonth},num`,
                                  { className: "text-danger" }
                                )
                                : null}
                              {!this.validator.fields["packageRecurringPrice"] && typeof this.validator.fields["packageRecurringPrice"] != 'undefined'
                                && this.validator.messagesShown ? (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => this.setState({
                                    showPricingModel: true,
                                    minPricingState: this.props.minPricing ?? 0,
                                    minPricingMonthState: this.props.minPricingMonth ?? 0,
                                    maxDiscountState: this.props.maxDiscount ?? 0
                                  })}
                                >Pricing Settings</a>
                              ) : null}
                            </div>
                          </div>
                        </ReviewServices>
                        <ReviewServices
                          isVatRegistered={
                            this.props.currentOrganisation.vatRegistered
                          }
                          flag={this.props.showFullBreakdown}
                          chargeType="oneoff"
                          title="One-Off Services"
                          calculatedPackagePrice={this.props.reviewedPrice}
                          discountedPrice={
                            this.props.packageOneOffPrice
                          }
                          discountPercentage={
                            this.state.oneOffDiscountedPricePercentage
                          }
                          paymentFrequency={""}
                          currentPaymentFrequency={""}
                        >
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Original Price (£)
                              </label>
                            </div>
                            <div className="col-10">
                              <input
                                value={formatCurrency(
                                  parseFloat(this.props?.oneOffTotal ?? 0).toFixed(2) || 0
                                )}
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Original Price (£)"
                              />
                            </div>
                          </div>
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Discount (%)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  isNaN(this.props.oneOffDiscountPercentage) ? 0 : this.props.oneOffDiscountPercentage
                                }
                                onChange={(e) => this.updatePercentageOneOff(e)}
                                className="input-text"
                                type="text"
                                placeholder="Discount (%)"
                              />
                              {this.props?.maxDiscount &&
                                this.props.maxDiscount > 0
                                ? this.validator.message(
                                  "packageOneOffPricePercentage",
                                  this.state.oneOffDiscountedPricePercentage,
                                  `_numeric|_maxDiscount:${this.props.maxDiscount},num`,
                                  { className: "text-danger" }
                                )
                                : null}
                              {!this.validator.fields["packageOneOffPricePercentage"] && typeof this.validator.fields["packageOneOffPricePercentage"] != 'undefined'
                                && this.validator.messagesShown ? (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => this.setState({
                                    showPricingModel: true,
                                    minPricingState: this.props.minPricing ?? 0,
                                    minPricingMonthState: this.props.minPricingMonth ?? 0,
                                    maxDiscountState: this.props.maxDiscount ?? 0
                                  })}
                                >Pricing Settings</a>
                              ) : null}
                            </div>
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Discounted Price (£)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  !this.state.hasBlurredOneOff
                                    ? parseFloat(
                                      this.props.packageOneOffPrice
                                    ) == parseFloat(0) &&
                                      this.state
                                        .oneOffDiscountedPricePercentage !=
                                      100
                                      ? this.props.oneOffTotal
                                      : this.props.packageOneOffPrice
                                    : formatCurrency(
                                      parseFloat(
                                        this.props.packageOneOffPrice
                                      ) == parseFloat(0) &&
                                        this.state
                                          .oneOffDiscountedPricePercentage !=
                                        100
                                        ? this.props.oneOffTotal
                                        : this.props?.packageOneOffPrice
                                    )
                                }

                                onKeyUp={(e) => {
                                  this.setState(
                                    {
                                      oneOffDiscountedPrice: e.target.value,
                                    },
                                    () => this.updateOneOffPercentageDiscount()
                                  );
                                }}
                                onChange={(e) => {
                                  if ((e.target.value % 1 > 0 && e.target.value.toString().split('.')[1].length > 2) || e.target.value.toString().split('.').length > 2) {
                                    return false;
                                  }

                                  this.props.onInputChange(
                                    "packageOneOffPrice",
                                    e.target.value > 0 ? e.target.value : 0
                                  );
                                  this.props.onInputChange(
                                    "final_oneOff_total",
                                    e.target.value > 0 ? e.target.value : 0
                                  );
                                  if (e.target.value > this.props.oneOffTotal) {
                                    this.props.onInputChange(
                                      "showFullBreakdown",
                                      false
                                    );
                                    // this.props.onInputChange(
                                    //   this.setState({
                                    //     flag: false,
                                    //   })
                                    // );
                                  } else {
                                    if (this.props.recurringTotal >= this.props.packageRecurringPrice) {
                                      this.props.onInputChange(
                                        "showFullBreakdown",
                                        true
                                      );
                                    }
                                    // this.props.onInputChange(
                                    // this.setState({
                                    //   flag: true,
                                    // });
                                    // );
                                  }
                                }}
                                onFocus={() =>
                                  this.setState({
                                    hasFocusedOneOff: true,
                                    hasBlurredOneOff: false,
                                  })
                                }
                                onBlur={() =>
                                  this.setState({ hasBlurredOneOff: true })
                                }
                                className="input-text"
                                type="text"
                                placeholder="Discounted Price (£)"
                              />
                              {this.props.minPricing &&
                                this.props.minPricing > 0 &&
                                ((!this.props.packageOneOffPrice &&
                                  this.state
                                    .oneOffDiscountedPricePercentage != 100
                                  ? this.props.oneOffTotal
                                  : this.props.packageOneOffPrice) > 0 || (this.props.packageOneOffPrice == 0 && this.state.oneOffDiscountedPricePercentage == 100))
                                ? this.validator.message(
                                  "packageOneOffPrice",
                                  this.props.packageOneOffPrice,
                                  `_required|_numeric|_minPricing:${this.props.minPricing},num`,
                                  { className: "text-danger" }
                                )
                                : null}
                              {!this.validator.fields["packageOneOffPrice"] && typeof this.validator.fields["packageOneOffPrice"] != 'undefined'
                                && this.validator.messagesShown ? (
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => this.setState({
                                    showPricingModel: true,
                                    minPricingState: this.props.minPricing ?? 0,
                                    minPricingMonthState: this.props.minPricingMonth ?? 0,
                                    maxDiscountState: this.props.maxDiscount ?? 0
                                  })}
                                >Pricing Settings</a>
                              ) : null}
                            </div>
                          </div>
                        </ReviewServices>
                      </React.Fragment>
                    )}
                  </TabPane>
                  <TabPane tabId={++activeStepCount} className="p-3">
                    {!this.state.validContract && (
                      <div className="text-center text-danger">
                        Please input all valid values!
                      </div>
                    )}
                    {this.state.validContract &&
                      this.props.previewContractPDFUrl && (
                        <iframe
                          src={this.props.previewContractPDFUrl}
                          className="pdfIframe"
                        />
                      )}
                  </TabPane>
                </TabContent>
                {this.state.sv && (
                  <p
                    style={{
                      textAlign: "center",
                      color: "red",
                      width: "100%",
                    }}
                  >
                    Please fill in all mandatory fields
                  </p>
                )}
                <div className="separator mt-3 mb-3" />
                <div className="row fieldset">
                  <div className="col-12 text-right">
                    <button onClick={this.cancel} className="btn">
                      Cancel
                    </button>
                    {this.props.activeStep > 1 && (
                      <button
                        onClick={this.backStep}
                        className="btn create-item ml-2"
                      >
                        Back
                      </button>
                    )}
                    {this.props.activeStep <
                      eval(`${this.props.contractSource}ContractSteps`)
                        .length && (
                        <button
                          onClick={this.nextStep}
                          className="btn create-item ml-2"
                        >
                          Next
                        </button>
                      )}

                    <button
                      onClick={() => this.formatDataAndSubmitContract("draft")}
                      className="btn create-item ml-2"
                    >
                      Save as a Draft
                    </button>
                    {this.props.activeStep ===
                      eval(`${this.props.contractSource}ContractSteps`)
                        .length && (
                        <button
                          onClick={() => this.formatDataAndSubmitContract("send")}
                          className="btn create-item ml-2"
                        >
                          Send Engagement Letter
                          <i className="ion ion-ios-send ml-1 send-proposal-ion-icon"></i>
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.showCreateNewContractSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowCreateNewContractSuccessModal(false);
              this.props.history.replace("/contracts");
            }}
          >
            <div className="mb-2 success-alert-message">
              {this.props.createNewContractSuccessModalMessage}
            </div>
          </SweetAlert>
        )}
        <Modal
          isOpen={this.state.showPricingModel}
          toggle={() => this.setState({
            showPricingModel: !this.state.showPricingModel,
            minPricingState: 0,
            minPricingMonthState: 0,
            maxDiscountState: 0
          })} size="lg">
          <ModalBody>
            <h4>Change Pricing Settings</h4>
            <div className="separator mb-2" />
            <div className="add-custom-package-content">
              <div className="row fieldset">
                <div className="col-4">
                  <label className="fieldset-label">Min. One-Off Price for Proposal/Engagement Letter</label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="input-text"
                    min={0}
                    onChange={(e) => {
                      var reg = /^\d+$/;
                      if (!reg.test(e.target.value) && e.target.value != "")
                        return;
                      if (e.target.value.length > 8) return;
                      const value = e.target.value < 0 ? 0 : e.target.value;
                      this.setState({
                        minPricingState: value
                      });
                    }}
                    value={this.state.minPricingState}
                  />
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-4">
                  <label className="fieldset-label">Min. Monthly Price for Proposal/Engagement Letter</label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="input-text"
                    min={0}
                    onChange={(e) => {
                      var reg = /^\d+$/;
                      if (!reg.test(e.target.value) && e.target.value != "")
                        return;
                      if (e.target.value.length > 8) return;
                      const value = e.target.value < 0 ? 0 : e.target.value;
                      this.setState({
                        minPricingMonthState: value
                      });
                    }}
                    value={this.state.minPricingMonthState}
                  />
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-4">
                  <label className="fieldset-label">
                    Max. Discount (%) for Proposal/Engagement Letter
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    min={0}
                    max={100}
                    className="input-text"
                    onChange={(e) => {
                      var reg = /^\d+$/;
                      if (!reg.test(e.target.value) && e.target.value != "")
                        return;
                      if (e.target.value > 100) return;
                      const value = e.target.value < 0 ? 0 : e.target.value;
                      this.setState({
                        maxDiscountState: value
                      });
                    }}
                    value={this.state.maxDiscountState}
                  />
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-12">
                  <button
                    onClick={this.updateOrganisation}
                    className="btn mt-3 create-item"
                  >
                    Submit
                  </button>
                  <button className="btn ml-2 mt-3" onClick={() => this.setState({ showPricingModel: false, minPricingMonthState: 0, minPricingState: 0, maxDiscountState: 0 })}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    currentOrganisation,
    subscriptionPackage,
    loading: subscriptionLoading,
    minPricing,
    minPricingMonth,
    maxDiscount,
    allow_prepare_contract,
    left_signed_contracts,
    allow_send_contract,
  } = state.Organisation;
  const { servicesByCategory, requiredGlobalPricingDrivers } = state.Service;
  const { clients } = state.Client;
  const { sentQuotes, skipQuotes } = state.Quote;
  const quoteLoading = state.Quote.loading;
  const clientLoading = state.Client.loading;
  const serviceLoading = state.Service.loading;
  const templateLoading = state.Template.loading;
  const { templates } = state.Template;
  const { availablePaymentMethods } = state.Common;
  return {
    currentOrganisation,
    servicesByCategory,
    requiredGlobalPricingDrivers,
    clients,
    quoteLoading,
    serviceLoading,
    clientLoading,
    sentQuotes,
    templates,
    availablePaymentMethods,
    skipQuotes,
    ...state.Contract,
    subscriptionPackage,
    subscriptionLoading,
    minPricing,
    minPricingMonth,
    maxDiscount,
    allow_prepare_contract,
    allow_send_contract,
    left_signed_contracts,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setActiveStep,
    onInputChange,
    getClients,
    getServicesByCategory,
    addServiceToContract,
    removeServiceFromContract,
    previewContract,
    sendContract,
    getSkipQuotes,
    saveContractAsDraft,
    getContract,
    resetCreateNewContractForm,
    setShowCreateNewContractSuccessModal,
    getSentQuotes,
    getTemplates,
    addSignatory,
    deleteSignatory,
    setSignatories,
    reviewContract,
    getTncTemplates,
    getGlobalDriversByServices,
    setShowUpgradeModal,
    getAvailablePaymentMethods,
    getMyOrganisation,
    getPricingSettings,
    updatePricingSettings,
    onOrgInputChange,
    resetCreateNewServiceForm,
  })(CreateNewContract)
);
