import React, { Component } from "react";
import "./style.css";
import "./style-m.css";
import logoImg from "./assets/outbooks-logo.webp";
import signupImg from "./assets/signup.png";
import iconGoogle from "./assets/icon-google.png";
import iconMicrosoft from "./assets/icon-microsoft.png";
import iconFacebook from "./assets/Icon-Facebook-White.png";
import iconX from "./assets/Icon-X-White.png";
import iconLinkedin from "./assets/Icon-Linkedin-White.png";
import iconYoutube from "./assets/Icon-YouTube-White.png";
import {
  Row,
  Card,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import { getValidator, getPhoneObject } from "../../Utils";
import { countryOptions } from "../../config";
import Loader from "../../components/Loader";
import InputPhone from "../../components/InputPhone";
import {
  registerUser,
  toggleRegistrationModal,
  resetRegisterForm,
} from "../../store/actions";
import { onInputChange } from "../../store/auth/register/actions";
import bg from "../../assets/images/bg.jpg";
import logoDark from "../../assets/images/outbooks.png";
import logoSm from "../../assets/images/logo-sm.png";
import mailIcon from "../../assets/images/mailticks-logo.png";
import "../../assets/scss/custom/pages/_register.scss";
import cancle from "../../assets/images/cancle.png";
import { loginUserWithProvider } from "../../store/actions";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = {
      agreeTerms: false,
      isTermsChecked: true,
      isVisible: true, // Define isVisible state variable for mbMenu
    };
  }

  handleValidSubmit(event, values) {
    if (this.validator.allValid() && this.state.agreeTerms) {
      this.props.registerUser(
        this.props.firstName,
        this.props.lastName,
        this.props.email,
        getPhoneObject(this.props.dialCode, this.props.phone),
        this.props.country,
        this.props.history
      );
      this.props.resetRegisterForm();
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
      this.setState({ isTermsChecked: false });
      this.forceUpdate();
    }
    // console.log(this.firstName.value, this.lastName.value);
    // this.props.firstName="";
    // this.props.lastName="";
    event.preventDefault();
  }
  componentDidMount() {
    this.props.resetRegisterForm();
  }
  componentWillUnmount() {
    this.props.resetRegisterForm();
  }
  handleClose = () => {
    console.log("Modal closed");
  };

  handleMicrosoftLogin = () => {
    this.props.loginUserWithProvider("microsoft", this.props.history);
  };

  handleGoogleLogin = () => {
    this.props.loginUserWithProvider("google", this.props.history);
  };

  handleTermsChange = () => {
    this.setState((prevState) => ({
      agreeTerms: !prevState.agreeTerms,
      isTermsChecked: true,
    }));
  };

  toggleVisibility = () => {
    const divElement = document.getElementById('mbMenu');
    if (divElement) {
      divElement.style.display = this.isVisible ? 'none' : 'block';
      //setIsVisible(!isVisible);
      this.setState(prevState => ({ isVisible: !prevState.isVisible }));
    }
  };


  render() {
    return (
      <React.Fragment>
        <div>
          
          <header className="lnheader">
            <div className="lnnav-bar">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks" />
                </a>
                <div className="mbMenuCont">
                  <button className="mbTgl" onClick={this.toggleVisibility}>&#9776;</button>

                  <div id="mbMenu" className="lnmenu mb-lnmenu" style={{ display: this.state.isVisible ? 'none' : 'block' }}>
                    <ul className="lnul">
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                      </li>
                      <li className="tosb-nav">
                        <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                        <ul class="sb-nav">
                          <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                        </ul>
                      </li>
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                      </li>
                      <li>
                        <a className="lna" href="/signIn">Login</a>
                      </li>
                      <li>
                        <a className="lna" href="/signup">Signup</a>
                      </li>
                      <li>
                        <a className="lna" href="https://calendly.com/amit-outbooks/outbooksproposal" target="_Blank">Book a Free Demo</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="lnmenu lp-lnmenu">
                <ul className="lnul">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                    <ul class="sb-nav">
                      <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                    </ul>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <div className="lnbtn lp-lnmenu">
                <a href="signup"  class="top3btn t3btn3">SIGNUP</a>
                <a href="signIn"  class="top3btn t3btn2">LOGIN</a>   
                <a href="https://calendly.com/amit-outbooks/outbooksproposal" target="_blank" class="top3btn t3btn1">BOOK A FREE DEMO</a>
              </div>
            </div>
          </header>
          {this.props.loading ? <Loader /> : null}
          <div className="lnmain-content">
            <section className="lnsection-one">
              <div className="lnhead-panel">
                <div className="lntext lnleft">
                  {/* <h1 className="lnh1 martno">Free Register</h1>
                  <p className="lnp">Get your free Outbooks Proposal account now.</p> */}
                  <h1 className="lnh1 martno">Join</h1>
                  <p className="lnp">Create a free account to start sending customised proposals</p>
                </div>
              </div>
              <div className="lnlogin-panel">
                <a className="lna" name="login"></a>
                <div className="lnlogin-right">
                  <table className="lntable" border="0" cellPadding={5}>
                    <tbody>
                      <tr>
                        <td vertical-align="middle" width={300} align="center">
                          <img
                            src={signupImg}
                            alt="Proposl Tool Login/Signup"
                          />
                        </td>
                        <td width={25}></td>
                        <td>
                          <div className="sign-ar">
                          {this.props.error ? (
                              <Alert
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  color: "#F9060E",
                                }}
                              >
                                {this.props.error}
                              </Alert>
                            ) : null}
                            <div className="row-f marb">
                              <div
                                className="btn-div sign-f"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleGoogleLogin();
                                }}
                              >
                                <tr>
                                  <td>
                                    <img
                                      src={iconGoogle}
                                      alt="Login with Google"
                                      height={30}
                                    />
                                  </td>
                                  <td>Register with Google</td>
                                </tr>
                              </div>
                              <div
                                className="btn-div sign-f"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.handleMicrosoftLogin();
                                }}
                              >
                                <tr>
                                  <td>
                                    <img
                                      src={iconMicrosoft}
                                      alt="Login with Microsoft"
                                      height={30}
                                    />
                                  </td>
                                  <td>Register with Microsoft</td>
                                </tr>
                              </div>
                            </div>

                            <div className="row-f ordiv">
                              <hr></hr>
                              <div className="or-devider">or</div>
                            </div>

                            {this.props.user && this.props.user ? (
                              <Alert color="success">
                                Register User Successfully
                              </Alert>
                            ) : null}
                            {this.props.registrationError &&
                            this.props.registrationError ? (
                              <Alert color="danger">
                                {this.props.registrationError}
                              </Alert>
                            ) : null}

                            

                            <form onSubmit={this.handleValidSubmit}>
                              <div className="row-f">
                                <input
                                  type="text"
                                  name="firstName"
                                  className="lninput lntext sign-f small martno"
                                  placeholder="First Name*"
                                  // min="5"
                                  // max="35"
                                  value={this.props.firstName || ""}
                                  onChange={(e) =>
                                    this.props.onInputChange(
                                      "firstName",
                                      e.target.value
                                    )
                                  }
                                />

                                <input
                                  type="text"
                                  name="lastName"
                                  className="lninput lntext sign-f small martno"
                                  placeholder="Last Name*"
                                  // min="5"
                                  // max="35"
                                  value={this.props.lastName || ""}
                                  onChange={(e) =>
                                    this.props.onInputChange(
                                      "lastName",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="row-f">
                                <div className="fname-valid">
                                  {this.validator.message(
                                    "First Name",
                                    this.props.firstName,
                                    "required|max:50|_name",
                                    { className: "text-danger small" }
                                  )}
                                  <span>&nbsp;</span>
                                </div>

                                <div className="lname-valid">
                                  {this.validator.message(
                                    "Last Name",
                                    this.props.lastName,
                                    "required|max:50|_name",
                                    { className: "text-danger small" }
                                  )}
                                  <span>&nbsp;</span>
                                </div>
                              </div>

                              <div className="row-f">
                                <input
                                  type="email"
                                  name="email"
                                  value={this.props.email || ""}
                                  onChange={(e) =>
                                    this.props.onInputChange(
                                      "email",
                                      e.target.value
                                    )
                                  }
                                  className="lninput lntext sign-f big marginTop0"
                                  placeholder="Email*"
                                  // min="8"
                                  // max="16"
                                />
                                {this.validator.message(
                                  "Email",
                                  this.props.email,
                                  "required|email",
                                  { className: "text-danger" }
                                )}
                              </div>
                              <div className="row-f phone-signup">
                                {/* <input
                                  type="Phone"
                                  name="Phone"
                                  className="lninput lntext sign-f big"
                                  placeholder="Phone Number"
                                  min="8"
                                  max="16"
                                /> */}
                                <InputPhone
                                  className="lninput lntext sign-f big"
                                  // min="8"
                                  // max="16"
                                  required
                                  placeholder="Phone Number*"
                                  phoneFieldName="phone"
                                  dialCodeFieldName="dialCode"
                                  dialCode={this.props.dialCode}
                                  phone={this.props.phone}
                                  validator={this.validator}
                                  onChange={this.props.onInputChange}
                                />
                              </div>

                              <div className="row-f country-signup">
                                {/* <input
                                  type="Country"
                                  name="Country"
                                  className="lninput lntext sign-f big"
                                  placeholder="Country"
                                  min="8"
                                  max="16"
                                /> */}
                                {/* <Select
                                  options={countryOptions}
                                  value={
                                    this.props.country
                                      ? countryOptions.filter(
                                          (option) =>
                                            option.value === this.props.country
                                        )
                                      : ""
                                  }
                                  onChange={(selectedOption) =>
                                    this.props.onInputChange(
                                      "country",
                                      selectedOption.value
                                    )
                                  }
                                /> */}

<Select
  options={countryOptions}
  value={
    this.props.country
      ? { value: this.props.country, label: this.props.country }
      : null
  }
  onChange={(selectedOption) =>
    this.props.onInputChange("country", selectedOption ? selectedOption.value : null)
  }
/>

                                {this.validator.message(
                                  "Country",
                                  this.props.country,
                                  "required",
                                  { className: "text-danger" }
                                )}
                              </div>
                              <div className="row-f">
                                <div className="tc-div sign-f">
                                  {" "}
                                  &nbsp;
                                  <input
                                    type="checkbox"
                                    name="terms"
                                    className="lninput lncheckbox sign-f"
                                    value="terms"
                                    checked={this.state.agreeTerms}
                                    onChange={this.handleTermsChange}
                                  />{" "}
                                  <p className="lnp tc-2 sign-f terms">
                                    By registering you agree to the Outbooks Proposal
                                    {" "}
                                    <a
                                      className="lna"
                                      href="https://outbooks.com/proposal/terms-and-conditions/"
                                    >
                                      <strong>Terms</strong>
                                    </a>
                                  </p>
                                  {!this.state.isTermsChecked && (
                                    <p className="text-danger">
                                      Please check the box to agree to the terms and conditions
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="row-f">
                                <button
                                  type="submit"
                                  name="submit"
                                  className="lnpage-btn lnlogin sign-f"
                                  onClick={this.handleValidSubmit}
                                >
                                  REGISTER
                                </button>
                              </div>
                            </form>
                            <div className="row-f">
                              <div className="tc-div sign-f">
                                <span className="tc-2 sign-f">
                                  {" "}
                                  &nbsp;Already have an account?
                                </span>{" "}
                                &nbsp;{" "}
                                <a className="lna" href="signin">
                                  <strong>SIGN IN</strong>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>

          <footer className="lnfooter">
            <div className="lncol-1">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks Proposal" />
                </a>
              </div>
              <div className="lnmenu">
                <ul className="lnul lnnav-list">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">
                      HOME
                    </a>
                  </li>
                  <li>
                    <a className="lna" href="signup">
                      SIGN UP
                    </a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">
                      FEATURES
                    </a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">
                      CONTACT US
                    </a>
                  </li>
                </ul>
              </div>
              <p className="lnp lncopy-right">
                Copyright © Outbooks Proposal {new Date().getFullYear()} | All Rights Reserved
              </p>
            </div>
            <div className="lncol-2">
            <div className="lnmenu footer-m1">
                <ul className="lnul lnnav-list">
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/contact-us">Help Center</a>
                    </li>
                    <li>
                      <a className="lna" href="#">Blogs</a>
                    </li>
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/terms-and-conditions/">Terms and Conditions</a>
                    </li>
                  </ul>
              </div>
            </div>
            <div className="lncol-3">
              <p className="lnp lnadr">
                Suite 18, Winsor & Newton Building,
                <br />
                Whitefriars Avenue, Harrow HA3 5RN
              </p>
              <p className="lnp lnregno">Registration No: 10746177</p>
              <ul className="lnul lncontact-links">
                <li>
                  <a className="lna" href="mailto:info@outbooks.com">
                    &#9993; &nbsp; info@outbooks.com
                  </a>
                </li>
                <li>
                  <a className="lna" href="tel:+443300578597">
                    &#9743; &nbsp; +44 330 057 8597
                  </a>
                </li>
              </ul>
              <ul className="lnul lnsocial-links">
                <li>
                  <a className="lna" href="https://www.facebook.com/profile.php?id=61556567720993">
                    <img src={iconFacebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a
                    className="lna"
                    href="https://www.linkedin.com/company/outbooksproposal/"
                  >
                    <img src={iconLinkedin} alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://twitter.com/outbookproposal">
                    <img src={iconX} alt="Twitter / X" />
                  </a>
                </li>
                <li>
                  <a
                    className="lna"
                    href="https://www.youtube.com/channel/UCCucVxt5QuYrJI6SDCDW7sQ"
                  >
                    <img src={iconYoutube} alt="Youtube" />
                  </a>
                </li>
              </ul>
              <a
                href="https://outbooks.com/proposal/contact-us/"
                className="lna lnpage-btn"
              >
                CONTACT US
              </a>
            </div>
          </footer>
        </div>
        <Modal
          id="modal"
          isOpen={this.props.showRegisterModal}
          centered
          size="lg"
          toggle={this.props.toggleRegistrationModal}
          className="registration-modal"
        >
          <ModalHeader className="close-modal">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {this.props.registerationModalName}, an email is on its way
              <img
                style={{ cursor: "pointer" }}
                height={30}
                width={30}
                onClick={this.props.toggleRegistrationModal}
                src={cancle}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <img src={mailIcon} />
            <p className="lnp">
              Please verify the email you received on{" "}
              <span className="registeration-modal-email">
                {this.props.registerationModalEmail}
              </span>{" "}
              to activate your Outbooks account.
            </p>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    dialCode,
    country,
    registrationError,
    error,
    loading,
    showRegisterModal,
    registerationModalName,
    registerationModalEmail,
  } =  state.Account && state.Login ? { ...state.Account, ...state.Login } : {};
  return {
    firstName,
    lastName,
    email,
    phone,
    dialCode,
    country,
    registrationError,
    error,
    loading,
    showRegisterModal,
    registerationModalName,
    registerationModalEmail,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loginUserWithProvider,
    registerUser,
    toggleRegistrationModal,
    onInputChange,
    resetRegisterForm,
  })(SignUp)
);